import React, { useEffect } from 'react';
import { AmbientTemperatureProps } from './AmbientTemperature.type';
import { AmbientTemperatureContent } from './AmbientTemperature.view';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import CheckerApi from 'api/Checker';
import { CheckerModel } from 'models/Checker.type';
import { GetConvertedAmbient } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';

const Checker = new CheckerApi();

const AmbientTemperature: React.FC<AmbientTemperatureProps> = (): JSX.Element => {
    const { fromServerToUserUnit } = useConverter();
    const getConvertedAmbient = useRecoilValue(GetConvertedAmbient);
    const convertedAmbient = getConvertedAmbient(fromServerToUserUnit);

    const customerSettingsNumber: number[] = Object.values(convertedAmbient.all ?? {}).map((value) => value as number);

    return (
        <AmbientTemperatureContent
            temperature={convertedAmbient.current}
            data={customerSettingsNumber}
            isLoading={false}
        />
    );
};

export default Wrapper(AmbientTemperature);
