import styled from 'styled-components';
import { Typography, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { DatePicker } from '@mui/x-date-pickers';
import { CommonTypeTheme } from 'components/Ui/Theme';

export const CustomDatePicker = styled(DatePicker)`
    ${applyStyleByMode({
        styleOld: `
            & label {
                padding: 0 5px 0 0 !important;
                background: #fff !important;
                transform: translate(14px, -6px) scale(0.9) !important;
            }        
        `
    })}
    margin: 4px 0 8px 0 !important;
`;

export const AdminActionViewContent = styled.div`
    th,
    td {
        overflow-wrap: break-word;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    td:nth-child(1),
    th:nth-child(5) {
        text-overflow: clip;
    }
    ${applyStyleByMode({
        styleOld: 'width: 98%;',
        styleJade: 'width: 100%;'
    })}
    && .MuiCardContent-root {
        ${applyStyleByMode({
            styleOld: 'padding: 16px 0;',
            styleJade: 'padding: 0px 10px 10px 10px;'
        })}
        font-size: 13px !important;
    }

    & .MuiTableHead-root {
        ${applyStyleByMode({
            styleOld: 'border-bottom: 1px solid #474544; background: #f9f9f9;'
        })}
    }

    td button {
        ${applyStyleByMode({
            styleJade: 'margin-left: -11px !important;'
        })}
    }
`;

export const DetailsContainer = styled.div`
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(3, minmax(40px, 230px));
    grid-auto-rows: repeat(4, minmax(30px, 100px));
`;

export const TextTitle = styled(Typography)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTypography-root {
                font-size: 14px;
                font-weight: bold;
                margin: 8px 10px;
            }
        `
    })}
`;

export const Text = styled(Typography)`
    &.MuiTypography-root {
        ${() => {
            const common = useTheme().palette.common as CommonTypeTheme;
            return `font-size: ${common.textSize};`;
        }}
        margin: 5px 10px;
        overflow-wrap: break-word;
    }
`;
