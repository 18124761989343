import React from 'react';
import * as style from './TrackingMapTopBar.style';
import { DateFromActionsEnum, TrackingMapTopBarViewProps } from './TrackingMapTopBar.type';
import Button from '../Ui/Components/UiButton';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import { useRecoilValue } from 'recoil';
import { UserInfo } from '../../states/global/User';
import { isInPeriod } from 'helpers';
import { Settings } from 'luxon';
import { SIZE_BUTTON } from '../Ui/variables';
import GetVehicleGroupCodebook from 'components/Queries/VehicleGroup/VehicleGroup';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import UiDateRangePicker2 from 'components/Ui/Components/UiDateRangePicker2';

export const TrackingMapTopBarContent: React.FC<TrackingMapTopBarViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    return (
        <style.TrackingMapTopBarContent data-testid='TrackingMapTopBarContent'>
            <Grid container justifyContent='center'>
                {userInfo.user?.userSetting.timezone.timezoneName === Settings.defaultZone.zoneName && (
                    <Grid md='auto' marginTop={1}>
                        <UiDateRangePicker2
                            data-testid={'TrackingMapTopBarContent-UiDateRangePicker'}
                            adjustToPeriod={{ period: 14, periodType: 'days' }}
                            dateFrom={props.date.selectedDateFrom}
                            dateTo={props.date.selectedDateTo}
                            dateFromLabel={translate('t.date_from')}
                            dateToLabel={translate('t.date_to')}
                            onDateChange={(dateRange) => {
                                if (dateRange)
                                    props.setDate({
                                        type: DateFromActionsEnum.SET_SELECTED_DATE,
                                        payload: { from: dateRange[0], to: dateRange[1] }
                                    });
                            }}
                        />
                    </Grid>
                )}
                <Grid md='auto' marginTop={1}>
                    <GetVehicleGroupCodebook>
                        {({ data }) => (
                            <FormControl
                                data-testid='SelectToFilterByVehicleGroup'
                                variant='outlined'
                                size='small'
                                sx={{ marginRight: '5px' }}
                            >
                                <InputLabel shrink>{translate('t.vehicle_group')}</InputLabel>
                                <Select
                                    variant='outlined'
                                    size='small'
                                    value={props.vehicleGroup || 0}
                                    style={{ width: '213px' }}
                                    onChange={(event) => {
                                        props.setVehicleGroup(event.target.value as number);
                                    }}
                                    input={<OutlinedInput notched label={translate('t.vehicle_group')} />}
                                >
                                    <MenuItem selected value={0}>
                                        {translate('t.all')}
                                    </MenuItem>
                                    {data?.map((vehicleType, index) => {
                                        return (
                                            <MenuItem value={vehicleType.id} key={`${vehicleType.id}-${index}`}>
                                                {vehicleType.type}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        )}
                    </GetVehicleGroupCodebook>
                </Grid>
                <Grid md='auto' marginTop={1}>
                    <style.CustomAutocomplete
                        id={'vehicles'}
                        size='small'
                        multiple
                        options={props.vehicleList}
                        getOptionDisabled={() => props.selectedVehicles.length > 2}
                        freeSolo={props.selectedVehicles.length > 2}
                        getOptionLabel={(option) => (option as { name: string }).name}
                        isOptionEqualToValue={(option, value) => {
                            return (option as { id: unknown }).id === (value as { id: unknown }).id;
                        }}
                        onChange={(a, b) => {
                            props.setSelectedVehicles(b);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                data-testid={'TrackingMapTopBarContent-TextField-vehicles'}
                                label={translate('t.vehicles')}
                                size='small'
                                inputProps={{
                                    ...params.inputProps
                                }}
                                variant='outlined'
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid md='auto' xs={12} marginTop={1} marginBottom={1} textAlign='center'>
                    <Button
                        variant='contained'
                        data-testid={'TrackingMapTopBarContent-Button-apply'}
                        size={SIZE_BUTTON}
                        skin='success'
                        sx={{ marginTop: { xs: '10px' } }}
                        onClick={() => {
                            const isValidDataRange: boolean = isInPeriod(
                                props.date.selectedDateFrom,
                                props.date.selectedDateTo,
                                14,
                                'days'
                            );
                            const moreThan2Hours = !isInPeriod(
                                props.date.selectedDateFrom,
                                props.date.selectedDateTo,
                                2,
                                'hours'
                            );
                            props.setShowJourneyWarning(moreThan2Hours);
                            props.setDate({ type: DateFromActionsEnum.SET_APPLIED_DATE_FROM });
                            props.setShowPeriodWarning(!isValidDataRange);
                        }}
                        disabled={!props.datePeriodIsValid || !props.selectedVehicles.length}
                    >
                        {translate('t.apply')}
                    </Button>
                </Grid>
            </Grid>
            <style.WarningCover>{props.showPeriodWarning && translate('p.date_range_over_14_days')}</style.WarningCover>
            {props.showJourneyWarning && (
                <style.InfoContent severity='info'>
                    {translate('p.journey_data_less_accurate_over_2_hours')}
                </style.InfoContent>
            )}
        </style.TrackingMapTopBarContent>
    );
};
