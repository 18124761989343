import React from 'react';
import { useRecoilValue } from 'recoil';
import { FontBold, PrivacyPolicyPaper, PrivacyPolicyViewContent, SubTextContainer } from './PrivacyPolicy.style';
import { PrivacyPolicyViewProps } from './PrivacyPolicy.type';
import { Theme, ThemeMode } from 'states/global/Theme';
import { Link, Typography } from '@mui/material';
import Footer from 'components/Footer/Footer';
import { getImgSource } from 'helpers/image';

export const PrivacyPolicyContent: React.FC<PrivacyPolicyViewProps> = (): JSX.Element => {
    const theme = useRecoilValue(Theme);

    return (
        <PrivacyPolicyViewContent data-testid='PrivacyPolicyContent'>
            <img
                src={theme.mode === ThemeMode.light ? getImgSource('logo') : getImgSource('logolight')}
                className='logo'
                alt='logo'
            />
            <PrivacyPolicyPaper>
                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    1. Privacy Policy
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Effective date: 14/04/2022
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. {'("us", "we", or "our")'} operates the
                    <Link href='https://webtrack.atmstechnology.com/' target='_blank' variant='h5' underline='hover'>
                        {' https://webtrack.atmstechnology.com/'}
                    </Link>{' '}
                    website {'(the "Service")'}.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    This page informs you of our policy regarding the collection, use, and disclosure of personal data
                    when you use our Service and the choices you have associated with that data.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We use your data to provide and improve the Service. By using the Service, you agree to the
                    collection and use of information in accordance with this policy.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    2. Definitions
                </Typography>
                <SubTextContainer>
                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Service
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Service is the
                            <Link
                                href='https://webtrack.atmstechnology.com/'
                                target='_blank'
                                variant='h5'
                                underline='hover'
                            >
                                {' https://webtrack.atmstechnology.com/ '}
                            </Link>
                            website operated by ATMS Technology Ltd.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Usage data
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Usage Data is data collected automatically either generated using the Service or from the
                            Service infrastructure itself (for example, the duration of a page visit).
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Cookies
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Cookies are small pieces of data stored on your device (computer or mobile device).
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Data controller
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Data Controller means the natural or legal person who (either alone or jointly or in common
                            with other persons) determines the purposes for which and the manner in which any personal
                            information are, or are to be, processed.
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Data processors (or Service Providers)
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Data Processor (or Service Provider) means any natural or legal person who processes the
                            data on behalf of the Data Controller.
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            We use the services of various Service Providers in order to process your data more
                            effectively, these are listed at paragraph 13 below.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - Data subject (or User)
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Data Subject is any living individual who is using our Service and is the subject of
                            Personal Data.
                        </Typography>
                    </SubTextContainer>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    3. Information collection and use
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We collect several different types of information for various purposes to provide and improve our
                    Service to you.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    4. Types of Data Collected
                </Typography>
                <SubTextContainer>
                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        4.1 Service
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        While using our Service, we may ask you to provide us with certain personally identifiable
                        information that can be used to contact or identify you {'("Personal Data")'}. Personally
                        identifiable information may include, but is not limited to:
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Email address
                        </Typography>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - First name and last name
                        </Typography>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Cookies and Usage Data
                        </Typography>
                    </SubTextContainer>
                    <Typography variant='body1' gutterBottom>
                        We may use your Personal Data to contact you with newsletters, marketing or promotional
                        materials and other information that may be of interest to you. You opted in to receiving
                        communication when you created a user account to use this service. You may opt out of receiving
                        any, or all, of these communications from us by following the unsubscribe link or instructions
                        provided in any email we send.
                    </Typography>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        4.2 Usage Data
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        We may also collect information about how the Service is accessed and used {'("Usage Data")'}.
                        This Usage Data may include information such as your computer&apos;s Internet Protocol address
                        (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the
                        time and date of your visit, the time spent on those pages, unique device identifiers and other
                        diagnostic data.
                    </Typography>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        4.3 Tracking and Cookies Data
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        We use cookies and similar tracking technologies to track the activity on our Service and hold
                        certain information.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        Cookies are files with small amount of data which may include an anonymous unique identifier.
                        Cookies are sent to your browser from a website and stored on your device. Tracking technologies
                        also used are beacons, tags, and scripts to collect and track information and to improve and
                        analyze our Service.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                        However, if you do not accept cookies, you may not be able to use some portions of our Service.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        Examples of Cookies we use:
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Session Cookies.
                        </Typography>
                        <SubTextContainer>
                            <Typography variant='body1' gutterBottom>
                                We use Session Cookies to operate our Service.
                            </Typography>
                        </SubTextContainer>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Preference Cookies.
                        </Typography>
                        <SubTextContainer>
                            <Typography variant='body1' gutterBottom>
                                We use Preference Cookies to remember your preferences and various settings.
                            </Typography>
                        </SubTextContainer>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Security Cookies.
                        </Typography>
                        <SubTextContainer>
                            <Typography variant='body1' gutterBottom>
                                We use Security Cookies for security purposes.
                            </Typography>
                        </SubTextContainer>
                    </SubTextContainer>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    5. Use of Data
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. uses the collected data for various purposes:
                    <ul>
                        <li>To provide and maintain our Service</li>
                        <li>To notify you about changes to our Service</li>
                        <li>
                            To allow you to participate in interactive features of our Service when you choose to do so
                        </li>
                        <li>To provide customer support</li>
                        <li>To gather analysis or valuable information so that we can improve our Service</li>
                        <li>To monitor the usage of our Service</li>
                        <li>To detect, prevent and address technical issues</li>
                        <li>
                            To provide you with news, special offers and general information about goods, services, and
                            events which we offer that are similar to those that you have already purchased or enquired
                            about unless you have opted not to receive such information
                        </li>
                    </ul>
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    6. Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)
                </Typography>
                <Typography variant='body1' gutterBottom>
                    If you are from the European Economic Area (EEA), ATMS Technology Ltd.’ legal basis for collecting
                    and using the personal information described in this Privacy Policy depends on the Personal Data we
                    collect and the specific context in which we collect it.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. may process your Personal Data because:
                    <ul>
                        <li>We need to perform a contract with you</li>
                        <li>You have given us permission to do so</li>
                        <li>The processing is in our legitimate interests, and is not overridden by your rights</li>
                        <li>To comply with the law</li>
                    </ul>
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    7. Retention of Data
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. will retain your Personal Data only for as long as is necessary for the
                    purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent
                    necessary to comply with our legal obligations (for example, if we are required to retain your data
                    to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. will also retain Usage Data for internal analysis purposes. Usage Data is
                    generally retained for a shorter period of time, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or we are legally obligated to retain this
                    data for longer time periods.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    8. Transfer Of Data
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Your information, including Personal Data, may be transferred to — and maintained on — computers
                    located outside of your state, province, country, or other governmental jurisdiction where the data
                    protection laws may differ than those from your jurisdiction. The servers where ATMS stores your
                    personal information are located in the EU and kept in a secure environment protected by a firewall.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    ATMS Technology Ltd. will take all steps reasonably necessary to ensure that your data is treated
                    securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take
                    place to an organization or a country unless there are adequate controls in place including the
                    security of your data and other personal information.
                </Typography>
                <SubTextContainer>
                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        8.1 Disclosure for Law Enforcement
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        Under certain circumstances, ATMS Technology Ltd may be required to disclose your Personal Data
                        if required to do so by law or in response to valid requests by public authorities (e.g. a court
                        or a government agency).
                    </Typography>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        8.2 Legal Requirements
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        ATMS Technology Ltd may disclose your Personal Data to:
                        <ul>
                            <li>To comply with a legal obligation</li>
                            <li>To protect and defend the rights or property of ATMS Technology Ltd</li>
                            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>To protect the personal safety of users of the Service or the public</li>
                            <li>To protect against legal liability</li>
                        </ul>
                    </Typography>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    9. Security Of Data
                </Typography>
                <Typography variant='body1' gutterBottom>
                    The security of your data is important to us but remember that no method of transmission over the
                    Internet, or method of electronic storage is 100% secure. While we strive to use robust means to
                    protect your Personal Data, we cannot guarantee its absolute security.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)
                </Typography>
                <Typography variant='body1' gutterBottom>
                    If you are a resident of the European Economic Area (EEA), you have certain data protection rights
                    under the General Data Protection Regulation (GDPR). ATMS Technology Ltd. will comply with your
                    rights of access and to correct, amend, delete, or limit the use of your Personal Data.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    If you wish to be informed what Personal Data we hold about you and if you want it to be removed
                    from our systems, please contact us at info@atmstechnology.com
                </Typography>
                <Typography variant='body1' gutterBottom>
                    In certain circumstances, you have the following data protection rights:
                </Typography>
                <SubTextContainer>
                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right to access, update or to delete the information we have on you.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            Whenever made possible, you can access, update or request deletion of your Personal Data
                            directly within your account settings section. If you are unable to perform these actions
                            yourself, please contact us to assist you.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right of rectification.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            You have the right to have your information rectified if that information is inaccurate or
                            incomplete.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right to object.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            You have the right to object to our processing of your Personal Data.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right of restriction.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            You have the right to request that we restrict the processing of your personal information.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right to data portability.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            You have the right to be provided with a copy of the information we have on you in a
                            structured, machine-readable and commonly used format.
                        </Typography>
                    </SubTextContainer>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        - The right to withdraw consent.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='body1' gutterBottom>
                            You also have the right to withdraw your consent at any time where ATMS Technology Ltd.
                            relied on your consent to process your personal information.
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            Please note that we may ask you to verify your identity and clarify the request before
                            responding.
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            Data protection legislation provides you with rights, one of which is to complain to a Data
                            Protection Authority about our collection and use of your Personal Data. For more
                            information, please contact your local data protection authority in your geographical
                            jurisdiction.
                        </Typography>
                    </SubTextContainer>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    11. Security Breach Management and Notification
                </Typography>
                <Typography variant='body1' gutterBottom>
                    In the unlikely event that ATMS Technology Ltd. becomes aware of any accidental or unlawful security
                    concern, we will promptly:
                </Typography>
                <SubTextContainer>
                    <Typography variant='body1' gutterBottom>
                        <FontBold>i.</FontBold>
                        investigate the Security Breach
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        <FontBold>ii.</FontBold>
                        take all steps to mitigate the effects and to minimize any damage resulting from the Security
                        Breach
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        <FontBold>iii.</FontBold>
                        comply with our obligation to notify the personal data breach to the competent supervisory
                        authority within 72 hours after becoming aware of the personal data breach, providing all
                        relevant information about the Security Breach, if applicable
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        <FontBold>iv.</FontBold>
                        notify Customer of the Security Breach, providing all relevant information about the Security
                        Breach, if applicable
                    </Typography>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    12. Service Providers
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We may employ third party companies and individuals to facilitate our Service{' '}
                    {'("Service Providers")'}, to provide the Service on our behalf, to perform Service-related services
                    or to assist us in analyzing how our Service is used.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    These third parties have access to your Personal Data as Data Processors only to perform these tasks
                    on our behalf and are obligated not to disclose or use it for any other purpose. We have contracts
                    with these processors to ensure that personal data is processed only under detailed conditions,
                    according to legislation.
                </Typography>
                <SubTextContainer>
                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        12.1 Approved Service Providers (processors)
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        ATMS Technology Ltd. Authorizes the engagement of the following processors:
                        <ul>
                            <li>Amazon Web Services - Cloud Service Provider</li>
                            <li>Google Analytics {'-'} Cloud Based Analytics Tools</li>
                            <li>Mail Chimp - Email Service Provider</li>
                            <li>Help Juice - Cloud Service Provider for our Knowledge Base</li>
                        </ul>
                    </Typography>

                    <Typography variant='h4' fontWeight='bold' gutterBottom>
                        12.2 Analytics
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        We may use third-party Service Providers to monitor and analyze the use of our Service.
                    </Typography>
                    <SubTextContainer>
                        <Typography variant='h4' fontWeight='bold' gutterBottom>
                            - Google Analytics
                        </Typography>
                        <SubTextContainer>
                            <Typography variant='body1' gutterBottom>
                                Google Analytics is a web analytics service offered by Google that tracks and reports
                                website traffic. Google uses the data collected to track and monitor the use of our
                                Service. This data is shared with other Google services. Google may use the collected
                                data to contextualize and personalize the ads of its own advertising network.
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                You can opt-out of having made your activity on the Service available to Google
                                Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents
                                the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing
                                information with Google Analytics about visits activity.
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                For more information on the privacy practices of Google, please visit the Google Privacy
                                & Terms web page:
                                <Link
                                    href='https://www.google.com/intl/en/policies/privacy/'
                                    target='_blank'
                                    variant='h5'
                                    underline='hover'
                                >
                                    {' https://www.google.com/intl/en/policies/privacy/'}
                                </Link>
                            </Typography>
                        </SubTextContainer>
                    </SubTextContainer>
                </SubTextContainer>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    13. Links To Other Sites
                </Typography>
                <Typography variant='body1' gutterBottom>
                    Our Service may contain links to other sites that are not operated by us. If you click on a
                    third-party link, you will be directed to that third party&apos;s site. We strongly advise you to
                    review the Privacy Policy of every site you visit.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We have no control over and assume no responsibility for the content, privacy policies or practices
                    of any third-party sites or services.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    14. Changes To This Privacy Policy
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We may update this Privacy Policy from time to time. You can access any changes to this privacy
                    policy by accessing the new Privacy Policy on this page.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    We will let you know via email and/or a prominent notice on our Service, prior to the change
                    becoming effective and update the {'"effective date"'} at the top of this Privacy Policy.
                </Typography>
                <Typography variant='body1' gutterBottom>
                    You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                    Policy are effective when they are posted on this page.
                </Typography>

                <Typography variant='h3' fontWeight='bold' gutterBottom>
                    15. Contact Us
                </Typography>
                <Typography variant='body1' gutterBottom>
                    If you have any questions about this Privacy Policy, please contact us by email at:
                    info@atmstechnology.com.
                </Typography>
            </PrivacyPolicyPaper>
            <Footer />
        </PrivacyPolicyViewContent>
    );
};
