import React from 'react';
import { UiDatePickerFilterProps } from './UiDatePickerFilter.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { UserInfo } from 'states/global/User';
import { TextField } from '@mui/material';

const UiDatePickerFilter: React.FC<UiDatePickerFilterProps> = ({
    state,
    setFilter,
    testId,
    keyFilter,
    label
}): JSX.Element => {
    const { dateTimeFormat } = useConverter();
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} data-testid={`UiDatePickerFilter-Localization-${testId}`}>
            <DatePicker
                data-testid={`UiDatePickerFilter-${testId}`}
                inputFormat={dateTimeFormat('date', false)}
                label={label}
                value={state.filters.filter((filter) => filter.id === keyFilter)[0]?.value.value || null}
                onChange={(newValue: DateTime): void => {
                    const value = !newValue
                        ? undefined
                        : {
                              name: label,
                              humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                              value: newValue
                          };
                    setFilter(keyFilter, value);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        data-testid={`UiDatePickerFilter-${testId}-Textfield`}
                        margin='normal'
                        id={`UiDatePickerFilter-updated-from-${testId}`}
                        size='small'
                        variant='outlined'
                        helperText={params.error && translate('t.the_date_format_is_invalid')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: userInfo.user?.userSetting.dateFormat || 'yyy-MM-dd',
                            style: {
                                padding: '10.5px'
                            }
                        }}
                        sx={{ marginTop: '10px' }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default UiDatePickerFilter;
