import React, { useEffect, useCallback, useState } from 'react';
import {
    CustomButton,
    CustomList,
    CustomListButton,
    CustomListText,
    CustomTextField,
    FieldContainer,
    GridContainer,
    GridTimePicker,
    HrsMinContainer,
    HelperText,
    Text
} from './DateTimeDialogSelector.style';
import { useRecoilValue } from 'recoil';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { DateTimeModalDialogProps } from './DateTimeDialogSelector.type';
import { Is12HrsFormat } from 'helpers/Converters';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const DateTimeDialogSelector: React.FC<DateTimeModalDialogProps> = ({
    dateValueStaticDatePicker,
    setDateValueStaticDatePicker,
    displaySeconds
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const [is12HrsFormatSelected, setIs12HrsFormatSelected] = useState<number | null>(
        dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1
    );
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const [hours, setHours] = useState<number | null>(null);
    const [minutes, setMinutes] = useState<number | null>(null);
    const [seconds, setSeconds] = useState<number | null>(null);

    const fromMSTto12HoursFormat = useCallback(
        (value: number) => {
            const isZero: boolean = value === 0;
            const isAfter12: boolean = value > 12;

            if (isZero) {
                return is12HrsFormat ? 12 : value;
            }

            if (is12HrsFormat) {
                return isAfter12 ? value - 12 : value;
            }

            return value;
        },
        [is12HrsFormat]
    );

    const handleListItemClick = (event, index) => {
        setIs12HrsFormatSelected(index);
        setDateValueStaticDatePicker(
            index == 2 ? dateValueStaticDatePicker.plus({ hours: 12 }) : dateValueStaticDatePicker.minus({ hours: 12 })
        );
    };

    const setHoursMinutes = (props): DateTime => {
        return DateTime.fromObject({
            year: dateValueStaticDatePicker.toObject().year,
            month: dateValueStaticDatePicker.toObject().month,
            day: dateValueStaticDatePicker.toObject().day,
            hour: props.hour ?? dateValueStaticDatePicker.toObject().hour,
            minute: props.minute ?? dateValueStaticDatePicker.toObject().minute,
            second: props.second ?? dateValueStaticDatePicker.toObject().second
        });
    };

    const inRangeHours = (value: number): boolean => {
        return is12HrsFormat ? (value <= 12 && value >= 1 ? true : false) : value <= 23 && value >= 0 ? true : false;
    };

    const inRangeMinutes = (value: number): boolean => {
        return value <= 59 && value >= 0 ? true : false;
    };

    const inRangeSeconds = (value: number): boolean => {
        return value <= 59 && value >= 0 ? true : false;
    };

    const handleHoursTimeButtons = (dateValue: DateTime, action: string, add: boolean): DateTime => {
        switch (action) {
            case 'HOURS':
                return add == false ? dateValue.minus({ hours: 1 }) : dateValue.plus({ hours: 1 });

            case 'MINUTES':
                return add == false ? dateValue.minus({ minutes: 1 }) : dateValue.plus({ minutes: 1 });

            case 'SECONDS':
                return add == false ? dateValue.minus({ seconds: 1 }) : dateValue.plus({ seconds: 1 });

            default:
                return add == false ? dateValue.minus({ hours: 1 }) : dateValue.plus({ hours: 1 });
        }
    };

    useEffect(() => {
        return () => {
            setHours(null);
            setMinutes(null);
            setSeconds(null);
            setIs12HrsFormatSelected(null);
        };
    }, []);

    useEffect(() => {
        setHours(fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour));
        setMinutes(dateValueStaticDatePicker.toObject().minute);
        displaySeconds && setSeconds(dateValueStaticDatePicker.toObject().second);
        setIs12HrsFormatSelected(dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1);
    }, [dateValueStaticDatePicker, fromMSTto12HoursFormat, displaySeconds]);

    return (
        <GridContainer container spacing={1}>
            <GridTimePicker mt={3} item md={12} className='timePicker'>
                <Text $ampm={is12HrsFormat}>{translate('t.time')}</Text>
                <div></div>
                <HrsMinContainer $ampm={is12HrsFormat}>
                    <FieldContainer className='innerDiv'>
                        <CustomButton
                            onClick={() => {
                                setDateValueStaticDatePicker(
                                    handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', true)
                                );
                            }}
                        >
                            <ArrowDropUp />
                        </CustomButton>

                        <CustomTextField
                            placeholder={is12HrsFormat ? 'HH' : 'hh'}
                            error={
                                !inRangeHours(
                                    hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)
                                )
                            }
                            value={hours != null && hours < 10 ? `0${hours}` : hours}
                            onChange={(newDate) => {
                                if (!Number.isNaN(Number(newDate.target.value))) {
                                    setHours(Number(newDate.target.value));

                                    if (inRangeHours(Number(newDate.target.value))) {
                                        setDateValueStaticDatePicker(setHoursMinutes({ hour: newDate.target.value }));
                                    }
                                }
                            }}
                            defaultValue={
                                fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour) < 10
                                    ? `0${fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)}`
                                    : fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)
                            }
                        ></CustomTextField>

                        <CustomButton
                            onClick={() => {
                                setDateValueStaticDatePicker(
                                    handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', false)
                                );
                            }}
                        >
                            <ArrowDropDown />
                        </CustomButton>
                    </FieldContainer>
                    <FieldContainer className='innerDiv'>:</FieldContainer>

                    <FieldContainer className='innerDiv'>
                        <CustomButton
                            onClick={() => {
                                setDateValueStaticDatePicker(
                                    handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', true)
                                );
                            }}
                        >
                            <ArrowDropUp />
                        </CustomButton>
                        <CustomTextField
                            placeholder='mm'
                            value={minutes && minutes < 10 ? `0${minutes}` : minutes}
                            error={!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute)}
                            onChange={(newDate) => {
                                if (!Number.isNaN(Number(newDate.target.value))) {
                                    setMinutes(Number(newDate.target.value));

                                    if (newDate.target.value && inRangeMinutes(Number(newDate.target.value))) {
                                        setDateValueStaticDatePicker(setHoursMinutes({ minute: newDate.target.value }));
                                    }
                                }
                            }}
                            defaultValue={
                                dateValueStaticDatePicker.toObject().minute < 10
                                    ? `0${dateValueStaticDatePicker.toObject().minute}`
                                    : dateValueStaticDatePicker.toObject().minute
                            }
                        ></CustomTextField>
                        <CustomButton
                            onClick={() => {
                                setDateValueStaticDatePicker(
                                    handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', false)
                                );
                            }}
                        >
                            <ArrowDropDown />
                        </CustomButton>
                    </FieldContainer>
                    {!displaySeconds ? (
                        ''
                    ) : (
                        <>
                            <FieldContainer className='innerDiv'>:</FieldContainer>
                            <FieldContainer className='innerDiv'>
                                <CustomButton
                                    onClick={() => {
                                        setDateValueStaticDatePicker(
                                            handleHoursTimeButtons(dateValueStaticDatePicker, 'SECONDS', true)
                                        );
                                    }}
                                >
                                    <ArrowDropUp />
                                </CustomButton>
                                <CustomTextField
                                    placeholder='ss'
                                    value={seconds && seconds < 10 ? `0${seconds}` : seconds}
                                    error={!inRangeSeconds(seconds ?? dateValueStaticDatePicker.toObject().second)}
                                    onChange={(newDate) => {
                                        if (!Number.isNaN(Number(newDate.target.value))) {
                                            setSeconds(Number(newDate.target.value));

                                            if (newDate.target.value && inRangeSeconds(Number(newDate.target.value))) {
                                                setDateValueStaticDatePicker(
                                                    setHoursMinutes({ second: newDate.target.value })
                                                );
                                            }
                                        }
                                    }}
                                    defaultValue={
                                        dateValueStaticDatePicker.toObject().second < 10
                                            ? `0${dateValueStaticDatePicker.toObject().second}`
                                            : dateValueStaticDatePicker.toObject().second
                                    }
                                ></CustomTextField>
                                <CustomButton
                                    onClick={() => {
                                        setDateValueStaticDatePicker(
                                            handleHoursTimeButtons(dateValueStaticDatePicker, 'SECONDS', false)
                                        );
                                    }}
                                >
                                    <ArrowDropDown />
                                </CustomButton>
                            </FieldContainer>
                        </>
                    )}

                    {is12HrsFormat && (
                        <CustomList>
                            <CustomListButton
                                selected={is12HrsFormatSelected === 1}
                                onClick={(event) => handleListItemClick(event, 1)}
                            >
                                <CustomListText primary='AM' />
                            </CustomListButton>
                            <CustomListButton
                                selected={is12HrsFormatSelected === 2}
                                onClick={(event) => handleListItemClick(event, 2)}
                            >
                                <CustomListText primary='PM' />
                            </CustomListButton>
                        </CustomList>
                    )}
                </HrsMinContainer>

                <div></div>
                {!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute) && (
                    <HelperText $ampm={is12HrsFormat}> {translate('t.the_time_format_is_invalid')}</HelperText>
                )}

                {!inRangeSeconds(seconds ?? dateValueStaticDatePicker.toObject().second) && (
                    <HelperText $ampm={is12HrsFormat}> {translate('t.the_time_format_is_invalid')}</HelperText>
                )}
                {!inRangeHours(hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)) && (
                    <HelperText $ampm={is12HrsFormat}>{translate('t.the_time_format_is_invalid')}</HelperText>
                )}
            </GridTimePicker>
        </GridContainer>
    );
};

export default DateTimeDialogSelector;
