import React from 'react';
import {
    ButtonDiv,
    ContainerButtonBES,
    SchemaDiv,
    VehicleDetailsSchemaViewContent
} from './VehicleDetailsSchema.style';
import UiButton from '../../Ui/Components/UiButton/UiButton';
import { useRecoilValue } from 'recoil';
import { VehicleDetailsWidgetAtom } from '../VehicleDetailsWidget/VehicleDetailsWidget.atom';
import { useTranslation } from 'react-i18next';

import { SIZE_BUTTON, VARIANT_BUTTON } from '../../Ui/variables';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { VehicleDetailsSchemaProps } from './VehicleDetailsSchema.type';
import VehicleSchema from '../NewVehicleSchema';
import { VehicleModel } from 'models/Vehicle.type';
import { useNavigate } from 'react-router-dom';

export const VehicleDetailsSchemaContent: React.FC<VehicleDetailsSchemaProps> = (props): JSX.Element => {
    const vehicleDetailsWidgetAtom = useRecoilValue(
        VehicleDetailsWidgetAtom(props.vehicleAtomId ? `${'VehicleSchemaAtom-' + props.vehicleAtomId}` : undefined)
    );
    const { t: translate } = useTranslation();
    const vehicleId = (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id;
    const customerSettings = useRecoilValue(CustomerSettings);
    const navigate = useNavigate();

    return (
        <>
            <VehicleDetailsSchemaViewContent data-testid='VehicleDetailsSchemaContent'>
                <SchemaDiv noBorder={props.noBorder}>
                    <VehicleSchema
                        schema={
                            (vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.vehicleConfigurationV2
                                ?.distribution || '2-2'
                        }
                        emptySchema={false}
                        vehicleId={(vehicleDetailsWidgetAtom.vehicleDetails as VehicleModel)?.id}
                        vehicleAtomId={props.vehicleAtomId}
                    />
                </SchemaDiv>
                {!props.vehicleAtomId && (
                    <ButtonDiv>
                        <UiButton
                            variant='contained'
                            skin='success'
                            style={{ margin: '5px' }}
                            size={SIZE_BUTTON}
                            onClick={() => {
                                navigate(`/vehicle/${vehicleId}/statistics`);
                            }}
                            testid='VehicleStatus-LinkToVehicleStatistics-Button'
                        >
                            {translate('t.statistics')}
                        </UiButton>

                        {customerSettings.mdd_bes && (
                            <ContainerButtonBES>
                                <UiButton
                                    onClick={() => {
                                        navigate(`/severity-rank/${vehicleId}?back=vehicle-status`);
                                    }}
                                    skin='link'
                                    color='info'
                                    variant={VARIANT_BUTTON}
                                    style={{ textAlign: 'center', margin: '5px' }}
                                    testid='VehicleStatus-LinkToVehicleBesSeverityRank-Button'
                                >
                                    {translate('t.tyre_severity')}
                                </UiButton>
                            </ContainerButtonBES>
                        )}
                    </ButtonDiv>
                )}
            </VehicleDetailsSchemaViewContent>
        </>
    );
};
