import React from 'react';
import { UiTable3HideColumnsViewContent } from './UiTable3HideColumns.style';
import { UiTable3HideColumnsProps } from './UiTable3HideColumns.type';
import Legend from '../Components/UiLegend';
import { IconButton } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import UiTable3HideColumnsContentView from '../UiTable3HideColumnsContent';

const UiTable3HideColumnsContent: React.FC<UiTable3HideColumnsProps> = (props): JSX.Element => {
    return (
        <UiTable3HideColumnsViewContent data-testid='UiTable3HideColumnsContent'>
            <Legend
                root={
                    <IconButton size='small'>
                        <ViewColumnIcon />
                    </IconButton>
                }
                icon={<ViewColumnIcon />}
                width={400}
            >
                <UiTable3HideColumnsContentView queryKey={props.queryKey} />
            </Legend>
        </UiTable3HideColumnsViewContent>
    );
};

export default UiTable3HideColumnsContent;
