import React from 'react';
import {
    CancelButton,
    ContainerActions,
    ContainerDialogTitle,
    CustomDialog,
    CustomDialogActions,
    Header,
    LeftTitle,
    RightTitle
} from './Modal.style';
import { Modal2ViewProps } from './Modal.type';
import MuiDialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import UiScroll from '../../Ui/Components/UiScroll/UiScroll';

export const ModalContent: React.FC<Modal2ViewProps> = ({
    id,
    cancelButtonText,
    onClose,
    isOpen,
    width,
    height,
    widthUnit,
    heightUnit,
    leftTitle,
    rightTitle,
    customContent,
    content,
    cancelButtonColor,
    buttons,
    replaceButtons
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <CustomDialog
            id={id}
            onClose={() => {
                if (onClose) {
                    onClose();
                }
            }}
            aria-labelledby='customized-dialog-title'
            open={isOpen ?? false}
            data-testid={`ModalContent-${id || 'testid'}`}
            width={width}
            height={height}
            widthunit={widthUnit}
            heightunit={heightUnit}
        >
            <ContainerDialogTitle>
                <Header>
                    <LeftTitle>{leftTitle}</LeftTitle>
                    <RightTitle>{rightTitle}</RightTitle>
                </Header>
            </ContainerDialogTitle>

            {customContent ? (
                customContent
            ) : (
                <>
                    <MuiDialogContent>
                        <UiScroll height='100%' content={content} />
                    </MuiDialogContent>
                    <ContainerActions>
                        <CustomDialogActions>
                            {buttons && buttons}
                            {!replaceButtons && (
                                <CancelButton
                                    variant='outlined'
                                    color='primary'
                                    size='small'
                                    data-testid='Modal-Cancel-Button'
                                    onClick={() => {
                                        if (onClose) {
                                            onClose();
                                        }
                                    }}
                                    customcolor={cancelButtonColor}
                                >
                                    {cancelButtonText ? cancelButtonText : translate('t.cancel')}
                                </CancelButton>
                            )}
                        </CustomDialogActions>
                    </ContainerActions>
                </>
            )}
        </CustomDialog>
    );
};
