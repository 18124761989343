import React from 'react';
import * as style from './Tooltip.style';
import * as type from './Tooltip.type';

const createTooltipTable = (props: type.TooltipProps, showInTooltip: type.tooltipCondition): JSX.Element => {
    const data = props.payload ? [...props.payload] : [];
    const dataIterator = 5;
    let tables: JSX.Element[] = [];
    while (data.length) {
        const d = data.splice(0, dataIterator);
        tables.push(
            <table>
                <thead></thead>
                <tbody>
                    {d
                        ?.filter((filteredTooltip) => showInTooltip(filteredTooltip))
                        .map((tooltip, index) => {
                            const displayNoValue: boolean = tooltip.strokeWidth === 0;
                            return (
                                <style.ColoredTr key={index} color={tooltip.stroke}>
                                    <td>{props.translator(tooltip.name)}: </td>
                                    <style.ValueTd>
                                        {displayNoValue ? '-' : `${tooltip.value} ${tooltip.unit || ''}`}
                                    </style.ValueTd>
                                </style.ColoredTr>
                            );
                        })}
                </tbody>
            </table>
        );
    }

    return <>{tables}</>;
};

const TooltipContent: React.FC<type.TooltipProps> = (props): JSX.Element => {
    const showAlienData: boolean =
        props.payload && props.payload?.length ? props.payload[0]?.payload?.showAlienData : false;
    const anyData: boolean = (props.payload?.length && Object.keys(props.payload[0].payload).length > 0) as boolean;
    /*(props.payload?.length && props.payload[0].payload?.sensorId) ||
        (props.payload?.length && props.payload[0].payload?.speed);*/
    const showInTooltip: type.tooltipCondition = (value) => !props.excludeFromTooltip.includes(value.dataKey);
    if (props.active && anyData) {
        return (
            <style.TooltipContent showAlienData={showAlienData} data-testid='TooltipContent'>
                {props.active && anyData && createTooltipTable(props, showInTooltip)}
            </style.TooltipContent>
        );
    }
    return <></>;
};

export default TooltipContent;
