import React from 'react';
import { VehicleStatusIconsContent } from './VehicleStatusIcons.view';
import { VehicleStatusIconsProps } from './VehicleStatusIcons.type';
import { Wrapper } from 'helpers/wrapper';

const VehicleStatusIcons: React.FC<VehicleStatusIconsProps> = ({ data, displayAlerts }): JSX.Element => {
    return (
        <VehicleStatusIconsContent data-testid='VehicleStatusIcons-testid' data={data} displayAlerts={displayAlerts} />
    );
};

export default Wrapper(VehicleStatusIcons);
