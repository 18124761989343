import React from 'react';
import * as style from './HubConnectionIndicator.style';
import * as type from './HubConnectionIndicator.type';

export const HubConnectionIndicatorContent: React.FC<type.HubConnectionIndicatorViewProps> = (props): JSX.Element => (
    <style.HubConnectionIndicatorContent data-testid='HubConnectionIndicatorContent'>
        <style.IndicatorIcon
            color={props.color}
            icon={['fas', props.connectionType === 'eth0' ? 'signal' : 'wifi']}
            size='sm'
        />
    </style.HubConnectionIndicatorContent>
);
