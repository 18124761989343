import React from 'react';
import { StatisticsMapContainer } from './StatisticsMap.style';
import * as type from './StatisticsMap.type';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DateTimePicker, SelectedVehicle } from '../../states/global/Statistics';
import Button from '../Ui/Components/UiButton';
import { SUCCESS } from '../Ui/colors';
import { ShowSaveRouteModal } from '../../states/global/Modal';
import SaveRoutePopup from '../SaveRoutePopup';

import { useTranslation } from 'react-i18next';
import WidgetStatisticsMap from './WidgetStatisticsMap/WidgetStatisticsMap';
import WidgetStatisticsSpeed from '../StatisticsGraphs/WidgetStatisticsSpeed/WidgetStatisticsSpeed';

export const StatisticsMapContent: React.FC<type.StatisticsMapViewProps> = (): JSX.Element => {
    const globalDateTimePicker = useRecoilValue(DateTimePicker);
    const setShowSaveRouteModal = useSetRecoilState(ShowSaveRouteModal);
    const selectedVehicle = useRecoilValue(SelectedVehicle);
    const { t: translate } = useTranslation();

    return (
        <StatisticsMapContainer data-testid='StatisticsMapContent'>
            <WidgetStatisticsSpeed
                vehicleId={selectedVehicle.id}
                show={true}
                onHoverGraph
                enableFastLeak
                headerAction={
                    <div>
                        <Button
                            skin={SUCCESS}
                            color='primary'
                            variant='text'
                            size='small'
                            onClick={() => {
                                setShowSaveRouteModal(true);
                            }}
                            testid='Statistics-SaveRoute-Button'
                        >
                            {translate('t.save_route')}
                        </Button>
                    </div>
                }
            />

            <WidgetStatisticsMap sx={{ marginTop: '10px' }} dateTimePicker={globalDateTimePicker} />

            <SaveRoutePopup />
        </StatisticsMapContainer>
    );
};
