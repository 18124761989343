import React from 'react';
import { BarGroupIcon, BarGroupInfo } from '../TopBar.style';
import { Schedule, Today } from '@mui/icons-material';
import UiLegend from '../../Ui/Components/UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import { CurrentDateFormik } from './CurrentDate.formik';
import { CurrentDateView as CurrentDateViewType } from './CurrentDate.type';
import { ButtonOpenPopUp } from './CurrentDate.style';
import { Tooltip } from '@mui/material';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';

const CurrentDateView: React.FC<CurrentDateViewType> = ({
    closePopUp,
    handleSubmit,
    inicialValuesFormik
}): JSX.Element => {
    const { fromUTCtoUserTimezone } = useConverter();
    const currentDate = fromUTCtoUserTimezone({
        date: DateTime.local().toUTC().toISO(),
        format: 'date',
        displaySeconds: false
    });
    const { t: translate } = useTranslation();

    return (
        <UiLegend
            title={translate(`t.change_date_format`)}
            width={350}
            mode='light'
            close={closePopUp}
            content={<CurrentDateFormik handleSubmit={handleSubmit} inicialValuesFormik={inicialValuesFormik} />}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            root={
                <ButtonOpenPopUp color='inherit' variant='text' testid='EditDateFormat-Button'>
                    <Tooltip
                        title={translate('t.click_to_change_date_format')}
                        placement='bottom'
                        arrow
                        disableInteractive
                    >
                        <BarGroupInfo>
                            <BarGroupIcon>
                                <Today />
                            </BarGroupIcon>
                            {currentDate}
                        </BarGroupInfo>
                    </Tooltip>
                </ButtonOpenPopUp>
            }
        />
    );
};
export default CurrentDateView;
