import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '../../UiButton/UiButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { LegendContainer, FilterColumnsContent, FilterColumnCover, TextContainer } from './FilterColumns.style';
import { FilterColumnsProps } from './FilterColumns.type';
import Legend from '../../UiLegend/UiLegend';
import { useTranslation } from 'react-i18next';
import { SIZE_BUTTON } from '../../../variables';
import useUserLocalStorageSettings from '../../../../CustomHooks/UserLocalStorageSettings';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/pro-solid-svg-icons';

const FilterColumns: React.FC<FilterColumnsProps> = (props): JSX.Element => {
    const [close, setClose] = useState<boolean>(false);
    const { t: translate } = useTranslation();
    const { setUserSettings } = useUserLocalStorageSettings([`table.${props.table.tableKey}Table`]);

    useEffect(() => {
        if (close) {
            setClose(false);
        }
    }, [close]);

    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            props.setCurrentFilters(props.table.state.filters);
            props.table.tableKey && setClose(true);
        }
    };

    const setValues = (typeAction: 'apply' | 'apply_save' = 'apply') => {
        props.setCurrentFilters(props.table.state.filters.filter((filter) => filter.value.value !== undefined));
        if (typeAction === 'apply_save') {
            if (props.table.state.filters && !!props.table.state.filters.length) {
                setUserSettings(`table.${props.table.tableKey}Table`, {
                    orderBy: props.table.state.sortBy,
                    pageSize: props.table.state.pageSize,
                    hiddenColumns: props.table.state.hiddenColumns,
                    filter: props.table.state.filters.filter((filter) => {
                        if (filter.value.value instanceof DateTime) {
                            return filter.value.value.isValid;
                        }
                        return true;
                    })
                });
            }
        }
        setClose(true);
    };

    const LegendContent: React.FC = (): JSX.Element => (
        <table>
            <tbody>
                <tr>
                    <td>
                        <LegendContainer>{translate('t.apply')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>{translate('p.apply_legend')}</TextContainer>
                    </td>
                </tr>
                <tr>
                    <td>
                        <LegendContainer extraWidth={true}>{translate('t.apply_save')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>
                            {translate('p.apply_save_legend')}
                            <FontAwesomeIcon icon={faFloppyDisk} />{' '}
                        </TextContainer>{' '}
                    </td>
                </tr>
                <tr>
                    <td>
                        <LegendContainer>{translate('t.reset')}</LegendContainer>
                    </td>
                    <td>
                        {' '}
                        <TextContainer>{translate('p.reset_legend')}</TextContainer>{' '}
                    </td>
                </tr>
            </tbody>
        </table>
    );

    return (
        <FilterColumnsContent data-testid={`FilterColumns-${props.table.tableKey}Table-Content`}>
            <Legend
                icon={<FilterListIcon />}
                root={
                    <IconButton data-testid={`FilterColumns-${props.table.tableKey}Table-Button`} size={SIZE_BUTTON}>
                        <FilterListIcon />
                    </IconButton>
                }
                width={350}
                close={close}
                headerElements={
                    <>
                        <Button
                            testid={`FilterColumns-${props.table.tableKey}Table-Apply-Button`}
                            onClick={() => {
                                setValues();
                            }}
                        >
                            {translate('t.apply')}
                        </Button>
                        <Button
                            testid={`FilterColumns-${props.table.tableKey}Table-Save-Button`}
                            onClick={() => {
                                setValues('apply_save');
                            }}
                        >
                            {translate('t.apply_save')}
                        </Button>
                        <Button
                            testid={`FilterColumns-${props.table.tableKey}Table-Reset-Button`}
                            onClick={() => {
                                props.table.setAllFilters([]);
                                setUserSettings(`table.${props.table.tableKey}Table`, {
                                    orderBy: props.table.state.sortBy,
                                    pageSize: props.table.state.pageSize,
                                    hiddenColumns: props.table.state.hiddenColumns,
                                    filter: []
                                });
                            }}
                            title='Reset the filters to the default values'
                        >
                            {translate('t.reset')}
                        </Button>
                        <Legend title={translate('t.legend')} content={<LegendContent />} />
                    </>
                }
                onClose={() => {
                    props.table.setAllFilters(props.currentFilters);
                    setClose(false);
                }}
            >
                <div>
                    <FilterColumnCover onKeyPress={handleKeypress}>
                        {props.allColumns
                            .filter((column) => column.canFilter)
                            .map((column, index) => (
                                <React.Fragment key={index}>{column.render('Filter')}</React.Fragment>
                            ))}
                    </FilterColumnCover>
                </div>
            </Legend>
        </FilterColumnsContent>
    );
};

export default FilterColumns;
