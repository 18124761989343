import React, { useMemo } from 'react';
import {
    CustomizedUiHighPressureIcon,
    CustomizedUiLowPressureIcon,
    PressueIconCover,
    TemperatureGenericDisabled,
    VehicleStatsLegendContainer
} from './VehicleStatsLegend.style';
import { VehicleStatsLegendProps } from './VehicleStatsLegend.type';
import { useRecoilValue } from 'recoil';
import { LegenItemType } from '../VehicleStats/VehicleStats.type';
import { applyStyleByMode } from 'helpers';
import { RANK0, SECONDARY_DARK_MODE, WHITE } from 'components/Ui/colors';
import { Theme } from '../../../states/global/Theme';
import { LegendBody, LegendItemContainer } from '../VehicleStats/VehicleStats.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UiLegend from 'components/Ui/Components/UiLegend/UiLegend';
import { CustomerSettings } from 'states/global/CustomerSettings';
import { useTranslation } from 'react-i18next';
import { faSlash as faSlashRegular } from '@fortawesome/pro-regular-svg-icons';
import { faThermometerThreeQuarters } from '@fortawesome/pro-solid-svg-icons';

const VehicleStatsLegendContent: React.FC<VehicleStatsLegendProps> = (): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);
    const { t: translate } = useTranslation();

    const legendItems = useMemo(() => {
        let legend: LegenItemType[] = [
            {
                title: `${translate('t.vehicle_with_alert_level')} 1`,
                color: '#c1c121',
                icon: ['fas', 'square']
            },
            {
                title: `${translate('t.vehicle_with_alert_level')} 2`,
                color: '#ea9800',
                icon: ['fas', 'square']
            },
            {
                title: `${translate('t.vehicle_with_alert_level')} 3`,
                color: '#ff0000',
                icon: ['fas', 'square']
            },
            {
                title: translate('t.transmitting_hub'),
                color: '#158015',
                icon: ['fas', 'wifi']
            },
            {
                title: translate('t.transmitting_hub_15_mns_1_day'),
                color: '#de9d0d',
                icon: ['fas', 'wifi']
            },
            {
                title: translate('t.not_transmitting_hub'),
                color: applyStyleByMode({
                    styleOld: '#959595',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'wifi']
            },
            {
                title: translate('t.transmitting_hub'),
                color: '#158015',
                icon: ['fas', 'signal']
            },
            {
                title: translate('t.not_transmitting_hub'),
                color: applyStyleByMode({
                    styleOld: '#959595',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'signal']
            },
            {
                title: translate('t.not_transmitting_gps_first'),
                color: '#ffa500',
                icon: ['fas', 'location-dot-slash']
            },
            {
                title: translate('t.not_transmitting_gps_second'),
                color: '#ff1616',
                icon: ['fas', 'location-dot-slash']
            },
            {
                title: translate('t.not_transmitting_gps_data'),
                color: applyStyleByMode({
                    styleOld: '#0000ff',
                    theme: ThemeMode?.mode,
                    light: '#0000ff',
                    dark: '#0984e3'
                }),
                icon: ['fas', 'location-dot-slash']
            },
            {
                title: translate('t.vehicle_without_hub'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'rectangle-xmark']
            },
            {
                title: translate('t.vehicle_in_maintenance'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'wrench']
            },
            {
                title: translate('t.parked_vehicle'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'parking']
            },
            {
                title: translate('t.hub_manually_turned_off'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'power-off']
            },
            {
                title: translate('t.empty_battery'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'battery-empty']
            },
            {
                title: `${translate('t.battery_is_below')} 7 V`,
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'battery-quarter']
            },
            {
                title: `${translate('t.battery_is_below')} 7.5 V`,
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'battery-half']
            },
            {
                title: `${translate('t.battery_is_below')} 8 V`,
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'battery-three-quarters']
            },
            {
                title: translate('t.temperature_alert'),
                color: applyStyleByMode({
                    styleOld: '#FF1616',
                    theme: ThemeMode?.mode,
                    light: '#FF1616',
                    dark: WHITE
                }),
                icon: ['fas', 'thermometer-three-quarters']
            },
            {
                title: translate('t.temperature_alert_disabled'),
                color: applyStyleByMode({
                    styleOld: '#FF1616',
                    theme: ThemeMode?.mode,
                    light: '#FF1616',
                    dark: WHITE
                }),
                iconElement: (
                    <TemperatureGenericDisabled component={'div'}>
                        <span className='fa-layers fa-fw'>
                            <FontAwesomeIcon
                                icon={faSlashRegular}
                                fontSize='16px'
                                color={applyStyleByMode({
                                    styleOld: '#FF1616',
                                    theme: ThemeMode?.mode,
                                    dark: WHITE,
                                    light: '#787878'
                                })}
                            />
                            <FontAwesomeIcon
                                icon={faThermometerThreeQuarters}
                                fontSize='16px'
                                color={applyStyleByMode({
                                    styleOld: '#FF1616',
                                    theme: ThemeMode?.mode,
                                    dark: WHITE,
                                    light: '#787878'
                                })}
                            />
                        </span>
                    </TemperatureGenericDisabled>
                )
            },
            {
                title: translate('t.low_pressure_alert'),
                iconElement: (
                    <PressueIconCover component={'span'}>
                        <CustomizedUiLowPressureIcon />
                    </PressueIconCover>
                )
            },
            {
                title: translate('t.high_pressure_alert'),
                iconElement: (
                    <PressueIconCover component={'span'}>
                        <CustomizedUiHighPressureIcon />
                    </PressueIconCover>
                )
            }
        ];
        if (customerSettings.temperature_prediction) {
            legend.splice(-3, 0, {
                title: translate('t.has_temperature_prediction'),
                color: applyStyleByMode({
                    styleOld: '#000',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                icon: ['fas', 'chart-mixed-up-circle-currency']
            });
        }

        if (customerSettings.mdd_bes) {
            legend = legend.concat([
                {
                    title: 'Highest BES on vehicle',
                    iconElement: (
                        <div
                            style={{
                                borderRadius: '3px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: RANK0,
                                width: '19px',
                                height: '15px',
                                fontSize: '0.6rem',
                                color: '#fff',
                                marginTop: '3px',
                                marginRight: '2px'
                            }}
                        >
                            BES
                        </div>
                    )
                }
            ]);
        }

        return legend;
    }, [ThemeMode, translate, customerSettings]);

    const LegendItem: React.FC<LegenItemType> = ({ color, title, icon, iconElement }): JSX.Element => {
        return (
            <LegendItemContainer>
                {iconElement && (
                    <>
                        {iconElement} {title}
                    </>
                )}
                {icon && (
                    <>
                        <FontAwesomeIcon icon={icon} size='lg' transform='down-2.5 left-6' fixedWidth color={color} />{' '}
                        {title}
                    </>
                )}
            </LegendItemContainer>
        );
    };

    const LegendList: React.FC = (): JSX.Element => {
        return (
            <>
                {legendItems.map((legendItem, index) => (
                    <LegendItem key={index} {...legendItem} />
                ))}
            </>
        );
    };

    return (
        <VehicleStatsLegendContainer data-testid='VehicleStatsLegendContent'>
            <UiLegend
                title={translate('t.legend')}
                width={340}
                content={
                    <LegendBody>
                        <LegendList />
                    </LegendBody>
                }
            />
        </VehicleStatsLegendContainer>
    );
};

export default VehicleStatsLegendContent;
