import { useQuery } from '@tanstack/react-query';
import VehicleModel from 'api/VehicleModel';
import VehicleManufacturer from 'api/VehicleManufacturer';
import Device from 'api/Device';
import VehicleConfigurationApi from 'api/VehicleConfiguration';
import VehicleTypeApi from 'api/VehicleType';
import { VehicleManufacturerType } from 'models/VehicleManufacturer.type';
import { VehicleModelType } from 'models/VehicleModel.type';
import { DeviceType } from 'models/Device.type';
import { QueriesTypes } from './VehicleActions.type';
import { VehicleTypesCodeBook } from 'models/VehicleType.type';
import { VehicleConfigurationsType } from 'models/VehicleConfiguration.type';
import { useRecoilValue } from 'recoil';
import { ShowVehicleModal } from 'states/global/Modal';
import ExternalSystemAPI from 'api/ExternalSystem';

const vehicleManufacturerAPI = new VehicleManufacturer();
const vehicleModelAPI = new VehicleModel();
const deviceAPI = new Device();
const vehicleConfigurationAPI = new VehicleConfigurationApi();
const vehicleTypeAPI = new VehicleTypeApi();
const externalSystemAPI = new ExternalSystemAPI();

export const useVehicleActionsQueries = (): QueriesTypes => {
    const modalIsOpen = useRecoilValue(ShowVehicleModal);
    const vehicleModelData = useQuery<VehicleModelType>(
        ['GetVehicleModel'],
        () => vehicleModelAPI.get({ extendUrl: 'customer' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen
        }
    );
    const vehicleManufacturerData = useQuery<VehicleManufacturerType>(
        ['GetVehicleManufacturer'],
        () => vehicleManufacturerAPI.get({}),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen
        }
    );
    const availableDevices = useQuery<DeviceType>(['GetAvailableDevices'], () => deviceAPI.getAvailableDevices(), {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        enabled: modalIsOpen
    });
    const vehicleTypes = useQuery<VehicleTypesCodeBook>(
        ['GetVehicleTypes'],
        () => vehicleTypeAPI.getCodebook({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen
        }
    );
    const vehicleConfiguration = useQuery<VehicleConfigurationsType>(
        ['GetVehicleConfiguration'],
        () => vehicleConfigurationAPI.get({ version: 'v2' }),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: modalIsOpen
        }
    );

    const externalSystems = useQuery(['GetCustomerExternalSystems'], () => externalSystemAPI.getCurrent(), {
        refetchOnWindowFocus: false,
        refetchInterval: false,
        enabled: modalIsOpen
    });

    const loading: boolean =
        vehicleModelData.isLoading &&
        vehicleManufacturerData.isLoading &&
        availableDevices.isLoading &&
        vehicleTypes.isLoading &&
        vehicleConfiguration.isLoading &&
        externalSystems.isLoading;

    return {
        vehicleModelData: vehicleModelData.data,
        vehicleManufacturerData: vehicleManufacturerData.data,
        availableDevices: availableDevices,
        vehicleTypes: vehicleTypes.data,
        vehicleConfiguration: vehicleConfiguration.data,
        externalSystems: externalSystems.data,
        isLoading: loading
    };
};
