import PortalApi from 'helpers/api/PortalApiClient';
import { getParams } from 'helpers';
import { LoggerRequestModel, LoggerRequestResponse } from '../models/LoggerRequest.type';

export default class LoggerRequest extends PortalApi<LoggerRequestModel> {
    route = 'logger-request';

    getUserActionsTable = async (props): Promise<LoggerRequestResponse> => {
        return await this.getByCriteria({ criteria: getParams(props) });
    };
}
