import React, { useEffect, useCallback } from 'react';
import { isInPeriod } from 'helpers';
import { CustomModalDialog, DialogPaperProps, UiDateRangePicker2El } from './UiDateRangePicker2.style';
import { UiDatePickerProps } from './UiDateRangePicker2.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import DateTimeDialogSelector from './Components/DateTimeDialogSelector/DateTimeRangeDialogSelector';
import DateTimePicker from './Components/DateTimePicker/DateTimePicker';
import { RangePositionCalendar } from './Components/DateTimeDialogSelector/DateTimeRangeDialogSelector.type';
import { LastChangedValue, RangePosition } from './UiDateRangePicker2.atom';

const UiDatePicker2Content: React.FC<UiDatePickerProps> = (props): JSX.Element => {
    const [openDate, setOpenDate] = React.useState(false);
    const [rangePosition, setRangePosition] = useRecoilState(RangePosition);
    const [dateValue, setDateValue] = React.useState<DateTime[] | null>([
        (typeof props.dateFrom === 'string' ? DateTime.fromISO(props.dateFrom) : props.dateFrom) || DateTime.local(),
        (typeof props.dateTo === 'string' ? DateTime.fromISO(props.dateTo) : props.dateTo) || DateTime.local()
    ]);
    const lastChangedValue = useRecoilValue(LastChangedValue);
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = React.useState<DateTime[] | null>(dateValue);

    const outOfPeriod = useCallback(
        (dateFrom: DateTime, dateTo: DateTime): boolean => {
            if (props.adjustToPeriod) {
                return !isInPeriod(dateFrom, dateTo, props.adjustToPeriod?.period, props.adjustToPeriod?.periodType);
            }
            return false;
        },
        [props.adjustToPeriod]
    );

    const updateBothDateByPeriod = (newDateFrom: DateTime, newDateTo: DateTime): DateTime[] => {
        if (outOfPeriod(newDateFrom, newDateTo) && props.adjustToPeriod && dateValue) {
            if (lastChangedValue === RangePositionCalendar.START) {
                return [
                    newDateFrom,
                    newDateFrom.plus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period })
                ];
            } else if (lastChangedValue === RangePositionCalendar.END) {
                return [
                    newDateTo.minus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period }),
                    newDateTo
                ];
            }
        }

        if (!props.validFutureDate) {
            const currentDate = DateTime.local();

            if (newDateFrom.c && newDateTo.c) {
                if (newDateFrom.c.day === newDateTo.c.day) {
                    if (
                        newDateFrom?.valueOf() > currentDate.valueOf() ||
                        newDateTo?.valueOf() > currentDate.valueOf()
                    ) {
                        if (rangePosition === RangePositionCalendar.START) {
                            return [currentDate.minus({ minute: 1 }), newDateTo];
                        }
                        if (rangePosition === RangePositionCalendar.END) {
                            return [newDateFrom, currentDate];
                        }
                    }
                }
            }

            if (newDateFrom?.valueOf() > newDateTo?.valueOf()) {
                const startDate = newDateTo.minus({ minutes: 1 });
                return [startDate, newDateTo];
            }

            if (newDateFrom?.valueOf() > currentDate.valueOf() || newDateTo?.valueOf() > currentDate.valueOf()) {
                if (rangePosition === RangePositionCalendar.START) {
                    return [currentDate.minus({ minute: '1' }), currentDate];
                }

                if (rangePosition === RangePositionCalendar.END) {
                    return [newDateFrom, currentDate];
                }

                const startDate = props.adjustToPeriod
                    ? currentDate.minus({ [`${props.adjustToPeriod.periodType}`]: props.adjustToPeriod.period })
                    : currentDate.minus({ days: 1 });
                return [startDate, currentDate];
            }
        }

        return [newDateFrom, newDateTo];
    };

    const validateDatesForModal = () => {
        if (dateValue) {
            let updatedDateValue: DateTime;
            let updatedOnDateChange: DateTime;

            if (dateValue[0].invalid || dateValue[0] === '' || dateValue[1].invalid || dateValue[1] === '') {
                updatedDateValue = [
                    dateValue[0].invalid || dateValue[0] === '' ? DateTime.local().minus({ days: 1 }) : dateValue[0],
                    dateValue[1].invalid || dateValue[1] === '' ? DateTime.local() : dateValue[1]
                ];
            } else {
                updatedDateValue = updateBothDateByPeriod(dateValue[0], dateValue[1]);
            }

            updatedOnDateChange = updatedDateValue;

            setDateValue(updatedDateValue);
            props.onDateChange && props.onDateChange(updatedOnDateChange);
        }
    };

    const onDateChangeFrom = (newDate: DateTime): void => {
        if (newDate == null) {
            setDateValue(['', dateValue && dateValue[1]]);
            props.onDateChange && props.onDateChange(['', dateValue && dateValue[1]]);
        } else {
            if (newDate.invalid) {
                setDateValue([newDate, dateValue && dateValue[1]]);
                props.onDateChange && props.onDateChange([newDate, dateValue && dateValue[1]]);
            } else {
                setDateValue(updateBothDateByPeriod(newDate, dateValue && dateValue[1]));

                props.onDateChange && props.onDateChange(updateBothDateByPeriod(newDate, dateValue && dateValue[1]));
            }
        }
    };

    const onDateChangeTo = (newDate: DateTime): void => {
        if (newDate == null) {
            setDateValue([dateValue && dateValue[0], '']);
            props.onDateChange && props.onDateChange([dateValue && dateValue[0], '']);
        } else {
            if (newDate.invalid) {
                setDateValue([dateValue && dateValue[0], newDate]);
                props.onDateChange && props.onDateChange([dateValue && dateValue[0], newDate]);
            } else {
                setDateValue(updateBothDateByPeriod(dateValue && dateValue[0], newDate));

                props.onDateChange && props.onDateChange(updateBothDateByPeriod(dateValue && dateValue[0], newDate));
            }
        }
    };

    useEffect(() => {
        props.dateFrom && setDateValue([DateTime.fromISO(props.dateFrom), props.dateTo]);
    }, [props.dateFrom, props.dateTo]);

    useEffect(() => {
        props.dateTo && setDateValue([props.dateFrom, DateTime.fromISO(props.dateTo)]);
    }, [props.dateFrom, props.dateTo]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiDateRangePicker2El data-testid='UiDatePickerContent'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DateTimePicker
                    onlyDate={props.onlyDate}
                    inputFormat={props.inputFormatFrom || props.inputFormat}
                    testId={props.testIdFrom}
                    label={props.dateFromLabel}
                    onFocus={() => setRangePosition(RangePositionCalendar.START)}
                    onOpen={() => {
                        setOpenDate(true);
                        validateDatesForModal();
                        setRangePosition(RangePositionCalendar.START);
                    }}
                    value={dateValue && dateValue[0]}
                    onDateChange={onDateChangeFrom}
                />

                <DateTimePicker
                    inputFormat={props.inputFormatTo || props.inputFormat}
                    testId={props.testIdTo}
                    onlyDate={props.onlyDate}
                    label={props.dateToLabel}
                    onFocus={() => setRangePosition(RangePositionCalendar.END)}
                    onOpen={() => {
                        setOpenDate(true);
                        validateDatesForModal();
                        setRangePosition(RangePositionCalendar.END);
                    }}
                    value={dateValue && dateValue[1]}
                    onDateChange={onDateChangeTo}
                />

                <CustomModalDialog
                    open={openDate}
                    DialogProps={DialogPaperProps(props.onlyDate)}
                    onCancel={() => {
                        setOpenDate(false);
                        dateValue &&
                            setDateValueStaticDatePicker([
                                dateValue[0].invalid ? DateTime.local() : dateValue[0],
                                dateValue[1].invalid ? DateTime.local() : dateValue[1]
                            ]);
                    }}
                    onSetToday={() => {}}
                    onClear={() => {}}
                    onDismiss={() => setOpenDate(false)}
                    onAccept={() => {
                        if (
                            dateValueStaticDatePicker &&
                            !dateValueStaticDatePicker[0].invalid &&
                            !dateValueStaticDatePicker[1].invalid
                        ) {
                            setDateValue(
                                updateBothDateByPeriod(dateValueStaticDatePicker[0], dateValueStaticDatePicker[1])
                            );

                            props.onDateChange &&
                                props.onDateChange(
                                    updateBothDateByPeriod(dateValueStaticDatePicker[0], dateValueStaticDatePicker[1])
                                );
                        }

                        setOpenDate(false);
                    }}
                >
                    <DateTimeDialogSelector
                        dateValueStaticDatePickerFrom={dateValueStaticDatePicker && dateValueStaticDatePicker[0]}
                        dateValueStaticDatePickerTo={dateValueStaticDatePicker && dateValueStaticDatePicker[1]}
                        onlyDate={props.onlyDate}
                        adjustToPeriod={props.adjustToPeriod}
                        validFutureDate={props.validFutureDate}
                        outOfPeriod={outOfPeriod}
                        setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                    />
                </CustomModalDialog>
            </LocalizationProvider>
        </UiDateRangePicker2El>
    );
};

export default UiDatePicker2Content;
