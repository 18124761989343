import { DateTimeType } from '../../StatisticsTopBar/StatisticsTopBar.type';
import { FilterOptionProps } from '../UiTable3Header/UiTable3Header.type';

export type UiTable3FilterCellProps = {
    accessor: string;
    filterProps?: FilterOptionProps;
    queryKey?: string;
};

export enum SIGNS {
    LOWER_THAN = '<',
    HIGHER_THAN = '>',
    FULLTEXT = '*',
    EQUAL = '=',
    NEGATE = '!'
}

export type SignedDateTimeIsValid = {
    isValid: boolean;
    value: DateTimeType;
    accessor: string;
};

export type SignedNumberIsValid = Omit<SignedDateTimeIsValid, 'value'> & {
    value: string;
};

export type ParseSignedValueAndAccessorType = {
    splitValue: string[];
    accessor: string;
};
