import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as type from './Map.type';
import {
    CustomLayers,
    findFeatureInLayerType,
    findLayerByNameType,
    focusToLayerType,
    LayerChainingDependency,
    LayerOrLayers,
    moveFeatureToType,
    ResponseGeneralByClick
} from './Map.type';
import MapView from './Map.view';
import Map from 'ol/Map';
import View from 'ol/View';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import * as OlProj from 'ol/proj';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import Vector from 'ol/source/Vector';
import { shiftKeyOnly, singleClick } from 'ol/events/condition';
import { Geometry, Point, Polygon } from 'ol/geom';
import Feature, { FeatureLike } from 'ol/Feature';
import { defaults as defaultControls, ScaleLine } from 'ol/control';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import LayerSwitcher from 'ol-layerswitcher';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
    IsBesMap,
    LoadingMapdata,
    MapAction,
    MapActionData,
    MapActionDataType,
    MapActionType
} from 'states/global/Map';
import {
    drawJourney,
    drawJourneys,
    drawJourneysCircle,
    drawOnlyTheJourney,
    getBesSevereDriving,
    drawSlopeInclinationJourney,
    showSlopeInfo,
    redrawSlopeInclinalJourney
} from './Handlers/JourneyHandler';
import { drawArea, drawAreas } from './Handlers/AreaHandler';
import { clearAllIntervals, drawVehicleAtTime, drawVehiclesPosition } from './Handlers/VehicleHandler';
import AzureMapApi from 'api/AzureMap';
import axios from 'axios';
import { Timeline } from 'states/global/Statistics';
import { CloseDrawControl, DrawControl } from './Controls';
import Overlay from 'ol/Overlay';
import { Pixel } from 'ol/pixel';
import { UserInfo } from 'states/global/User';
import { drawHeatmap } from './Handlers/HeatmapHandler';
import { drawSpeedIncidents } from './Handlers/SpeedIncidentHandler';
import { drawExcessiveAcc } from './Handlers/ExcessiveAccHandler';
import { Extent } from 'ol/extent';
import Layer from 'ol/layer/Layer';
import { Collection, Tile } from 'ol';
import {
    AreaEditorFormAtom,
    AreaEditorFormDispatcher,
    AreaEditorFormTypesEnum
} from '../Tracking/AreaEditorForm/AreaEditorForm.atom';
import { useTranslation } from 'react-i18next';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';
import { Wrapper } from 'helpers/wrapper';

const EXTENT_PADDING: [number, number, number, number] = [50, 50, 50, 50];
const createOverlay = (): Overlay => {
    return new Overlay({
        element: document.getElementById('map-popup-general') as HTMLElement | undefined,
        id: 'map-popup-overlay'
    });
};

let drawControl;
let closeDrawControl;
let drawInteraction;

const MapManager: React.FC<type.MapProps> = (props): JSX.Element => {
    const [mapInstance, setMapInstance] = useState<Map>();
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const setLoadingMapData = useSetRecoilState(LoadingMapdata);
    const userInfo = useRecoilValue(UserInfo);
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const isBesMap = useRecoilValue(IsBesMap);
    const AzureMap = new AzureMapApi();
    const mapType = useMemo(
        () => userInfo.user?.userSetting.mapType || type.MapTypes.osm,
        [userInfo.user?.userSetting.mapType]
    );
    const timeline = useRecoilValue(Timeline);
    const resetMapAction = useResetRecoilState(MapAction);
    const resetMapActionData = useResetRecoilState(MapActionData);
    const [areaEditorState, setAreaEditorState] = useRecoilState(AreaEditorFormAtom);
    const dispatchInAreaEditor = AreaEditorFormDispatcher(areaEditorState, setAreaEditorState);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [warningMsg, setWarningMsg] = useState<string>('');
    const [layoutType, setLayoutType] = useState<CustomLayers>();
    const overlay = useRef() as React.MutableRefObject<Overlay | undefined>;
    const accessTokenMap =
        'sk.eyJ1IjoidHJhbnNlbnNlIiwiYSI6ImNrMngxem05bTA3Z2QzbW9tbXQ5bWNka3UifQ.EDIQw7SlHC4o9YoohJLb5A';
    let id!: string | undefined;
    let loadingDataTimer;
    const closePopupEl = document.getElementById('popup-closer');
    const infoPopup = document.getElementById('map-info-popup');
    const { t: translate } = useTranslation();
    const customerSettingsAtom = useRecoilValue(CustomerSettingsAtom);

    const defaultSettings = useMemo(
        (): type.DefaultSettings => ({
            maxZoom: 18,
            minZoom: 2,
            zoom: 15,
            areaCenterCoordinates: [37.41, 8.82],
            epsg: ['EPSG:4326', 'EPSG:3857'],
            scaleLine: new ScaleLine({
                units: userInfo.user?.userSetting.altitudeUnit === 'ft' ? 'imperial' : 'metric'
            })
        }),

        []
    );

    //Move to useCallback
    const transformCoordinates = (coordinates): [number, number][] => {
        return coordinates.map((coordinate) => {
            return OlProj.transform(coordinate, defaultSettings.epsg[1], defaultSettings.epsg[0]);
        });
    };

    const triggerOnMount = () => {
        // fill with automatic triggered functionality
    };

    const OnEditAreaEnd = (event: ModifyEvent) => {
        const polygon = event.features.getArray()[0].getGeometry() as Polygon;
        const coordinates = transformCoordinates(polygon.getCoordinates()[0]);

        dispatchInAreaEditor({ type: AreaEditorFormTypesEnum.SET_AREA_COORDINATES, formPayload: coordinates });
    };

    const OnDrawAreaEnd = (event: DrawEvent) => {
        const polygon = event.feature.getGeometry() as Polygon;
        const coordinates = transformCoordinates(polygon.getCoordinates()[0]);

        dispatchInAreaEditor({ type: AreaEditorFormTypesEnum.SET_AREA_COORDINATES, formPayload: coordinates });
        removeDrawControls();
    };

    const providerSettings = useMemo(() => {
        let CustomMapTileLayer = new TileLayer({
            preload: Infinity,
            source: new XYZ({
                attributions: '',
                minZoom: 1,
                maxZoom: 18,
                tileSize: [256, 256],
                url: `//maps.atmstechnology.com/${userInfo.user?.customer.id || 58}/{z}/{x}/{-y}.png`
            }),
            ...(customerSettingsAtom.map_area && {
                extent: OlProj.transformExtent(
                    customerSettingsAtom.map_area,
                    defaultSettings.epsg[0],
                    defaultSettings.epsg[1]
                )
            })
        });

        return {
            osm: {
                layers: [
                    new TileLayer({
                        source: new OSM(),
                        preload: Infinity
                    }),
                    CustomMapTileLayer
                ]
            },
            bing: {
                layers: [CustomMapTileLayer]
            },
            mapbox: {
                layers: [
                    new TileLayer({
                        preload: Infinity,
                        source: new XYZ({
                            url:
                                'https://api.mapbox.com/styles/v1/transense/ck2x2vgy425x81cqthph4kipi/tiles/256/{z}/{x}/{y}?access_token=' +
                                accessTokenMap,
                            crossOrigin: 'Anonymous'
                        })
                    }),
                    CustomMapTileLayer
                ]
            }
        };
    }, [customerSettingsAtom.map_area]);

    const mapElement = useRef() as React.MutableRefObject<HTMLDivElement | undefined>;
    const renewAzureTokenTimeout = useRef<ReturnType<typeof setTimeout>>();
    const azureToken = useRef<string>(localStorage.getItem('aMapToken')) as React.MutableRefObject<string>;

    const toggleLayerManualy = (name: LayerOrLayers, show: boolean): void => {
        const foundLayer: VectorLayer<Vector>[] = findLayerByName(name);
        if (foundLayer) {
            foundLayer[0].setVisible(show);
        }
    };

    const findLayerByName: findLayerByNameType = (names) => {
        if (mapInstance) {
            const allLayers = mapInstance?.getLayers().getArray();
            if (Array.isArray(names)) {
                return allLayers.filter((layer) =>
                    names.some((name) => layer.getClassName().split(' ').includes(name))
                ) as VectorLayer<Vector>[];
            }
            return allLayers.filter((layer) => layer.getClassName().includes(names)) as VectorLayer<Vector>[];
        }
        //console.warn(`Warning: Cannot find layer: ${name}`);
        return [];
    };

    const isAllowedLayer = (sourceLayer?: Layer, allowedLayers?: LayerChainingDependency): boolean => {
        if (!sourceLayer) {
            return false;
        }
        if (allowedLayers) {
            if (!Array.isArray(allowedLayers)) {
                return sourceLayer.getClassName().includes(allowedLayers);
            }
            return allowedLayers.some((name) => sourceLayer.getClassName().split(' ').includes(name));
        }
        return true;
    };

    const triggerLoadData = () => {
        loadingDataTimer && clearTimeout(loadingDataTimer);
        loadingDataTimer = setTimeout(() => {
            setMapAction({ action: type.MapActionsEnum.DRAW_HEATMAP });
            if (isBesMap) {
                setMapAction({ action: type.MapActionsEnum.GET_BES_SEVERE_DRIVING_DATA });
            }
        }, 3000);
    };

    const closePopup = () => {
        if (closePopupEl) {
            setShowPopup(false);
            overlay.current?.setPosition(undefined);
            closePopupEl.blur();
        }
    };

    const defineEvents = () => {
        mapInstance?.on('click', (evt): void => {
            const features = findGeneralByClick(evt.pixel);

            if (features) {
                const feature = features.feature as Feature;
                const layoutType = features.layoutType;

                if (layoutType === CustomLayers.VEHICLE) {
                    const featureGeometry = feature?.getGeometry() as Point;
                    const coordinates = featureGeometry?.getCoordinates();
                    const featureId = feature.getId() as string;

                    storeMapActionData({ clickedVehicle: +featureId.replace('vehicle-', '') });
                    id = featureId.replace('vehicle-', '');
                    overlay.current?.setPosition(coordinates);

                    setShowPopup(true);
                    setLayoutType(layoutType);
                }

                if (layoutType === CustomLayers.SPEED_INCIDENTS) {
                    storeMapActionData({ clickedSpeedIncident: undefined });

                    const featureGeometry = feature?.getGeometry() as Point;
                    const coordinates = featureGeometry?.getCoordinates();
                    const featureId = feature.getId() as string;

                    storeMapActionData({ clickedSpeedIncident: +featureId.replace('speed-', '') });
                    id = featureId.replace('speed-', '');
                    overlay.current?.setPosition(coordinates);

                    setShowPopup(true);
                    setLayoutType(layoutType);
                }
            }
        });

        closePopupEl &&
            (closePopupEl.onclick = () => {
                closePopup();
            });

        mapInstance?.on('moveend', (): void => {
            storeMapActionData({ zoomLevel: Math.ceil(mapInstance.getView().getZoom() || 13) });
            triggerLoadData();
            /*if (props.mapMode === MapModes.SPEED_HEATMAP) {
                setMapAction({
                    action: MapActionsEnum.DRAW_SPEED_HEATMAP_OFFLINE
                });
            }*/
        });

        mapInstance?.on('pointermove', (e): void => {
            if (e.dragging) {
                if (infoPopup) {
                    infoPopup.style.visibility = 'hidden';
                }
                return;
            }
            showSlopeInfo(mapInstance, e, infoPopup, storeMapActionData);
        });
    };

    const toggleMapInteractions = () => {
        mapInstance?.getInteractions().forEach((x) => x.setActive(!x.getActive()));
    };

    const getMapExtent = (): [number, number, number, number] => {
        if (mapInstance) {
            const extent: Extent = mapInstance.getView().calculateExtent();
            const transformedExtent: Extent = OlProj.transformExtent(
                extent,
                defaultSettings.epsg[1],
                defaultSettings.epsg[0]
            );
            return transformedExtent as [number, number, number, number];
        }
        return [0, 0, 0, 0];
    };

    const updateLoadingMapStatus = (status: boolean): void => {
        setLoadingMapData(status);
    };

    const findGeneralByClick = (pixel: Pixel): ResponseGeneralByClick | undefined => {
        const layers = [CustomLayers.VEHICLE, CustomLayers.SPEED_INCIDENTS];
        let feature: FeatureLike[] | FeatureLike | null;

        for (let index = 0; index < layers.length; index++) {
            feature = findFetureByClick(pixel, true, layers[index]);

            if (feature) {
                return {
                    feature: feature,
                    layoutType: layers[index]
                };
            }
        }
    };

    const findFetureByClick = (
        pixel: Pixel,
        findFirst?: boolean,
        inLayer?: LayerChainingDependency
    ): FeatureLike[] | FeatureLike | null => {
        let clickedFeatures: FeatureLike[] = [];
        let clickedFeature: FeatureLike | null = null;
        mapInstance?.forEachFeatureAtPixel(pixel, (feature, layer) => {
            const ifMetCondition: boolean = isAllowedLayer(layer, inLayer) && (findFirst ? !clickedFeature : true);
            if (ifMetCondition) {
                if (findFirst) {
                    clickedFeature = feature;
                } else {
                    clickedFeatures.push(feature);
                }
            }
        });
        return findFirst ? clickedFeature : clickedFeatures;
    };

    const adjustZoomToLimits = (): void => {
        const currentZoom = mapInstance?.getView().getZoom() || 30;
        const newZoom = Math.min(Math.max(defaultSettings.minZoom, currentZoom), defaultSettings.maxZoom);
        mapInstance?.getView().setZoom(newZoom);
    };

    const focusToLayer: focusToLayerType = (layer) => {
        const foundLayer: VectorLayer<Vector>[] = findLayerByName(layer);
        if (foundLayer.length) {
            const source = foundLayer[0].getSource();
            const extent = source?.getExtent() ?? [];
            const extentIsFinite = extent?.reduce((a, b) => {
                return a && isFinite(b);
            }, true);
            extentIsFinite && mapInstance?.getView().fit(extent, { padding: EXTENT_PADDING });
            adjustZoomToLimits();
        }
    };

    /*const removeFeatureFromLayer = (featureId: string, layer: type.Layers): void => {
        const featureLayer = findLayerByName(layer);
        const feature = findFeatureInLayer(featureId, layer);
        if (feature) {
            featureLayer[0].removeFeatures(feature);
        } else {
            console.warn(`Warning: Cannot find feature or layer: feature ${featureId} / layer ${layer}`);
        }
    };*/

    const findFeatureInLayer: findFeatureInLayerType = (featureId, layer) => {
        const featureLayer = layer instanceof VectorLayer ? [layer] : findLayerByName(layer);
        const feature = featureLayer.length ? featureLayer[0].getSource()?.getFeatureById(featureId) : null;
        if (feature) {
            return feature;
        }
        //console.warn(`Warning: Cannot find feature: ${featureId}`);
        return null;
    };

    const clearLayer = (layer: LayerChainingDependency | VectorLayer<Vector<Geometry>>): void => {
        const featureLayer = layer instanceof VectorLayer ? [layer] : findLayerByName(layer);
        featureLayer.length && featureLayer[0].getSource()?.clear();
    };

    const moveFeatureTo: moveFeatureToType = (featureId, layer, newGeometry) => {
        const feature = findFeatureInLayer(featureId, layer);
        if (feature) {
            feature.setGeometry(newGeometry);
            if (featureId.replace('vehicle-', '') === id && overlay.current?.getPosition()) {
                const selectedFeature = feature.getGeometry() as Point;
                overlay.current.setPosition(selectedFeature.getCoordinates());
            }
        } else {
            //console.warn(`Warning: No features in layer: ${layer}`);
        }
    };

    const resizeMap = (): void => {
        mapInstance && mapInstance.updateSize();
    };

    const createAzureLayer = (): TileLayer<XYZ> => {
        const tilesetId = 'microsoft.imagery';
        const language: string = (userInfo.user?.userSetting.language || 'en_GB').replace('_', '-'); // pending to check
        const view = 'Auto';
        return new TileLayer({
            source: new XYZ({
                // eslint-disable-next-line max-len
                url: `https://atlas.microsoft.com/map/tile?api-version=2.0&tilesetId=${tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language=${language}&view=${view}`,
                tileLoadFunction: function (imageTile: Tile & unknown, src) {
                    axios(src, {
                        method: 'GET',
                        responseType: 'blob',
                        headers: {
                            'x-ms-client-id': 'e7af9e05-ce06-48da-bdb9-a237455b99b3',
                            Authorization: `Bearer ${azureToken.current}`,
                            mode: 'cors'
                        }
                    }).then((resp) => {
                        (imageTile as Tile & { getImage: () => { src } }).getImage().src = URL.createObjectURL(
                            new Blob([resp.data], { type: 'text/json;charset=utf-8' })
                        );
                    });
                }
            })
        });
    };

    const getAzureExpiration = (jwtToken: string): number => {
        const json: string = atob(jwtToken.split('.')[1]);
        const decode = JSON.parse(json);
        const renewSkew = 300000;
        return 1000 * decode.exp - Date.now() - renewSkew;
    };

    const loadAzureToken = async (): Promise<string> => {
        const AzureToken = await AzureMap.getAzureToken();
        const token = AzureToken.token;
        localStorage.setItem('aMapToken', token);
        return token;
    };

    const initAzureAD = async (): Promise<void> => {
        let token: string = await loadAzureToken();
        azureToken.current = token;

        const renewToken = async () => {
            try {
                token = await loadAzureToken();
                azureToken.current = token;
                renewAzureTokenTimeout.current = setTimeout(renewToken, getAzureExpiration(token));
            } catch (error) {
                renewAzureTokenTimeout.current && clearTimeout(renewAzureTokenTimeout.current);
                throw error;
            }
        };
        renewAzureTokenTimeout.current = setTimeout(renewToken, getAzureExpiration(token));
    };

    const mapActions = useMemo(
        () => ({
            findLayerByName,
            focusToLayer,
            defaultSettings,
            moveFeatureTo,
            findFeatureInLayer,
            getMapExtent,
            updateLoadingMapStatus,
            toggleMapInteractions,
            mapInstance
        }),

        [mapInstance]
    );

    const storeMapActionData = (params: MapActionDataType): void => {
        setMapActionData((currentMapActionData) => {
            return { ...currentMapActionData, ...params };
        });
    };

    const mapActionHandler = (mapAction: MapActionType): void => {
        switch (mapAction.action) {
            case type.MapActionsEnum.LOAD_MULTIPLE_JOURNEY_LINE:
                clearAllIntervals();
                drawJourneys(mapAction.params, mapActions);
                break;
            case type.MapActionsEnum.LOAD_JOURNEY_LINE_ON_STATISTICS:
                clearLayer(CustomLayers.VEHICLE);
                closePopup();
                drawJourney(mapAction.params, mapActions, storeMapActionData);
                break;
            case type.MapActionsEnum.LOAD_JOURNEY_LINE:
                clearLayer(CustomLayers.JOURNEY);
                drawOnlyTheJourney(mapAction.params, mapActions, storeMapActionData, true);
                break;
            case type.MapActionsEnum.DRAW_VEHICLE_AT_TIME:
                drawVehicleAtTime(mapAction.params, mapActionData, mapActions, timeline.granularity);
                break;
            case type.MapActionsEnum.DRAW_VEHICLES_POSITION:
                clearLayer(CustomLayers.JOURNEY);
                drawVehiclesPosition(mapAction.params, mapActions, storeMapActionData);
                break;
            case type.MapActionsEnum.CLEAN_DRAW_AREA:
                clearLayer(CustomLayers.DRAW_AREA);
                break;
            case type.MapActionsEnum.CLEAN_AREAS:
                clearLayer(CustomLayers.AREA);
                break;
            case type.MapActionsEnum.DRAW_AREAS:
                drawAreas(mapActions);
                break;
            case type.MapActionsEnum.DRAW_AREA:
                drawArea(mapAction.params, mapActions);
                break;
            case type.MapActionsEnum.RESIZE_MAP:
                resizeMap();
                /*
                // deprecated - remove when confirmed that works all good
                // consider to remove lastAction props
                clearAllIntervals();
                mapActionHandler(mapAction.params.lastAction);*/
                break;
            case type.MapActionsEnum.DRAW_HEATMAP: {
                drawHeatmap({ map: mapActions, mapData: mapActionData });
                break;
            }
            case type.MapActionsEnum.GET_BES_SEVERE_DRIVING_DATA: {
                clearLayer(CustomLayers.SEVERE_DRIVING);
                getBesSevereDriving(mapActions, mapActionData, storeMapActionData);
                break;
            }
            case type.MapActionsEnum.DRAW_EXCESSIVE_ACC: {
                drawExcessiveAcc(
                    mapActions,
                    mapActionData,
                    storeMapActionData,
                    userInfo.user?.userSetting.timezone.timezoneName
                ); // pending to test
                break;
            }
            case type.MapActionsEnum.DRAW_SPEED_INCIDENTS:
                closePopup();
                clearLayer(CustomLayers.SPEED_INCIDENTS);
                drawSpeedIncidents(
                    mapActions,
                    mapActionData,
                    storeMapActionData,
                    userInfo.user?.userSetting.timezone.timezoneName
                ); // pending to test
                break;
            case type.MapActionsEnum.SHOW_MAP_MESSAGE:
                setWarningMsg(mapAction.params.message);
                break;
            case type.MapActionsEnum.HIDE_MAP_MESSAGE:
                setWarningMsg('');
                break;
            case type.MapActionsEnum.DISASSEMBLE_MAP:
                mapInstance?.setTarget();
                break;
            case type.MapActionsEnum.DRAW_JOURNEY_FAULTY_REPORT:
                clearLayer(CustomLayers.JOURNEY_FAULTY_REPORT);
                drawJourneysCircle(mapAction.params, mapActions, setWarningMsg, translate('t.there_no_data'));
                break;
            case type.MapActionsEnum.DRAW_SLOPE_JOURNEY:
                clearLayer(CustomLayers.SLOPE_INCLINATION_JOURNEY);
                drawSlopeInclinationJourney(
                    mapAction.params,
                    mapActions,
                    mapActionData,
                    setWarningMsg,
                    translate('t.there_no_data')
                );
                break;
            case type.MapActionsEnum.SLOPE_INCLINATION_JOURNEY_UPDATE: {
                redrawSlopeInclinalJourney(mapActions, mapActionData);
                break;
            }
            default:
                break;
        }
    };

    const enableDrawArea = (): void => {
        const drawAreaLayer = findLayerByName(CustomLayers.AREA);
        if (drawAreaLayer.length > 0 && drawAreaLayer[0].getSource()) {
            if (props.enableDrawing) {
                const drawArea = new Draw({
                    source: drawAreaLayer[0].getSource() as Vector<Geometry>,
                    type: 'Polygon'
                });
                drawInteraction = drawArea;
                drawArea.once('drawend', OnDrawAreaEnd);
                mapInstance && mapInstance.addInteraction(drawArea);
            } else if (props.enableEditingArea) {
                const modify = new Modify({
                    features: new Collection(drawAreaLayer[0].getSource()?.getFeatures()),
                    deleteCondition: (event) => {
                        return shiftKeyOnly(event) && singleClick(event);
                    }
                });
                drawInteraction = modify;
                drawInteraction.once('modifyend', OnEditAreaEnd);

                mapInstance && mapInstance.addInteraction(modify);
            }

            mapInstance && (props.enableDrawing || props.enableEditingArea) && mapInstance.addControl(closeDrawControl);
        }
    };

    const disableDrawArea = (): void => {
        clearLayer(CustomLayers.DRAW_AREA);

        if (mapInstance && (props.enableDrawing || props.enableEditingArea)) {
            mapInstance.removeInteraction(drawInteraction);
            mapInstance.removeControl(closeDrawControl);
        }
    };

    const removeDrawControls = (): void => {
        if (mapInstance && (props.enableDrawing || props.enableEditingArea)) {
            mapInstance.removeInteraction(drawInteraction);
            mapInstance.removeControl(closeDrawControl);
            mapInstance.removeControl(drawControl);
            drawControl = undefined;
            drawInteraction = undefined;
        }
    };

    const resetMapElementSource = (): void => {
        // when any other children is added into map view component, it needs to be incremented here
        mapElement.current?.children[2] && mapElement.current?.removeChild(mapElement.current?.children[2]);
    };

    const initializeMap = async (): Promise<void> => {
        resetMapElementSource();
        const areaLayerConfig = {
            source: new Vector(),
            className: CustomLayers.AREA,
            title: translate('t.areas')
        };

        const vehicleLayerConfig = {
            source: new Vector(),
            className: CustomLayers.VEHICLE,
            title: translate('t.vehicles')
        };

        const basicLayers: VectorLayer<Vector>[] = [
            new VectorLayer(areaLayerConfig),
            new VectorLayer({
                source: new Vector({ wrapX: false }),
                className: CustomLayers.DRAW_AREA
            }),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.JOURNEY
            }),
            new VectorLayer(vehicleLayerConfig),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.HEATMAP
            }),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.SPEED_INCIDENTS
            }),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.EXCESSIVE_ACC
            }),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.JOURNEY_FAULTY_REPORT
            }),
            new VectorLayer({
                source: new Vector(),
                className: CustomLayers.SLOPE_INCLINATION_JOURNEY
            })
        ];

        const map: Map = new Map({
            controls: defaultControls({
                attributionOptions: /** @type {olx.control.AttributionOptions} */ {
                    collapsible: false
                }
            }).extend([defaultSettings.scaleLine, new LayerSwitcher({ reverse: false })]),
            layers: [...providerSettings[mapType].layers, ...basicLayers].concat([]),
            view: new View({
                zoom: defaultSettings.zoom,
                maxZoom: defaultSettings.maxZoom,
                constrainResolution: props.mapMode?.includes('HEATMAP')
            }),
            target: mapElement.current
        });

        if (mapType === type.MapTypes.bing) {
            await initAzureAD();
            const azureLayer = createAzureLayer();
            map.getLayers().insertAt(0, azureLayer);
        }

        setMapInstance(map);
        triggerOnMount();
    };

    useEffect(() => {
        // initalize map
        if (!mapElement.current || !customerSettingsAtom.map_area?.length) {
            return;
        }
        initializeMap();
        () => {
            mapElement.current = undefined;
        };
    }, [userInfo.user?.userSetting.mapType, customerSettingsAtom.map_area]);

    useEffect(() => {
        resetMapAction();
        resetMapActionData();
        setWarningMsg('');
    }, []);

    useEffect(() => {
        if (mapInstance) {
            props?.mapMode === type.MapModes.CUSTOM_DATA && toggleLayerManualy(CustomLayers.AREA, false);

            if (!props.enableEditingArea) {
                drawAreas(mapActions);
            }

            if (customerSettingsAtom.map_area?.length) {
                mapInstance
                    ?.getView()
                    .fit(
                        OlProj.transformExtent(
                            customerSettingsAtom.map_area,
                            defaultSettings.epsg[0],
                            defaultSettings.epsg[1]
                        )
                    );
                adjustZoomToLimits();
            } else {
                mapInstance
                    ?.getView()
                    .setCenter(
                        OlProj.transform(
                            defaultSettings.areaCenterCoordinates,
                            defaultSettings.epsg[0],
                            defaultSettings.epsg[1]
                        )
                    );
            }
            defineEvents();
            overlay.current = createOverlay();
            mapInstance.addOverlay(overlay.current);
        }
    }, [mapInstance, customerSettingsAtom.map_area]);

    useEffect(() => {
        if (mapInstance) {
            if ((props.enableDrawing || props.enableEditingArea) && areaEditorState.selectedArea) {
                removeDrawControls();
            }
            if (!props.enableEditingArea && props.enableDrawing) {
                drawAreas(mapActions);
            }
        }
    }, [areaEditorState.mode]);

    useEffect(() => {
        if (mapInstance) {
            if ((props.enableDrawing || props.enableEditingArea) && areaEditorState.selectedArea) {
                removeDrawControls();
            }
        }
    }, [areaEditorState.selectedArea?.id, areaEditorState.mode]);

    useEffect(() => {
        if (mapInstance) {
            /*
                Since we OL is being executed several times,
                we need to ensure to add new events with the updated state.
                Otherwise the callback will reset the state.
            */
            if (props.enableDrawing || props.enableEditingArea) {
                if (drawControl) {
                    if (drawInteraction) {
                        if (props.enableEditingArea) {
                            const lastEvent =
                                drawInteraction.listeners_?.length > 0 ?? drawInteraction.listeners_.modifyend[0];
                            lastEvent && drawInteraction.un('modifyend', lastEvent);

                            if (areaEditorState.hasNewChanges) {
                                drawInteraction.once('modifyend', OnEditAreaEnd);
                            }
                        } else if (props.enableDrawing) {
                            const lastEvent =
                                drawInteraction.listeners_?.length > 0 ?? drawInteraction.listeners_.modifyend[0];
                            lastEvent && drawInteraction.un('drawend', lastEvent);

                            if (areaEditorState.hasNewChanges) {
                                drawInteraction.once('drawend', OnDrawAreaEnd);
                            }
                        }
                    } else if (areaEditorState.hasNewChanges) {
                        //If new area has new data, update drawControl onClick
                        mapInstance.removeControl(drawControl);
                        drawControl = new DrawControl({
                            onClick: enableDrawArea
                        });

                        mapInstance.addControl(drawControl);
                    }
                } else {
                    drawControl = new DrawControl({
                        onClick: enableDrawArea
                    });

                    mapInstance.addControl(drawControl);
                }
            }
        }
    }, [mapInstance, areaEditorState, props.enableDrawing, props.enableEditingArea, drawControl]);

    useEffect(() => {
        mapInstance && mapActionHandler(mapAction);
    }, [mapAction, mapInstance]);

    if (!closeDrawControl && mapInstance && (props.enableDrawing || props.enableEditingArea)) {
        closeDrawControl = new CloseDrawControl({
            onClick: disableDrawArea
        });
    }

    return (
        <MapView
            mapElement={mapElement}
            showPopup={showPopup}
            warningMsg={warningMsg}
            layoutType={layoutType}
            disableVehiclePopup={props.disableVehiclePopup}
            data-testid='Map-testid'
        />
    );
};

export default Wrapper(MapManager);
