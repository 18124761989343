import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { FormControlLabelEl } from './UiTable3HideColumnsCheckbox.style';
import { UiTable3HideColumnsCheckboxProps } from './UiTable3HideColumnsCheckbox.type';
import { Checkbox } from '@mui/material';
import { ColumnVisibility, TableFn } from '../UiTable3/TableAtom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from '../../CustomHooks/UserLocalStorageSettings';

const UiTable3HideColumnsCheckboxContent: React.FC<UiTable3HideColumnsCheckboxProps> = (props): JSX.Element => {
    const tableFn = useRecoilValue(TableFn);
    const columnVisibility = useRecoilValue(ColumnVisibility);
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    // TODO: find better solution to store hidden columns via table hidden colums effect
    const setHiddenColumns = (columnId, isVisible) => {
        let hiddenColumns: Record<string, unknown>[] = [];
        if (isVisible) {
            hiddenColumns = [...(getUserSettingTable(localStorageTableKey)?.hiddenColumns || []), columnId];
        } else {
            hiddenColumns = getUserSettingTable(localStorageTableKey).hiddenColumns.filter(
                (column) => column !== columnId
            );
        }

        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            hiddenColumns
        });
    };

    const CheckboxEl = useMemo(() => {
        return (
            <FormControlLabelEl
                data-testid='UiTable3HideColumnsCheckboxContent'
                control={
                    <Checkbox
                        disabled={false}
                        color='primary'
                        onChange={() => {
                            setHiddenColumns(props.columnId, columnVisibility[props.columnId].isVisible);
                            tableFn.toggleHideColumn &&
                                tableFn.toggleHideColumn instanceof Function &&
                                tableFn.toggleHideColumn(props.columnId, columnVisibility[props.columnId].isVisible);
                        }}
                    />
                }
                label={<>{props.label}</>}
                checked={columnVisibility[props.columnId].isVisible}
            />
        );
    }, [columnVisibility[props.columnId].isVisible]);

    return CheckboxEl;
};

export default UiTable3HideColumnsCheckboxContent;
