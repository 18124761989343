import React, { useEffect } from 'react';
import { SetAlertsContent } from './SetAlerts.view';
import { SetAlertsProps } from './SetAlerts.type';
import { useResetRecoilState } from 'recoil';
import { SettingsStep } from 'components/Alert/AlertSettings/atom';
import { Wrapper } from 'helpers/wrapper';

const SetAlerts: React.FC<SetAlertsProps> = (): JSX.Element => {
    const resetSettingStep = useResetRecoilState(SettingsStep);
    useEffect(() => {
        return () => {
            resetSettingStep();
        };
    }, []);
    return <SetAlertsContent data-testid={'SetAlerts-testid'} />;
};

export default Wrapper(SetAlerts);
