import React, { useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { UiTable3RowCheckboxViewContent } from './UiTable3RowCheckbox.style';
import { UiTable3RowCheckboxProps } from './UiTable3RowCheckbox.type';
import { SelectRow, SelectedRowDispatcher, SelectedRowEnum } from '../UiTable3/TableAtom';
import { Checkbox } from '@mui/material';

const UiTable3RowCheckboxContent: React.FC<UiTable3RowCheckboxProps> = (props): JSX.Element => {
    const [selectRow, setSelectRow] = useRecoilState(SelectRow);
    const selectRowDispatch = SelectedRowDispatcher(selectRow, setSelectRow);

    const checked = useMemo(() => {
        const isSelected = selectRow.selectedRows?.filter((row) => row.id === props.rowData.id);
        return isSelected?.length ? !selectRow.selectedAll : selectRow.selectedAll;
    }, [selectRow]);

    return (
        <UiTable3RowCheckboxViewContent
            {...props.getCellProps()}
            data-testid={`checkboxCellColumn${props.columnIndex}Row${props.rowIndex}`}
        >
            <Checkbox
                checked={checked}
                onChange={(e) => {
                    selectRowDispatch({
                        type: SelectedRowEnum.SET_SELECT,
                        payload: {
                            rowData: props.rowData,
                            added: selectRow.selectedAll ? !e.target.checked : e.target.checked
                        }
                    });
                }}
            />
        </UiTable3RowCheckboxViewContent>
    );
};

export default UiTable3RowCheckboxContent;
