import React from 'react';
import * as view from './VehicleMapPopupStatistics.view';
import * as type from './VehicleMapPopupStatistics.type';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import VehicleApi from '../../api/Vehicle';
import { MapActionData } from '../../states/global/Map';
import { TrackingStatistics } from '../../models/Vehicle.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const VehicleMapPopupStatistics: React.FC<type.VehicleMapPopupStatisticsProps> = (props): JSX.Element => {
    const Vehicle = new VehicleApi();
    const mapActionData = useRecoilValue(MapActionData);
    const { fromTimezoneToUTC } = useConverter();
    const { isLoading: statisticsIsLoading, data: statisticsData } = useQuery<unknown, Error, TrackingStatistics>(
        ['VehicleStatistics', mapActionData.clickedVehicle],
        () =>
            mapActionData?.clickedVehicle &&
            Vehicle.getTrackingStatistics(
                mapActionData.clickedVehicle,
                fromTimezoneToUTC(DateTime.local().minus({ days: 1 })),
                fromTimezoneToUTC(DateTime.local())
            ),
        {
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            retry: false,
            enabled: props.isActive && !!mapActionData.clickedVehicle
        }
    );

    return (
        <view.VehicleMapPopupStatisticsContent
            statisticsData={statisticsData}
            isLoading={statisticsIsLoading}
            data-testid={'VehicleMapPopupStatistics-testid'}
        />
    );
};

export default Wrapper(VehicleMapPopupStatistics);
