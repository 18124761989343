import React from 'react';
import { useRecoilValue } from 'recoil';
import { MapActionData } from '../../states/global/Map';
import {
    CustomTab,
    CustomTabContent,
    CustomTabs,
    TabContentWrapper,
    VehicleMapPopupContentView
} from './VehicleMapPopup.style';
import { VehicleMapPopupViewProps } from './VehicleMapPopup.type';
import { PopupHeader, PoupContent, TopLabel } from '../Maps/MapPopUp/MapPopUp.style';
import { LastPosition } from '../../models/Vehicle.type';
import { useTranslation } from 'react-i18next';
import VehicleMapPopupDetails from '../VehicleMapPopupDetails';
import VehicleMapPopupTyres from '../VehicleMapPopupTyres';
import VehicleMapPopupAlerts from '../VehicleMapPopupAlerts';
import VehicleMapPopupStastics from '../VehicleMapPopupStatistics';
import UiLink from '../Ui/Components/UiLink/UiLink';
import { applyStyleByMode } from 'helpers';
import { Theme } from '../../states/global/Theme';
import { BACKGROUND_BLUE, DARK_STEEL_GREY } from '../Ui/colors';

export const VehicleMapPopupContent: React.FC<VehicleMapPopupViewProps> = (props): JSX.Element => {
    const mapActionData = useRecoilValue(MapActionData);
    const ThemeMode = useRecoilValue(Theme);
    const vehicleDetails =
        mapActionData?.vehiclesPosition && mapActionData?.clickedVehicle
            ? (mapActionData?.vehiclesPosition[mapActionData?.clickedVehicle] as LastPosition)
            : undefined;
    const { t: translate } = useTranslation();
    return (
        <VehicleMapPopupContentView data-testid='VehicleMapPopupContent'>
            <PopupHeader>
                <TopLabel>
                    <UiLink
                        data-testid={'VehicleMapPopupContent-UiLink-vehicleDetailsName'}
                        $padding='0'
                        content={vehicleDetails?.name || ' '}
                        url={`/vehicle-status?vehicle=${vehicleDetails?.vehicleId}`}
                        color={applyStyleByMode({
                            styleOld: '#0071c7',
                            theme: ThemeMode?.mode,
                            light: DARK_STEEL_GREY,
                            dark: BACKGROUND_BLUE
                        })}
                    />
                </TopLabel>
            </PopupHeader>

            <PoupContent>
                <CustomTabs
                    data-testid={'VehicleMapPopupContent-CustomTabs'}
                    value={props.currentTab}
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={props.changeTab}
                >
                    <CustomTab label={translate('t.tyres')} />
                    <CustomTab label={translate('t.details')} />
                    <CustomTab label={translate('t.alerts')} />
                    <CustomTab label={translate('t.statistics')} />
                </CustomTabs>
                <CustomTabContent
                    value={props.currentTab}
                    index={0}
                    data-testid={'VehicleMapPopupContent-CustomTabContent-0'}
                    testId='VehicleMapPopupContent-CustomTabContent-0'
                >
                    <TabContentWrapper>
                        <VehicleMapPopupTyres
                            isActive={props.currentTab === 0}
                            data-testid={'VehicleMapPopupContent-VehicleMapPopupTyres'}
                        />
                    </TabContentWrapper>
                </CustomTabContent>
                <CustomTabContent
                    value={props.currentTab}
                    index={1}
                    data-testid={'VehicleMapPopupContent-CustomTabContent-1'}
                    testId='VehicleMapPopupContent-CustomTabContent-1'
                >
                    <TabContentWrapper>
                        <VehicleMapPopupDetails data-testid={'VehicleMapPopupContent-VehicleMapPopupDetails'} />
                    </TabContentWrapper>
                </CustomTabContent>
                <CustomTabContent
                    value={props.currentTab}
                    index={2}
                    data-testid={'VehicleMapPopupContent-CustomTabContent-2'}
                    testId='VehicleMapPopupContent-CustomTabContent-2'
                >
                    <TabContentWrapper>
                        <VehicleMapPopupAlerts
                            isActive={props.currentTab === 2}
                            data-testid={'VehicleMapPopupContent-VehicleMapPopupAlerts'}
                        />
                    </TabContentWrapper>
                </CustomTabContent>
                <CustomTabContent
                    value={props.currentTab}
                    index={3}
                    data-testid={'VehicleMapPopupContent-CustomTabContent-3'}
                    testId='VehicleMapPopupContent-CustomTabContent-3'
                >
                    <TabContentWrapper>
                        <VehicleMapPopupStastics
                            isActive={props.currentTab === 3}
                            data-testid={'VehicleMapPopupContent-VehicleMapPopupStastics'}
                        />
                    </TabContentWrapper>
                </CustomTabContent>
            </PoupContent>
        </VehicleMapPopupContentView>
    );
};
