import React, { useEffect } from 'react';
import { CustomDateTimePicker, DatePickerContentEl } from './DateTimePicker.style';
import { DateTimePickerProps } from './DateTimePicker.type';
import { useRecoilValue } from 'recoil';
import { DateTimeMask, GetDateMask, Is12HrsFormat } from 'helpers/Converters';
import useConverter from '../../../../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import { SIZE_INPUT } from '../../../../variables';

import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from '../../../../../Mixs/HelperText/HelperText';
import { useTranslation } from 'react-i18next';
import { Wrapper } from 'helpers/wrapper';

const DateTimePickerContent: React.FC<DateTimePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const dateTimeMask = useRecoilValue(DateTimeMask);
    const getDateMask = useRecoilValue(GetDateMask);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = React.useState<DateTime | null>(
        (typeof props.value === 'string' ? DateTime.fromISO(props.value) : props.value) || DateTime.local()
    );

    useEffect(() => {
        props.value && setDateValue(DateTime.fromISO(props.value));
    }, [props.value]);

    useEffect(() => {
        return () => {
            setDateValue(null);
        };
    }, []);

    return (
        <DatePickerContentEl data-testid='DateTimePickerForUiDateRangePicker2'>
            <CustomDateTimePicker
                mask={props.onlyDate ? getDateMask : dateTimeMask}
                inputFormat={dateTimeFormat(props.onlyDate ? 'date' : props.inputFormat || 'dateTime', false)}
                ampm={is12HrsFormat}
                label={props.label || translate('t.date')}
                value={dateValue}
                open={false}
                onOpen={props.onOpen}
                onChange={(newDate: DateTime): void => {
                    props.onDateChange(newDate);
                }}
                renderInput={(params) => (
                    <TextField
                        variant='outlined'
                        size={SIZE_INPUT}
                        {...params}
                        margin='normal'
                        id={`DateTimePickerForUiDateRangePicker2${Math.ceil(Math.random() * 1000000000000000000)}`}
                        style={{ margin: '0 0 25px 0 !important' }}
                        data-testid={`DateTimePickerForUiDateRangePicker2${
                            props.testId ||
                            firstCapital(snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_')))
                        }`}
                        onFocus={() => props.onFocus && props.onFocus()}
                        onBlur={() => props.onBlur && props.onBlur()}
                        helperText={
                            <HelperText error={params.error} text={translate('t.the_date_format_is_invalid')} />
                        }
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: dateTimeFormat(
                                props.onlyDate ? 'date' : props.inputFormat || 'dateTime',
                                false
                            ),
                            style: { width: '100%' }
                        }}
                    />
                )}
                $noMargin={props.noMargin}
            />
        </DatePickerContentEl>
    );
};

export default Wrapper(DateTimePickerContent);
