import { Button, TableBody, TableCell, TableRow, styled as styledMui, useTheme } from '@mui/material';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from 'states/global/Theme';
import styled from 'styled-components';

export const TableBodyEl = styledMui(TableBody)<{
    rowCount: number;
    customHeight?: number;
}>(
    (props) =>
        `
    display: block;      
    height: ${props.customHeight || props.rowCount * 50 || 500}px;
    max-height: ${props.customHeight || props.rowCount * 50 || 500}px;
    td {
        ${applyStyleByMode({
            styleOld: `
            font-size: 1em !important;
            line-height: 21px !important;
            `
        })}
    }
    `
);

export const TableCellEl = styledMui(TableCell)({
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
});

export const ActionButton = styledMui(Button)`
    min-width: 20px !important;
    max-width: 20px !important;
`;

export const ActionTableCell = styledMui(TableCell)({
    textAlign: 'center'
});

export const EmptyTable = styledMui(TableCell)`
    height: 70px;
    border-bottom: #e5e5e5 solid thin;
    ${applyStyleByMode({
        styleOld: `
        background: #f7f7f7;
        `
    })}
    text-align: center !important;
`;

export const CustomTableRow = styled(TableRow)`
    ${() => {
        return `
            &:nth-of-type(even) {
                background-color: ${useTheme().palette.mode === ThemeMode.dark ? '#1d2630de' : 'rgba(0, 0, 0, 0.04)'};
            }
        `;
    }}
`;
