import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    AlertLabel,
    AlertsCheckboxConver,
    AlertsPressureSettingsViewContent,
    StyledSkeleton
} from './AlertsPressureSettings.style';
import { AlertsPressureSettingsViewProps } from './AlertsPressureSettings.type';
import { useTranslation } from 'react-i18next';
import { Grid, Skeleton } from '@mui/material';
import CustomAlertsCheckbox from '../CustomAlertsCheckbox';
import AlertSettingLevel from '../AlertSettingLevel';
import AlertSettingInput from '../AlertSettingInput';
import AlertSettinOptimal from '../AlertSettinOptimal';
import AlertSettingInputHotPressure from '../AlertSettingInputHotPressure';
import { IsLoadingAlertSettings } from '../AlertSettings/atom';
import { AlertInfo } from '../AlertsAffectedVehiclesList/AlertsAffectedVehiclesList.style';

export const AlertsPressureSettingsContent: React.FC<AlertsPressureSettingsViewProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const isLoadingAlertSettings = useRecoilValue(IsLoadingAlertSettings);

    return (
        <AlertsPressureSettingsViewContent data-testid='AlertsPressureSettingsContent'>
            <Grid container direction={'row'} sm={12} md={12} lg={12} spacing={0}>
                <Grid item xs={12} md lg>
                    <AlertLabel variant='subtitle1' gutterBottom>
                        {translate('t.low_cold_pressure')}
                    </AlertLabel>
                </Grid>
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs={12} md lg>
                    <AlertLabel variant='subtitle1' gutterBottom display={{ xs: 'none', md: 'block', lg: 'block' }}>
                        {translate('t.high_cold_pressure')}
                    </AlertLabel>
                </Grid>
                <Grid item xs md lg />
                <Grid item xs md lg />
            </Grid>
            <Grid container direction={'row'} sm={12} md={12} lg={12} spacing={0}>
                {isLoadingAlertSettings ? (
                    <Skeleton variant='rectangular' width={'100%'} height={118} />
                ) : (
                    <>
                        <AlertSettingLevel level={3} alertInput={<AlertSettingInput level={-3} />} />
                        <AlertSettingLevel level={2} alertInput={<AlertSettingInput level={-2} />} />
                        <AlertSettingLevel level={1} alertInput={<AlertSettingInput level={-1} />} />
                        <AlertSettingLevel level={0} alertInput={<AlertSettinOptimal />} />
                        <Grid item xs={12} md lg display={{ xs: 'block', md: 'none', lg: 'none' }}>
                            <AlertLabel variant='subtitle1' gutterBottom>
                                {translate('t.high_cold_pressure')}
                            </AlertLabel>
                        </Grid>
                        <AlertSettingLevel level={1} alertInput={<AlertSettingInput level={1} />} />
                        <AlertSettingLevel level={2} alertInput={<AlertSettingInput level={2} />} />
                        <AlertSettingLevel level={3} alertInput={<AlertSettingInput level={3} />} />
                    </>
                )}
            </Grid>
            <Grid container direction={'row'} sm={12} md={12} lg={12} spacing={0} marginTop={'10px'}>
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs md lg>
                    <AlertLabel variant='subtitle1' gutterBottom>
                        {translate('t.high_hot_pressure')}
                    </AlertLabel>
                </Grid>
                <Grid item xs md lg />
                <Grid item xs md lg />
            </Grid>
            <Grid container direction='row-reverse' sm={12} md={12} lg={12} spacing={0} alignItems='center'>
                {isLoadingAlertSettings ? (
                    <Skeleton variant='rectangular' width='40%' height={80} />
                ) : (
                    <>
                        <AlertSettingLevel level={13} alertInput={<AlertSettingInputHotPressure level={13} />} />
                        <AlertSettingLevel level={12} alertInput={<AlertSettingInputHotPressure level={12} />} />
                        <AlertSettingLevel level={11} alertInput={<AlertSettingInputHotPressure level={11} />} />
                    </>
                )}

                <AlertsCheckboxConver item xs={6} md lg>
                    {isLoadingAlertSettings ? (
                        <>
                            <StyledSkeleton variant='circular' width={50} height={50} />
                            <StyledSkeleton variant='rectangular' width={100} height={15} />
                        </>
                    ) : (
                        <CustomAlertsCheckbox />
                    )}
                </AlertsCheckboxConver>
                <Grid item xs md lg />
                <Grid item xs md lg />
                <Grid item xs md lg />
            </Grid>
            <Grid item marginTop='20px'>
                <AlertInfo severity='info'>
                    {translate('p.custom_alerts_level_calculated_based_high_cold_pressure')}
                </AlertInfo>
            </Grid>
        </AlertsPressureSettingsViewContent>
    );
};
