import React, { useEffect, useState } from 'react';
import * as view from './VehicleMapPopup.view';
import { VehicleMapPopupProps } from './VehicleMapPopup.type';
import { MapActionData } from '../../states/global/Map';
import { useRecoilValue } from 'recoil';
import { Wrapper } from 'helpers/wrapper';

const VehicleMapPopup: React.FC<VehicleMapPopupProps> = (props): JSX.Element => {
    const [currentTab, setCurrentTab] = useState<number>(0);
    const mapActionData = useRecoilValue(MapActionData);

    const changeTab = (event: React.ChangeEvent<{}>, newTab: number): void => {
        setCurrentTab(newTab);
    };

    useEffect(() => {
        setCurrentTab(0);
    }, [mapActionData.clickedVehicle]);

    useEffect(() => {
        return () => {
            setCurrentTab(0);
        };
    }, []);

    return (
        <view.VehicleMapPopupContent
            {...props}
            currentTab={currentTab}
            changeTab={changeTab}
            data-testid={'VehicleMapPopup-testid'}
        />
    );
};

export default Wrapper(VehicleMapPopup);
