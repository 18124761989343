import React from 'react';
import { useRecoilValue } from 'recoil';
import { applyStyleByMode } from 'helpers';
import { Theme, ThemeMode } from '../../../states/global/Theme';
import { SECONDARY_DARK_MODE, WHITE } from '../../Ui/colors';
import * as type from './HubConnectionIndicator.type';
import * as view from './HubConnectionIndicator.view';
import { DateTime, Duration } from 'luxon';
import { Wrapper } from 'helpers/wrapper';

const getIconColor = (lastTimeConnected: DateTime | string | null, color: string, theme: string): string => {
    if (lastTimeConnected) {
        const lastTimeParsed = DateTime.fromISO(lastTimeConnected);
        const now = DateTime.utc();
        const diff = now.diff(lastTimeParsed);

        if (!isNaN(diff.valueOf())) {
            const duration = Duration.fromMillis(diff.valueOf());
            const lastTimeConnectedDiff = duration.shiftTo('days', 'minutes').toObject();

            if (lastTimeConnectedDiff && lastTimeConnectedDiff.days < 1) {
                if (lastTimeConnectedDiff && lastTimeConnectedDiff.minutes <= 15) {
                    return '#158015';
                }
                return theme === ThemeMode.dark ? '#ffd372' : '#de9d0d';
            }
        }
    }

    return color;
};

const HubConnectionIndicator: React.FC<type.HubConnectionIndicatorProps> = (props): JSX.Element => {
    const ThemeMode = useRecoilValue(Theme);

    return (
        <view.HubConnectionIndicatorContent
            data-testid={'HubConnectionIndicator-testid'}
            connectionType={props.connectionType}
            color={getIconColor(
                props.lastTimeConnected,
                applyStyleByMode({
                    styleOld: '#b5b5b5',
                    theme: ThemeMode?.mode,
                    light: SECONDARY_DARK_MODE,
                    dark: WHITE
                }),
                ThemeMode?.mode
            )}
        />
    );
};

export default Wrapper(HubConnectionIndicator);
