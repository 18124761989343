import React, { useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { UiTable3FooterViewContent } from './UiTable3Footer.style';
import { UiTable3FooterProps } from './UiTable3Footer.type';
import { useTranslation } from 'react-i18next';
import { TableRow, TablePagination } from '@mui/material';
import { TableAtom } from '../../../states/component/Table';
import { TableFn } from '../UiTable3/TableAtom';
import useUserLocalStorageSettings, {
    useUserLocalStorageSettingsTable
} from 'components/CustomHooks/UserLocalStorageSettings';

const UiTable3FooterContent: React.FC<UiTable3FooterProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const { setUserSettings } = useUserLocalStorageSettings([localStorageTableKey]);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();

    const tableFns = useRecoilValue(TableFn);

    const handleChangeRowsPerPage = (event): void => {
        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            pageSize: +event.target.value,
            pageIndex: 0
        });
        setTableState({ ...tableState, queryPageSize: +event.target.value, queryPageIndex: 0 });
    };

    return (
        <UiTable3FooterViewContent data-testid='UiTable3FooterContent'>
            <TableRow>
                <TablePagination
                    rowsPerPageOptions={undefined}
                    count={tableState.totalCount}
                    labelRowsPerPage={translate('t.entries_per_page')}
                    rowsPerPage={tableState.queryPageSize}
                    page={tableState.queryPageIndex}
                    onPageChange={(event, newPage) => {
                        tableFns.gotoPage instanceof Function && tableFns.gotoPage(newPage);
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableRow>
        </UiTable3FooterViewContent>
    );
};

export default UiTable3FooterContent;
