import React from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { UiTable3ApplyFilterBtnProps } from './UiTable3ApplyFilterBtn.type';
import { useTranslation } from 'react-i18next';
import { TableFilters, TableFn } from '../UiTable3/TableAtom';
import { TableAtom } from '../../../states/component/Table';
import { prepareTableFilterValues } from 'helpers';
import { SearchButton } from './UiTable3ApplyFilterBtn.style';

const UiTable3ApplyFilterBtnContent: React.FC<UiTable3ApplyFilterBtnProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const tableFilter = useRecoilValue(TableFilters);
    const setTableState = useSetRecoilState(TableAtom(`${props.queryKey}-Table`));
    const tableFns = useRecoilValue(TableFn);

    return (
        <SearchButton
            variant='contained'
            data-testid='FilterSearchButton'
            onClick={() => {
                const filters = prepareTableFilterValues(tableFilter);
                tableFns.gotoPage instanceof Function && tableFns.gotoPage(0);
                setTimeout(() => {
                    setTableState((current) => ({ ...current, queryPageFilter: filters, queryPageIndex: 0 }));
                }, 200);
            }}
        >
            {translate('t.search')}
        </SearchButton>
    );
};

export default UiTable3ApplyFilterBtnContent;
