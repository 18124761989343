import styled from 'styled-components';
import { Typography } from '@mui/material';

export const HubsDeviceViewContent = styled.div``;

export const Text = styled(Typography)`
    text-align: center;
    min-height: 60vmin;
    font-weight: 600;
`;

export const DropDownContainer = styled.div`
    margin-bottom: 10px;
    & .MuiAutocomplete-root {
        width: 100px !important;
    }
`;
