import React from 'react';
import { useRecoilValue } from 'recoil';
import { UiTable3RightActionBarViewContent } from './UiTable3RightActionBar.style';
import { UiTable3RightActionBarProps } from './UiTable3RightActionBar.type';
import { SelectRow } from '../UiTable3/TableAtom';
import { getParams } from 'helpers';
import { TableAtom } from '../../../states/component/Table';

const UiTable3RightActionBarContent: React.FC<UiTable3RightActionBarProps> = (props): JSX.Element => {
    const selectRow = useRecoilValue(SelectRow);
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));

    return (
        <UiTable3RightActionBarViewContent data-testid='UiTable3RightActionBarContent'>
            {props.rightActionBtns &&
                props.rightActionBtns(
                    selectRow.selectedAll,
                    selectRow.selectedRows,
                    getParams(tableState),
                    tableState.totalCount
                )}
        </UiTable3RightActionBarViewContent>
    );
};

export default UiTable3RightActionBarContent;
