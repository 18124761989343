import React, { useEffect, useState } from 'react';
import { AlertByVehicleProps } from './AlertsList.type';
import { ModelTpmsAlerts } from 'models/TpmsAlerts.type';
import { VehicleEventModel } from 'models/VehicleEvent.type';
import { AlertSolveEvent, ModelAlertSolve } from 'models/NotificationEvent.type';
import { SensorAlertResponse } from 'models/Sensors.type';
import { TabNumber } from '../AlertsListTabManager/AlertsListTabManager.type';
import { GpsAlert } from 'models/SpeedEvent.type';
import { AlertsListContent } from './AlertsList.view';
import { ModalContent } from '../AlertsListTabManager/AlertsListTabManager.style';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import { isAdmin } from 'states/global/User';
import Button from 'components/Ui/Components/UiButton/UiButton';
import { Success } from 'components/Popup/Popup';
import NotificationEvent from 'api/NotificationEvent';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';

const notificationEventAPI = new NotificationEvent();

const getTabByKeys = (key: string): string => {
    switch (key) {
        case '_alertVehicleEvent':
            return '3';
            break; 
        case '_alertGPS':
            return '4';
            break;
        case '_alertSensor':
            return '5';
            break;
        case '_alertHistoryLog':
            return '6';
            break;
        default:
            return '2';
            break;
    }
}

const AlertsList: React.FC<AlertByVehicleProps> = (props): JSX.Element => {
    const [data, setData] = useState<ModelTpmsAlerts[] | GpsAlert[] | SensorAlertResponse[] | VehicleEventModel[]>(
        props.data ? props.data : []
    );
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const queryClient = useQueryClient();
    const userIsAdmin = useRecoilValue(isAdmin);

    const getData = () => {
        if (props.data) {
            setData(props.data);
        }
    };

    const handleResolved = async (alertIdToSolve: number, falseAlert?: number): Promise<void> => {
        try {
            const { data }: ModelAlertSolve = await notificationEventAPI.postSolvedEvents(alertIdToSolve, falseAlert);

            if ((data as AlertSolveEvent).notificationEvent.solvedAt) {
                if (props.tabNumber === TabNumber.TpmsAlert) {
                    queryClient.invalidateQueries(['GetTpmsAlert']);
                }

                if (props.tabNumber === TabNumber.HistoricalLog) {
                    queryClient.invalidateQueries(['GetTpmsAlertSolved']);
                }

                queryClient.invalidateQueries(['topBarAlerts']);
                modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
                Success({
                    text: `${translate(falseAlert ? 'p.alert_marked_as_false_alarm' : 'p.alert_marked_as_resolved')}`
                });
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error', error);
        }
    };

    const handleOpenConfigAlertsModal = (alertIdToSolve: number | null, faultyAlert?: number): void => {
        if (alertIdToSolve) {
            const contentModalResolved = {
                id: 'ResolveAlertsByVehicleModal',
                leftTitle: faultyAlert ? translate('t.false_alert') : translate('t.solve_alert'),
                content: (
                    <ModalContent>
                        {faultyAlert
                            ? translate('p.are_you_sure_to_mark_this_notification_as_false_alarm')
                            : translate('p.are_you_sure_to_mark_this_notification_as_resolved')}
                    </ModalContent>
                ),
                buttons: (
                    <>
                        <Button
                            skin='success'
                            color='primary'
                            variant='contained'
                            onClick={() => {
                                handleResolved(alertIdToSolve, faultyAlert);
                            }}
                        >
                            {translate('t.yes')}
                        </Button>
                    </>
                ),
                width: 500,
                widthUnit: 'px',
                height: 70
            };

            modalDispach({
                type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                ModalPropsPayload: {
                    ...contentModalResolved,
                    onClose: () =>
                        modalDispach({
                            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                            ModalPropsPayload: {
                                ...contentModalResolved,
                                isOpen: false
                            }
                        }),
                    isOpen: true
                }
            });
        }
        !alertIdToSolve && modalDispach({ type: ModalActionTypesEnum.CLOSE_MODAL });
    };

    useEffect(() => {
        getData();

        return () => {
            setData([]);
        };
    }, [props.data]);

    return (
        <AlertsListContent
            data-testid={'AlertByVehicle-testid'}
            dataShow={data}
            isAdmin={userIsAdmin}
            showTab={getTabByKeys(props.alertKey)}
            handleOpenConfigAlertsModal={handleOpenConfigAlertsModal}
            {...props}
        />
    );
};

export default AlertsList;
