import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
    AlertTemperatureSettingsViewContent,
    LevelIndicator,
    LevelIndicatorContent
} from './AlertTemperatureSettings.style';
import { AlertTemperatureSettingsViewProps } from './AlertTemperatureSettings.type';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import AlertTemperatureSlider from '../AlertTemperatureSlider';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { GetConvertedAmbient } from 'states/global/CustomerSettings';

export const AlertTemperatureSettingsContent: React.FC<AlertTemperatureSettingsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const { fromServerToUserUnit, displayUserUnits } = useConverter();
    const getConvertedAmbient = useRecoilValue(GetConvertedAmbient);
    const now = DateTime.now();
    const currentMonth: number = parseInt(now.toFormat('M'));
    const theme = useTheme();

    const ambientTemperature = useMemo(() => getConvertedAmbient(fromServerToUserUnit), [getConvertedAmbient]);

    return (
        <AlertTemperatureSettingsViewContent data-testid='AlertTemperatureSettingsContent'>
            <Grid container direction={'row'} sm={12} md={12} lg={12} spacing={0}>
                <LevelIndicatorContent item>
                    <Typography variant='subtitle1' data-testid='AlertsAmbientTemperatureLabel'>{`${translate(
                        't.ambient_temperature'
                    )}: ${ambientTemperature ? ambientTemperature.current : '-'} ${
                        displayUserUnits.temperature
                    }`}</Typography>
                </LevelIndicatorContent>
                <Divider orientation='vertical' flexItem style={{ marginRight: 15 }} />
                <LevelIndicatorContent item>
                    <Typography variant='subtitle1' data-testid='AlertsLevel1Label'>{`${translate(
                        't.level'
                    )} 1`}</Typography>
                    <LevelIndicator level={1} theme={theme} data-testid='AlertsLevel1Indicator' />
                </LevelIndicatorContent>
                <LevelIndicatorContent item>
                    <Typography variant='subtitle1' data-testid='AlertsLevel2Label'>{`${translate(
                        't.level'
                    )} 2`}</Typography>
                    <LevelIndicator level={2} theme={theme} data-testid='AlertsLevel2Indicator' />
                </LevelIndicatorContent>
                <LevelIndicatorContent item>
                    <Typography variant='subtitle1' data-testid='AlertsLevel3Label'>{`${translate(
                        't.level'
                    )} 3`}</Typography>
                    <LevelIndicator level={3} theme={theme} data-testid='AlertsLevel3Indicator' />
                </LevelIndicatorContent>
                <Divider orientation='vertical' flexItem style={{ marginRight: 15 }} />
                <LevelIndicatorContent item>
                    <Typography variant='subtitle1' data-testid='AlertsTemperatureLevelEditorLabel'>{`${translate(
                        't.temperature_levels_editor'
                    )} (${displayUserUnits.temperature})`}</Typography>
                </LevelIndicatorContent>
            </Grid>
            <Grid>
                <AlertTemperatureSlider ruleType={props.ruleType} />
            </Grid>
        </AlertTemperatureSettingsViewContent>
    );
};
