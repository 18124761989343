import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
    DeviceCommandsTab,
    DeviceCommandsTableViewContent,
    DeviceCommandsTabs,
    DeviceCommandsTabsContainer,
    TabContentHistory,
    TabContentTerminal
} from './DeviceCommandsTable.style';
import { DeviceCommandsTableViewProps } from './DeviceCommandsTable.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../Ui/Components/UiWidget/UiWidget';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { ActiveDeviceCommandsTab } from './DeviceCommandsTable.state';
import TabContent from '../../Ui/Components/UiTab/UiTab';
import UiTable from '../../Ui/Components/UiTable2/UiTable';
import TopBarTerminal from './Components/TopBarTerminal/TopBarTerminal';
import DeviceCommand from '../../../api/DeviceCommand';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

const deviceCommandApi = new DeviceCommand();

export const DeviceCommandsTableContent: React.FC<DeviceCommandsTableViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const activeTabAtom = useRecoilValue(ActiveDeviceCommandsTab);
    const activeTab = activeTabAtom.details;
    const historycolumns = [...props.columns];
    historycolumns.pop();

    const hidden: string[] = ['commandNameFilter'];
    const ThemeMode = useRecoilValue(Theme);

    const TerminalTable = useCallback(() => {
        return (
            <UiTable
                maxHeight={450}
                columns={props.columns}
                fetchFn={deviceCommandApi.getRecentPlusHistoryDeviceCommand}
                additionalProps={{ id: props.deviceId }}
                hiddenColumns={hidden}
                refresh={false}
                queryKey={'deviceCommandsTableTerminal'}
                hiddenFilterAction={true}
            />
        );
    }, [props.deviceId]);

    const HistoryTable = useCallback(() => {
        return (
            <UiTable
                maxHeight={450}
                columns={historycolumns}
                fetchFn={deviceCommandApi.getRecentPlusHistoryDeviceCommand}
                additionalProps={{ id: props.deviceId }}
                refresh={false}
                hiddenColumns={[]}
                queryKey={'deviceCommandsTableTerminal'}
                hiddenFilterAction={true}
            />
        );
    }, [props.deviceId]);

    return (
        <DeviceCommandsTableViewContent data-testid='DeviceCommandsTableContent'>
            <Widget
                data-testid='AdministrationTableContent'
                avatar={
                    <UiIcon
                        icon={faChevronRight}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#333',
                            theme: ThemeMode?.mode,
                            dark: WHITE
                        })}
                    />
                }
                title={translate('t.hub_commands')}
                loading={false}
                content={
                    <>
                        <DeviceCommandsTabsContainer>
                            <DeviceCommandsTabs
                                value={activeTab}
                                onChange={props.handleTabChange}
                                indicatorColor='primary'
                                textColor='primary'
                            >
                                <DeviceCommandsTab label={`${translate('t.terminal')}`} />

                                <DeviceCommandsTab label={`${translate('t.history')}`} />
                            </DeviceCommandsTabs>
                        </DeviceCommandsTabsContainer>

                        <TabContent value={activeTab} index={0} testId='DeviceCommandsTerminal'>
                            <TopBarTerminal deviceId={props.deviceId} />
                            <TabContentTerminal>
                                <TerminalTable />
                            </TabContentTerminal>
                        </TabContent>
                        <TabContent value={activeTab} index={1} testId='DeviceCommandsHistory'>
                            <TabContentHistory>
                                <HistoryTable />
                            </TabContentHistory>
                        </TabContent>
                    </>
                }
            ></Widget>
        </DeviceCommandsTableViewContent>
    );
};
