import React from 'react';
import { useRecoilValue } from 'recoil';
import { AdministrationTableContainer } from './AdministrationTable.style';
import { AdministrationTableViewPropsView } from './AdministrationTable.type';
import UiTable from '../../Ui/Components/UiTable2/UiTable';
import { useTranslation } from 'react-i18next';
import Device from '../../../api/Device';
import Button from '../../Ui/Components/UiButton/UiButton';
import Widget from '../../Ui/Components/UiWidget/UiWidget';
import { checkRole } from 'helpers/authentication';
import { UserInfo } from '../../../states/global/User';
import { ROLES } from 'variables';
import { applyStyleByMode } from 'helpers';
import { SIZE_BUTTON } from '../../Ui/variables';
import AddIcon from '@mui/icons-material/Add';
import { faHdd } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';

const administrationHubApi = new Device();

export const AdministrationTableContent: React.FC<AdministrationTableViewPropsView> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    const userInfo = useRecoilValue(UserInfo);
    const hidden: string[] = [
        'lastTransmissionFrom',
        'lastTransmissionTo',
        'powerSourceFrom',
        'powerSourceTo',
        'batteryFrom',
        'batteryTo'
    ];

    return (
        <>
            <AdministrationTableContainer data-testid='AdministrationTableContent'>
                <Widget
                    data-testid='AdministrationTableContent'
                    avatar={
                        <UiIcon
                            icon={faHdd}
                            size='lg'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#fff',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    }
                    title={translate('t.hubs')}
                    loading={false}
                    content={
                        <UiTable
                            columns={props.columns}
                            fetchFn={administrationHubApi.getAdministrationHubTable}
                            exportFn={administrationHubApi.getExport}
                            exportName={'Devices'}
                            hiddenColumns={hidden}
                            actionsButton={
                                !checkRole(userInfo.user, [
                                    ROLES.ROLE_ENGINEERING,
                                    ROLES.ROLE_BASE_USER,
                                    ROLES.ROLE_TECHNICAL_USER
                                ]) ? (
                                    <Button
                                        variant='contained'
                                        skin='success'
                                        style={{ color: '#fff' }}
                                        size={SIZE_BUTTON}
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            props.handleModalAdd();
                                        }}
                                    >
                                        {translate('t.add_hub')}
                                    </Button>
                                ) : (
                                    <></>
                                )
                            }
                            defaultSortBy={{
                                id: 'id',
                                desc: false
                            }}
                            queryKey={'administrationHub'}
                        />
                    }
                ></Widget>
            </AdministrationTableContainer>
        </>
    );
};
