import React, { useEffect } from 'react';
import { CustomTimePicker, UiTimePickerContainer } from './UiTimePicker.style';
import { UiTimePickerProps } from './UiTimePicker.type';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { GetUserTimeMask, Is12HrsFormat } from 'helpers/Converters';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import { SIZE_INPUT } from 'components/Ui/variables';
import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from 'components/Mixs/HelperText/HelperText';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { CustomModalDialog } from '../UiDatePicker/UiDatePicker.style';
import { BACKGROUND_GREEN } from 'components/Ui/colors';
import DateTimeDialogSelector from './DateTimeDialogSelector/DateTimeDialogSelector';

const UiTimePicker: React.FC<UiTimePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const getUserTimeMask = useRecoilValue(GetUserTimeMask);
    const [openDate, setOpenDate] = React.useState(false);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = React.useState<DateTime | null>(DateTime.local());
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = React.useState<DateTime | null>(dateValue);

    useEffect(() => {
        props.value && setDateValue(DateTime.isDateTime(props.value) ? props.value : DateTime.fromISO(props.value));
    }, [props.value]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiTimePickerContainer data-testid='UiTimePickerContent'>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CustomTimePicker
                    label={props.label || translate('t.time')}
                    value={dateValue}
                    mask={getUserTimeMask({ displaySeconds: Boolean(props.displaySeconds) })}
                    ampm={is12HrsFormat}
                    onChange={(newDate: DateTime): void => {
                        if (newDate == null) {
                            setDateValue('');
                            props.onDateChange && props.onDateChange('');
                        } else {
                            setDateValue(newDate);
                            props.onDateChange && props.onDateChange(newDate);
                        }
                    }}
                    inputFormat={dateTimeFormat('time', Boolean(props.displaySeconds))}
                    open={false}
                    onOpen={() => {
                        setOpenDate(true);
                        dateValueStaticDatePicker.invalid && setDateValueStaticDatePicker(DateTime.local());
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant='outlined'
                            size={SIZE_INPUT}
                            {...params}
                            margin='normal'
                            id={`UiDatePicker${Math.ceil(Math.random() * 1000000000000000000)}`}
                            style={{ margin: '0 0 25px 0 !important' }}
                            data-testid={`UiDatePicker${
                                props.testId ||
                                firstCapital(snakeToCamel((props.label || translate('t.time')).replaceAll(' ', '_')))
                            }`}
                            onFocus={() => props.onFocus && props.onFocus()}
                            onBlur={() => props.onBlur && props.onBlur()}
                            helperText={
                                !props.hiddenHelperText && (
                                    <HelperText
                                        data-testid={`error$${
                                            props.testId ||
                                            firstCapital(
                                                snakeToCamel((props.label || translate('t.time')).replaceAll(' ', '_'))
                                            )
                                        }`}
                                        error={params.error}
                                        text={translate('t.the_time_format_is_invalid')}
                                    />
                                )
                            }
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: dateTimeFormat('time', Boolean(props.displaySeconds)),
                                style: { width: '100%', textTransform: 'uppercase' }
                            }}
                        />
                    )}
                />
                <CustomModalDialog
                    open={openDate}
                    DialogProps={{
                        PaperProps: {
                            sx: {
                                maxWidth: '100px',
                                width: '100px',
                                maxHeight: '220px',
                                '@media (min-width: 768px)': {
                                    maxWidth: '100px',
                                    width: '100px',
                                    height: '220px',
                                    '.MuiDialogActions-root': {
                                        height: '0px',
                                        button: {
                                            bottom: '20px',
                                            height: '35px',
                                            padding: '0px 8px 0px 8px'
                                        }
                                    }
                                },
                                '&& .MuiDialogActions-root': {
                                    button: {
                                        border: `1px solid ${BACKGROUND_GREEN}`,
                                        color: BACKGROUND_GREEN
                                    },
                                    'button:nth-child(2)': {
                                        border: `1px solid ${BACKGROUND_GREEN} !important`,
                                        backgroundColor: `${BACKGROUND_GREEN} !important`,
                                        color: 'white'
                                    }
                                }
                            }
                        }
                    }}
                    onCancel={() => {
                        setOpenDate(false);
                        setDateValueStaticDatePicker(dateValue.invalid ? DateTime.local() : dateValue);
                    }}
                    onSetToday={() => {}}
                    onClear={() => {}}
                    onDismiss={() => setOpenDate(false)}
                    onAccept={() => {
                        if (!dateValueStaticDatePicker.invalid) {
                            setDateValue(dateValueStaticDatePicker);
                            props.onDateChange && props.onDateChange(dateValueStaticDatePicker);
                        }

                        setOpenDate(false);
                    }}
                >
                    <DateTimeDialogSelector
                        dateValueStaticDatePicker={dateValueStaticDatePicker}
                        setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                        displaySeconds={Boolean(props.displaySeconds)}
                    />
                </CustomModalDialog>
            </LocalizationProvider>
        </UiTimePickerContainer>
    );
};

export default UiTimePicker;
