import React from 'react';
import { DeviceDetailsTableContent } from './DeviceDetailsTable.view';
import { DeviceDetailsTableProps } from './DeviceDetailsTable.type';
import { useQuery } from '@tanstack/react-query';
import Device from '../../../api/Device';
import { useSetRecoilState } from 'recoil';
import { ActiveDeviceDetailsTab } from './DeviceDetailsTable.state';
import { Wrapper } from 'helpers/wrapper';

const deviceApi = new Device();

const DeviceDetailsTable: React.FC<DeviceDetailsTableProps> = (props): JSX.Element => {
    const setActiveTab = useSetRecoilState(ActiveDeviceDetailsTab);

    const { data, isLoading, isRefetching } = useQuery(
        [`DeviceDetailsTableQuery-${props.deviceId}`],
        () => deviceApi.getDetails(props.deviceId),
        {
            retry: false,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );

    const handleTabChange = (event, newTabIndex: number): void => {
        setActiveTab({ details: newTabIndex });
    };

    return (
        <DeviceDetailsTableContent
            data-testid='DeviceDetailsTable-testid'
            handleTabChange={handleTabChange}
            deviceId={props.deviceId}
            data={data}
            isLoading={isLoading || isRefetching}
        />
    );
};

export default Wrapper(DeviceDetailsTable);
