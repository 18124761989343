import React, { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { GpsHeatmapViewContent } from './GpsHeatmap.style';
import { GpsHeatmapViewProps } from './GpsHeatmap.type';
import { useTranslation } from 'react-i18next';
import Widget from '../../components/Ui/Components/UiWidget';
import MapLegend from '../../components/Ui/Components/UiMapComponent';
import HeatmapLegend from '../../components/Maps/HeatmapLegend';
import HeatmapTopbar from '../../components/Maps/HeatmapTopBar';
import { HeatmapKeys, MapActionsEnum, MapModes } from '../../components/Map/Map.type';
import { MapAction, MapActionData } from '../../states/global/Map';
import Map from '../../components/Map';
import { HeatmapSettings } from '../../components/HeatmapPlayground/HeatmapPlayground.state';
import { applyStyleByMode } from 'helpers';
import { UserInfo } from '../../states/global/User';
import { Settings } from 'luxon';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import UiIcon from 'components/Ui/Components/UiIcon';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';

export const GpsHeatmapContent: React.FC<GpsHeatmapViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const [mapAction, setMapAction] = useRecoilState(MapAction);
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const heatmapSettingsState = useRecoilValue(HeatmapSettings);
    const heatmapSettings = heatmapSettingsState();
    const heatmapLimit = useMemo(() => {
        return { min: 20, max: 0 };
    }, []);
    const ThemeMode = useRecoilValue(Theme);

    const heatmap = useMemo(() => {
        const zoom = Math.max(mapActionData.zoomLevel || 13, 13);
        return {
            dotSize: heatmapSettings[zoom].dotSize,
            innerRadius: heatmapSettings[zoom].innerRadius,
            outerRadius: heatmapSettings[zoom].outerRadius,
            innerOpacity: heatmapSettings[zoom].innerOpacity,
            outerOpacity: heatmapSettings[zoom].outerOpacity,
            enableBlur: !heatmapSettings[zoom].disableBlur
        };
    }, [mapActionData.zoomLevel]);

    const getView = useCallback(() => {
        if (userInfo.user?.userSetting.timezone.timezoneName) {
            Settings.defaultZone = userInfo.user?.userSetting.timezone.timezoneName || 'Europe/Bratislava';
            return (
                <GpsHeatmapViewContent data-testid='GpsHeatmapContent'>
                    <HeatmapTopbar
                        showDatetimePicker={true}
                        showVehiclePicker={true}
                        onApply={(dateRange, vehicles) => {
                            if (dateRange && vehicles) {
                                setMapActionData((currentMapActionData) => {
                                    return {
                                        ...currentMapActionData,
                                        heatmapLimit,
                                        heatmapDateRange: dateRange,
                                        vehicleIds: vehicles,
                                        ...heatmap,
                                        heatmapKey: HeatmapKeys.GPS
                                    };
                                });
                                setMapAction({
                                    action: MapActionsEnum.DRAW_HEATMAP
                                });
                            }
                        }}
                    />
                    <Widget
                        title={translate('t.gps_heatmap')}
                        collapseButton={false}
                        fullScreenButton={true}
                        {...props}
                        avatar={
                            <UiIcon
                                icon={faLocationDot}
                                size='lg'
                                fixedWidth
                                color={applyStyleByMode({
                                    styleOld: '#fff',
                                    theme: ThemeMode?.mode,
                                    light: undefined,
                                    dark: WHITE
                                })}
                            />
                        }
                        scrollbar={false}
                        $contentHeight={75}
                        marginBottom={false}
                        applyPadding={false}
                        onResize={() => {
                            setMapAction({
                                action: MapActionsEnum.RESIZE_MAP,
                                params: {
                                    lastAction: mapAction
                                }
                            });
                        }}
                        unit='vh'
                        padding={0}
                    >
                        <MapLegend>
                            <HeatmapLegend
                                from={heatmapLimit.min}
                                to={heatmapLimit.max}
                                unit={translate('t.satellites')}
                            />
                        </MapLegend>
                        <Map mapMode={MapModes.GPS_HEATMAP} />
                    </Widget>
                </GpsHeatmapViewContent>
            );
        }
        return <></>;
    }, [userInfo.user?.userSetting.timezone.timezoneName]);

    return getView();
};
