import PortalApiClient from 'helpers/api/PortalApiClient';
import {
    AlertsBreakdownQueryParams,
    AlertsBreakDownResponse,
    LeakNotification,
    MqttResponse,
    NotificationEventTypeModel,
    TyreNotificationPreview,
    TyreEventModelResponse,
    TyreEventAdditionalProps,
    TyreNotificationsResponse,
    ModelAlertSolve,
    GetNotificationsType
} from 'models/NotificationEvent.type';

import {
    AlertTypesFilters,
    typeFilter
} from 'components/AlertNotification/AlertsListTabManager/AlertsListTabManager.type';
import { FilterProps } from 'components/Ui/Components/UiTable2/UiTable.type';
import { GetParamsProps } from 'models/Table.type';
import { getParams } from 'helpers';

export default class NotificationEvent extends PortalApiClient<NotificationEventTypeModel> {
    route = 'notification-event';

    getAlertsBreakDown = (params: AlertsBreakdownQueryParams): Promise<AlertsBreakDownResponse> => {
        if (typeof params.vehicleId !== 'number' || !params.vehicleId) {
            delete params['vehicleId'];
        }

        return this.getByCriteria({
            extendUrl: `alerts-break-down/${params.period}`,
            criteria: {
                ...({ vehicleId: params.vehicleId } as Record<string, number>)
            }
        });
    };

    postSolvedEvents = (alertId: number, falseAlert = 0): Promise<ModelAlertSolve> => {
        let params: { falseAlert?: number } = {};

        if (falseAlert) {
            params = { falseAlert };
        }

        return this.post({
            extendUrl: `${alertId}/solve`,
            data: params
        });
    };

    getTotalAlertsByTyre = (tyreId: number): Promise<TyreNotificationsResponse> => {
        this.showError404 = false;
        return this.get({
            extendUrl: `total-alerts-by-tyre/${tyreId}`
        });
    };

    getTempPreview = (props): Promise<TyreNotificationPreview> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }

        return this.getByCriteria({
            extendUrl: 'temp/preview',

            criteria: criteria
        });
    };

    getPreview = (props): Promise<TyreNotificationPreview> => {
        let criteria = {
            measuredFrom: props.additionalProps.measuredFrom,
            measuredTo: props.additionalProps.measuredTo
        };

        if (props.additionalProps.vehicleId > 0) {
            criteria['vehicleId'] = props.additionalProps.vehicleId;
        }

        return this.getByCriteria({
            extendUrl: 'preview',

            criteria
        });
    };

    postLeakNotification = (params: LeakNotification): Promise<unknown> => {
        return this.post({
            extendUrl: 'leak-notification',
            data: params
        });
    };

    getNotification = async ({
        vehicleId,
        solved,
        measuredFrom,
        sensorId,
        page,
        limit,
        alertFilters,
        wheelId,
        tab
    }: GetNotificationsType): Promise<any> => {
        let filterParams: FilterProps[] = [];
        wheelId && filterParams.push({ id: 'wheelId', value: { name: 'wheelId', value: `${wheelId}` } });
        vehicleId && filterParams.push({ id: 'vehicleId', value: { name: 'vehicleId', value: `${vehicleId}` } });
        solved !== undefined && filterParams.push({ id: 'solved', value: { name: 'solved', value: `${+solved}` } });
        measuredFrom &&
            filterParams.push({ id: 'measuredFrom', value: { name: 'measuredFrom', value: `${measuredFrom}` } });
        sensorId && filterParams.push({ id: 'sensorId', value: { name: 'sensorId', value: `${sensorId}` } });
        if (alertFilters?.type) {
            alertFilters?.type.length > 1
                ? filterParams.push({
                      id: 'type',
                      value: {
                          name: 'multipleValues',
                          value: alertFilters?.type.map((val) =>
                              val === typeFilter.PRESSURE_HOT ? 'hotPressure' : `${val}`
                          )
                      }
                  })
                : filterParams.push({
                      id: 'type',
                      value: {
                          name: 'type',
                          value: `${
                              alertFilters?.type[0] === typeFilter.PRESSURE_HOT ? 'hotPressure' : alertFilters?.type[0]
                          }`
                      }
                  });
        }
        if (alertFilters?.level) {
            alertFilters?.level.length > 1
                ? filterParams.push({
                      id: 'level',
                      value: { name: 'multipleValues', value: alertFilters?.level.map((val) => `${val}`) }
                  })
                : filterParams.push({ id: 'level', value: { name: 'level', value: `${alertFilters?.level[0]}` } });
        }
        tab && filterParams.push({ id: 'tab', value: { name: 'tab', value: tab } });

        return await this.getTable({
            version: 'v2',
            criteria: getParams({
                queryPageIndex: page ? page - 1 : 0,
                queryPageSize: limit ?? 10,
                queryPageSortBy: [],
                queryPageFilter: filterParams
            })
        });
    };

    getTyreEvent = async (
        props: GetParamsProps,
        additionalProps: TyreEventAdditionalProps
    ): Promise<TyreEventModelResponse> => {
        const propsParams = getParams(props);
        propsParams.measuredFrom = additionalProps.measuredFrom;
        propsParams.measuredTo = additionalProps.measuredTo;
        propsParams.vehicleId = additionalProps.vehicleId;
        if (propsParams.type === 'pressure') {
            propsParams.type = ['pressure', 'hotPressure'];
        }
        if (!propsParams.type) {
            propsParams.type = ['temperature', 'pressure', 'hotPressure'];
        }
        if (additionalProps.tab) {
            propsParams.tab = additionalProps.tab;
        }

        return await this.getTable({
            version: 'v2',
            criteria: propsParams
        });
    };

    getMqtt = async (): Promise<MqttResponse> => {
        return await this.get({
            extendUrl: 'mqtt'
        });
    };
}
