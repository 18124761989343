import { StaticDateTimePicker, StaticDatePicker } from '@mui/x-date-pickers-pro';
import { PickersModalDialog } from '@mui/x-date-pickers/internals/components/PickersModalDialog';
import '@mui/lab';
import styled from 'styled-components';
import { ThemeMode } from '../../../../states/global/Theme';
import { BACKGROUND_BLUE } from 'components/Ui/colors';
import { useTheme } from '@mui/material';

export const CustomModalDialog = styled(PickersModalDialog)``;

export const UiDateRangePicker2El = styled.div`
    display: inline-block;
    ${() => {
        return `
            &&& { 
                .MuiTextField-root {
                    margin: 8px;
                    margin-left: 0px;
                    & label {
                        padding: 0 5px 0 0;
                        background: ${useTheme().palette.mode === ThemeMode.dark ? 'transparent' : 'transparent'};
                    }
                    & .MuiInputLabel-shrink {
                        transform: translate(12px, -9px) scale(0.8);
                    }
                }
            }
        `;
    }}
`;

export const CustomStaticDatePicker = styled(StaticDatePicker)`
    height: 400px;
`;

export const CustomStaticDateTimePicker = styled(StaticDateTimePicker)`
    height: 400px;
`;

export const DialogPaperProps = (onlyDate) => {
    return {
        PaperProps: {
            sx: {
                maxWidth: onlyDate ? '130px' : '150px',
                width: onlyDate ? '130px' : '150px',
                maxHeight: '800px',
                '@media (min-width: 768px)': {
                    maxWidth: onlyDate ? '300px' : '590px',
                    width: onlyDate ? '300px' : '590px',
                    height: onlyDate ? '430px' : '500px',
                    '.MuiDialogActions-root': {
                        height: '0px',
                        button: {
                            bottom: '20px',
                            height: '35px',
                            padding: '0px 8px 0px 8px'
                        }
                    }
                },
                '&& .MuiDialogActions-root': {
                    button: {
                        border: `1px solid ${BACKGROUND_BLUE}`,
                        color: BACKGROUND_BLUE
                    },
                    'button:nth-child(2)': {
                        border: `1px solid ${BACKGROUND_BLUE} !important`,
                        backgroundColor: `${BACKGROUND_BLUE} !important`,
                        color: 'white'
                    }
                }
            }
        }
    };
};
