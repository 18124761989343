import React from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
    AutocompleteWithStyle,
    MinesPermissionsViewContent,
    SubHead,
    TableName,
    WidgetContent
} from './MinesPermissions.style';
import { MinesPermissionsViewProps } from './MinesPermissions.type';
import { useTranslation } from 'react-i18next';
import Modal from '../../Modal';
import { SelectedUser } from './Permissions.atom';
import { ShowPermissionModal } from '../../../states/global/Modal';
import UiButton from '../../Ui/Components/UiButton';
import { TextField, Typography, useMediaQuery, useTheme, Grid, Hidden, Box } from '@mui/material';
import { CRUD } from 'variables';
import { ChangeCustomerEnum, MinesPermissionsAtom, MinesPermissionsDispatcher } from './MinesPermissions.reducer';
import MinesPermissionTable from './Table';
import { CustomerCodebook } from '../../../models/Customer.type';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import UiScroll from 'components/Ui/Components/UiScroll/UiScroll';

export const MinesPermissionsContent: React.FC<MinesPermissionsViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const permissionsAtom = useRecoilValue(SelectedUser);
    const setPermissionModal = useSetRecoilState(ShowPermissionModal);
    const resetPermissionAtom = useResetRecoilState(SelectedUser);
    const [minesPermissionsState, setMinesPermissionsState] = useRecoilState(MinesPermissionsAtom);
    const minesPermissionsDispatch = MinesPermissionsDispatcher(minesPermissionsState, setMinesPermissionsState);
    const theme = useTheme();
    const showXsSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

    const buttonSave = (): JSX.Element => (
        <div
            title={
                minesPermissionsState.minesPermission.assigned.length === 0 ? translate('p.at_least_1_assigned') : ''
            }
        >
            <UiButton
                skin='success'
                color='primary'
                variant='contained'
                testid='MinesPermissions-Save-Button'
                onClick={() => {
                    if (permissionsAtom?.id) {
                        props.mutationSave.mutate(permissionsAtom.id);
                    }
                }}
                disabled={minesPermissionsState.minesPermission.assigned.length === 0}
            >
                {translate('t.save')}
            </UiButton>
        </div>
    );

    const contentView = (viewModal = true): JSX.Element => {
        if (props.loading) return <UiLoadingPage size='25px' />;

        return (
            <UiScroll maxHeight={viewModal ? undefined : '68vh'}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <SubHead>
                            <Hidden only={['xs']}>
                                <TableName elevation={0}>
                                    <Typography>{translate('t.all_customers')}</Typography>
                                </TableName>
                            </Hidden>
                            <AutocompleteWithStyle
                                data-testid='MinesPermissions-Autocomplete-All'
                                options={minesPermissionsState.minesPermission.notAssigned || []}
                                getOptionLabel={(customer) => (customer as CustomerCodebook).name || ''}
                                size='small'
                                onChange={(event, customer) => {
                                    minesPermissionsDispatch({
                                        type: ChangeCustomerEnum.NOT_ASSIGNED_CUSTOMER_SEARCH,
                                        payload: customer as CustomerCodebook
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate('t.search_by_customers')}
                                        variant='outlined'
                                    />
                                )}
                                disabled={!((props.customers?.length as number) > 0)}
                            />
                        </SubHead>
                        <MinesPermissionTable
                            testid='Customers-All'
                            buttonAction={props.rowAction}
                            actionType={CRUD.ADD}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <SubHead>
                            <Hidden only={['xs']}>
                                <TableName elevation={0}>
                                    <Typography>{translate('t.assigned_customers')}</Typography>
                                </TableName>
                            </Hidden>
                            <AutocompleteWithStyle
                                data-testid='MinesPermissions-Autocomplete-Assigned'
                                options={minesPermissionsState.minesPermission.assigned || []}
                                getOptionLabel={(customer) => (customer as CustomerCodebook).name || ''}
                                size='small'
                                onChange={(event, customer) => {
                                    minesPermissionsDispatch({
                                        type: ChangeCustomerEnum.ASSIGNED_CUSTOMER_SEARCH,
                                        payload: customer as CustomerCodebook
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={translate('t.search_by_customers')}
                                        variant='outlined'
                                    />
                                )}
                                disabled={!((props.customers?.length as number) > 0)}
                            />
                        </SubHead>
                        <MinesPermissionTable
                            testid='Customers-Assigned'
                            buttonAction={props.rowAction}
                            actionType={CRUD.REMOVE}
                        />
                    </Grid>
                </Grid>
            </UiScroll>
        );
    };

    if (props.noRenderModal) {
        return (
            <>
                <Box sx={{ p: 2 }}>
                    {contentView(false)}
                    {!props.loading && (
                        <Box sx={{ textAlign: 'end', mt: 3 }}>
                            {props.mutationSave.isLoading ? <UiLoadingPage size='25px' /> : buttonSave()}
                        </Box>
                    )}
                </Box>
            </>
        );
    }

    return (
        <MinesPermissionsViewContent data-testid='MinesPermissionsContent'>
            <Modal
                id={`permission-modal-${permissionsAtom?.id}`}
                testid={`Permission-Modal-${permissionsAtom?.id}`}
                leftTitle={`${translate('t.mines_permissions')} ${` - ${permissionsAtom?.username}`}`}
                rightTitle={`${translate('t.username')}: ${permissionsAtom?.username}`}
                type='ShowPermissionModal'
                width={showXsSm ? 98 : 80}
                height={showXsSm ? 98 : 75}
                widthUnit='vw'
                heightUnit='vh'
                onClose={() => {
                    setPermissionModal(false);
                    resetPermissionAtom();
                    props.setShowPermissionModal && props.setShowPermissionModal(false);
                }}
                buttons={
                    props.mutationSave.isLoading ? (
                        <Box sx={{ mb: 3, mr: 2 }}>
                            <UiLoadingPage size='25px' />
                        </Box>
                    ) : (
                        buttonSave()
                    )
                }
                content={contentView()}
            />
        </MinesPermissionsViewContent>
    );
};
