import React from 'react';
import { useRecoilValue } from 'recoil';
import {
    CardContentCustom,
    CollapseButton,
    CustomCard,
    CustomCardHeader,
    CustomCardHeaderContainer,
    UiWidgetContainer
} from './UiWidget.style';
import { TabBody, UiWidgetViewProps } from './UiWidget.type';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import { Fullscreen, FullscreenExit } from '@mui/icons-material';
import UiScroll from '../UiScroll/UiScroll';
import { Theme } from '../../../../states/global/Theme';
import UiIcon from '../UiIcon/UiIcon';
import { applyStyleByMode } from '../../../../helpers';
import { DARK_STEEL_GREY, BACKGROUND_BLUE } from '../../colors';
import { Collapse, Tooltip } from '@mui/material';
import UiLoadingPage from '../UiLoadingPage/UiLoadingPage';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const createContent = (
    content?: JSX.Element | (JSX.Element | TabBody)[] | null | React.ReactNode,
    loading?: boolean,
    height?: number,
    collapsed?: boolean,
    collapseButton?: boolean,
    contentHeight?: number,
    unit?: string
) => {
    if (collapseButton) {
        return (
            <Collapse in={!collapsed} timeout='auto'>
                <UiScroll height={contentHeight} unit={unit}>
                    <>{!loading && content}</>
                </UiScroll>
            </Collapse>
        );
    }

    return loading ? (
        <UiLoadingPage size='30px' />
    ) : (
        <UiScroll height={contentHeight} unit={unit}>
            <>{!loading && content}</>
        </UiScroll>
    );
};

const actionButtonsHeader = (
    changeFullscreen: () => void,
    fullscreen: boolean,
    title: React.ReactNode | string,
    fullScreenButton?: boolean
) => {
    const ButtonFullScreenButton: JSX.Element = (
        <IconButton
            aria-label='settings'
            data-testid={`${title}-FullScreenButton`}
            onClick={changeFullscreen}
            size='large'
        >
            {fullscreen && <Fullscreen fontSize='inherit' style={{ width: '20px', height: '20px' }} />}
            {!fullscreen && <FullscreenExit fontSize='inherit' style={{ width: '20px', height: '20px' }} />}
        </IconButton>
    );

    return fullScreenButton ? ButtonFullScreenButton : <></>;
};

export const UiWidgetContent: React.FC<UiWidgetViewProps> = ({
    width,
    unit,
    height,
    $contentHeight,
    avatar,
    $headerActionStartsLeft,
    headerAction,
    title,
    loading,
    content,
    applyPadding = true,
    fullscreen,
    fullScreenButton,
    marginBottom = true,
    children,
    lockButton,
    lock,
    collapseButton,
    collapsed,
    sx,
    headerColor,
    widgetRef,
    testid,
    changeFullscreen,
    changeLock,
    changeCollapsed,
    actionScroll,
    $testidCollapseButton
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeMode = useRecoilValue(Theme);

    return (
        <UiWidgetContainer data-testid={testid || 'UiWidgetContainer'} ref={widgetRef}>
            <CustomCard
                width={width || 100}
                unit={unit || 'px'}
                height={height}
                $contentHeight={$contentHeight}
                $fullscreen={fullscreen}
                $applyPadding={applyPadding}
                sx={sx}
            >
                <CustomCardHeaderContainer $headerColor={headerColor}>
                    <CustomCardHeader
                        $headerColor={headerColor}
                        avatar={avatar}
                        $marginBottom={marginBottom}
                        $headerActionStartsLeft={$headerActionStartsLeft}
                        action={
                            <>
                                {headerAction}
                                {lockButton && (
                                    <IconButton
                                        aria-label='settings'
                                        onClick={changeLock}
                                        data-testid='Uiwidget-LockButton-Button'
                                    >
                                        {lock ? (
                                            <UiIcon
                                                icon={['fas', 'lock']}
                                                size='lg'
                                                fixedWidth
                                                color={applyStyleByMode({
                                                    styleOld: '#fff',
                                                    theme: ThemeMode?.mode,
                                                    light: DARK_STEEL_GREY,
                                                    dark: BACKGROUND_BLUE
                                                })}
                                            />
                                        ) : (
                                            <UiIcon
                                                icon={['fas', 'lock-open']}
                                                size='lg'
                                                fixedWidth
                                                color={applyStyleByMode({
                                                    styleOld: '#fff',
                                                    theme: ThemeMode?.mode,
                                                    light: DARK_STEEL_GREY,
                                                    dark: BACKGROUND_BLUE
                                                })}
                                            />
                                        )}
                                    </IconButton>
                                )}
                                {collapseButton && (
                                    <CollapseButton
                                        aria-label='settings'
                                        onClick={changeCollapsed}
                                        $collapsed={collapsed as boolean}
                                        data-testid={$testidCollapseButton || 'Widget-CollapseOrUncollapse-Button'}
                                    >
                                        <Tooltip title={`${collapsed ? 'Uncollapse' : 'Collapse'} Widget`} arrow>
                                            <ArrowUpIcon />
                                        </Tooltip>
                                    </CollapseButton>
                                )}
                                {actionButtonsHeader(changeFullscreen, fullscreen, title, fullScreenButton)}
                            </>
                        }
                        title={loading ? translate('t.loading') : title}
                    />
                </CustomCardHeaderContainer>
                <CardContentCustom
                    onScroll={(event) => {
                        actionScroll && actionScroll(event);
                    }}
                >
                    {createContent(
                        content || children,
                        loading,
                        height,
                        collapsed,
                        collapseButton,
                        fullscreen ? innerHeight : $contentHeight,
                        fullscreen ? 'px' : unit
                    )}
                </CardContentCustom>
            </CustomCard>
        </UiWidgetContainer>
    );
};
