import React from 'react';
import { useRecoilState } from 'recoil';
import { UiTable3HeaderCheckboxViewContent } from './UiTable3HeaderCheckbox.style';
import { UiTable3HeaderCheckboxProps } from './UiTable3HeaderCheckbox.type';
import { Checkbox } from '@mui/material';
import { SelectRow, SelectedRowDispatcher, SelectedRowEnum } from '../UiTable3/TableAtom';

const UiTable3HeaderCheckboxContent: React.FC<UiTable3HeaderCheckboxProps> = (): JSX.Element => {
    const [selectRow, setSelectRow] = useRecoilState(SelectRow);
    const selectRowDispatch = SelectedRowDispatcher(selectRow, setSelectRow);

    return (
        <UiTable3HeaderCheckboxViewContent data-testid='HeaderCheckbox'>
            <Checkbox
                checked={selectRow.selectedAll}
                onChange={(e) => {
                    selectRowDispatch({
                        type: SelectedRowEnum.SET_SELECT_ALL,
                        payload: e.target.checked
                    });
                }}
            />
        </UiTable3HeaderCheckboxViewContent>
    );
};

export default UiTable3HeaderCheckboxContent;
