import React, { useCallback } from 'react';
import { WheelDropDownViewContent } from './WheelDropDown.style';
import { WheelDropDownViewProps } from './WheelDropDown.type';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import DropDown from '../../Ui/Components/UiDropDown';
import WheelModel from '../../../models/Wheel.type';

export const WheelDropDownContent: React.FC<WheelDropDownViewProps> = ({
    value,
    error,
    getValue,
    defaultValue,
    wheels,
    wheelLoading,
    testid,
    nameAsId,
    disabled,
    customLabel
}): JSX.Element => {
    const { t: translate } = useTranslation();

    const WheelDropDown = useCallback(() => {
        return (
            <DropDown
                options={
                    wheels?.map((wheels: WheelModel) => {
                        return {
                            id: nameAsId ? wheels.customPosition : wheels.id,
                            name: wheels.customPosition
                        };
                    }) ?? []
                }
                inputName='wheel'
                label={customLabel ?? translate('t.wheel')}
                value={value}
                error={error}
                testid={testid}
                disabled={disabled || wheelLoading}
                getValue={getValue}
                defaultValue={defaultValue}
            />
        );
    }, [value, wheels]);

    return (
        <WheelDropDownViewContent data-testid='WheelDropDownContent'>
            <WheelDropDown />
        </WheelDropDownViewContent>
    );
};
