import { atom } from 'recoil';
import { VehicleForCodeBook } from '../../../../models/Vehicle.type';
import { DateTime } from 'luxon';

export type DropDownProps = {
    vehicle: VehicleForCodeBook;
    dateFrom: DateTime;
    dateTo: DateTime;
    wheelPosition?: number;
    areaEntries?: number;
    loaded?: number;
    operatorId?: number | null;
    granularity?: GranularityType;
};

export type GranularityType = null | 1 | 5 | 10 | 15;

export const TopBarAtom = atom<DropDownProps>({
    key: 'TopBarAtom',
    default: {
        vehicle: {
            name: '',
            icon: '',
            active: false,
            id: -1
        },
        dateFrom: DateTime.local().minus({ days: 1 }),
        dateTo: DateTime.local(),
        loaded: -1
    }
});
