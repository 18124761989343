import React, { useEffect } from 'react';
import {
    CustomDateTimePicker,
    CustomModalDialog,
    UiDatePickerContent as UiDatePickerContentEl
} from './UiDatePicker.style';
import { UiDatePickerProps } from './UiDatePicker.type';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useRecoilValue } from 'recoil';
import { DateTimeMask, Is12HrsFormat, GetDateMask } from 'helpers/Converters';
import { useTranslation } from 'react-i18next';
import useConverter from '../../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField } from '@mui/material';
import { SIZE_INPUT } from '../../variables';

import { firstCapital, snakeToCamel } from 'helpers/converter/text';
import HelperText from '../../../Mixs/HelperText';
import DateTimeDialogSelector from './Components/DateTimeDialogSelector/DateTimeDialogSelector';
import { BACKGROUND_BLUE } from '../../colors';

const UiDatePickerContent: React.FC<UiDatePickerProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const dateTimeMask = useRecoilValue(DateTimeMask);
    const getDateMask = useRecoilValue(GetDateMask);
    const [openDate, setOpenDate] = React.useState(false);
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const { dateTimeFormat } = useConverter();
    const [dateValue, setDateValue] = React.useState<DateTime | null>(
        props.allowEmpty
            ? null
            : (typeof props.value === 'string' ? DateTime.fromISO(props.value) : props.value) || DateTime.local()
    );
    const [dateValueStaticDatePicker, setDateValueStaticDatePicker] = React.useState<DateTime | null>(dateValue);

    useEffect(() => {
        props.value && setDateValue(DateTime.fromISO(props.value));
    }, [props.value]);

    useEffect(() => {
        dateValue && setDateValueStaticDatePicker(dateValue);
    }, [dateValue]);

    useEffect(() => {
        return () => {
            setDateValue(null);
            setDateValueStaticDatePicker(null);
        };
    }, []);

    return (
        <UiDatePickerContentEl data-testid='UiDatePickerContent' fullWidth={props.fullWidth}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <CustomDateTimePicker
                    mask={props.onlyDate ? getDateMask : dateTimeMask}
                    inputFormat={dateTimeFormat(props.onlyDate ? 'date' : props.inputFormat || 'dateTime', false)}
                    ampm={is12HrsFormat}
                    label={props.label || translate('t.date')}
                    value={dateValue}
                    disabled={props.disabled}
                    open={false}
                    onOpen={() => {
                        setOpenDate(true);
                        (dateValueStaticDatePicker === null || dateValueStaticDatePicker?.invalid) &&
                            setDateValueStaticDatePicker(DateTime.local());
                    }}
                    onChange={(newDate: DateTime): void => {
                        if (newDate == null) {
                            setDateValue('');
                            props.onDateChange && props.onDateChange(null);
                        } else {
                            setDateValue(newDate);
                            props.onDateChange && props.onDateChange(newDate);
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant='outlined'
                            size={SIZE_INPUT}
                            {...params}
                            margin='normal'
                            id={`UiDatePicker${Math.ceil(Math.random() * 1000000000000000000)}`}
                            style={{ margin: '0 0 25px 0 !important' }}
                            data-testid={`UiDatePicker${
                                props.testId ||
                                firstCapital(snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_')))
                            }`}
                            fullWidth={props.fullWidth}
                            onFocus={() => props.onFocus && props.onFocus()}
                            onBlur={() => props.onBlur && props.onBlur()}
                            helperText={
                                <HelperText
                                    data-testid={`error$${
                                        props.testId ||
                                        firstCapital(
                                            snakeToCamel((props.label || translate('t.date')).replaceAll(' ', '_'))
                                        )
                                    }`}
                                    error={params?.inputProps?.value === '' && props.allowEmpty ? false : params.error}
                                    text={translate('t.the_date_format_is_invalid')}
                                />
                            }
                            error={
                                params?.inputProps?.value === '' && props.allowEmpty
                                    ? false
                                    : props.error || params.error
                            }
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                ...params.inputProps,
                                placeholder: `${dateTimeFormat('date', false).toUpperCase()} ${
                                    !props.onlyDate || props.inputFormat === 'dateTime' || props.inputFormat === 'time'
                                        ? dateTimeFormat('time', false)
                                        : ''
                                }`,
                                style: { width: '100%' }
                            }}
                        />
                    )}
                    $noMargin={props.noMargin}
                />

                <CustomModalDialog
                    open={openDate}
                    DialogProps={{
                        PaperProps: {
                            sx: {
                                maxWidth: props.onlyDate ? '120px' : '150px',
                                width: props.onlyDate ? '120px' : '150px',
                                maxHeight: '700px',
                                '@media (min-width: 768px)': {
                                    maxWidth: props.onlyDate ? '300px' : '550px',
                                    width: props.onlyDate ? '300px' : '550px',
                                    height: '415px',
                                    '.MuiDialogActions-root': {
                                        height: '0px',
                                        button: {
                                            bottom: '20px',
                                            height: '35px',
                                            padding: '0px 8px 0px 8px'
                                        }
                                    }
                                },
                                '&& .MuiDialogActions-root': {
                                    button: {
                                        border: `1px solid ${BACKGROUND_BLUE}`,
                                        color: BACKGROUND_BLUE
                                    },
                                    'button:nth-child(2)': {
                                        border: `1px solid ${BACKGROUND_BLUE} !important`,
                                        backgroundColor: `${BACKGROUND_BLUE} !important`,
                                        color: 'white'
                                    }
                                }
                            }
                        }
                    }}
                    onCancel={() => {
                        setOpenDate(false);
                        setDateValueStaticDatePicker(dateValue.invalid ? DateTime.local() : dateValue);
                    }}
                    onSetToday={() => {}}
                    onClear={() => {}}
                    onDismiss={() => setOpenDate(false)}
                    onAccept={() => {
                        if (!dateValueStaticDatePicker.invalid) {
                            setDateValue(dateValueStaticDatePicker);
                            props.onDateChange && props.onDateChange(dateValueStaticDatePicker);
                        }

                        setOpenDate(false);
                    }}
                >
                    <DateTimeDialogSelector
                        dateValueStaticDatePicker={dateValueStaticDatePicker}
                        setDateValueStaticDatePicker={setDateValueStaticDatePicker}
                        onlyDate={props.onlyDate}
                    />
                </CustomModalDialog>
            </LocalizationProvider>
        </UiDatePickerContentEl>
    );
};

export default UiDatePickerContent;
