import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import {
    ActionButton,
    ActionTableCell,
    CustomTableRow,
    EmptyTable,
    TableBodyEl,
    TableCellEl
} from './UiTable3Body.style';
import { UiTable3BodyProps } from './UiTable3Body.type';
import UiTable3RowCheckbox from '../UiTable3RowCheckbox';
import SettingsIcon from '@mui/icons-material/Settings';
import Menu from '../../Ui/Components/UiMenu';
import { TableAtom } from '../../../states/component/Table';
import { useTranslation } from 'react-i18next';

const UiTable3BodyContent: React.FC<UiTable3BodyProps> = (props): JSX.Element => {
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));
    const { t: translate } = useTranslation();
    const TableBody = useCallback(() => {
        return (
            <>
                {!props.rows.length && (
                    <CustomTableRow>
                        <EmptyTable>{translate('t.there_no_data')}</EmptyTable>
                    </CustomTableRow>
                )}
                {props.rows.map((row, rowIndex) => {
                    props.prepareRow(row);
                    return (
                        <React.Fragment key={rowIndex}>
                            <CustomTableRow {...row.getRowProps()} data-testid={`bodyRow${rowIndex}`}>
                                {row.cells.map((cell, cellIndex) => {
                                    return (
                                        <React.Fragment key={cellIndex}>
                                            {cellIndex === 0 && props.useColumnCheckbox && (
                                                <UiTable3RowCheckbox
                                                    rowData={cell.row.original}
                                                    columnIndex={cellIndex}
                                                    rowIndex={rowIndex}
                                                    getCellProps={cell.getCellProps}
                                                />
                                            )}
                                            {props.useColumnAction?.position === cellIndex && cellIndex === 0 && (
                                                <React.Fragment key={`rowActionRow${rowIndex}`}>
                                                    <ActionTableCell
                                                        {...cell.getCellProps()}
                                                        data-testid={`rowActionRow${rowIndex}`}
                                                    >
                                                        <Menu
                                                            items={
                                                                props.rowActionBtns &&
                                                                props.rowActionBtns(cell.row.original)
                                                            }
                                                            rootEl={
                                                                <ActionButton
                                                                    aria-controls='simple-menu'
                                                                    aria-haspopup='true'
                                                                >
                                                                    <SettingsIcon />
                                                                </ActionButton>
                                                            }
                                                        />
                                                    </ActionTableCell>
                                                </React.Fragment>
                                            )}
                                            <TableCellEl
                                                {...cell.getCellProps()}
                                                data-testid={`cellColumn${cellIndex}Row${rowIndex}`}
                                            >
                                                {cell.render('Cell')}
                                            </TableCellEl>
                                            {props.useColumnAction?.position === cellIndex && cellIndex !== 0 && (
                                                <React.Fragment key={`rowActionRow${rowIndex}`}>
                                                    <ActionTableCell
                                                        {...cell.getCellProps()}
                                                        data-testid={`rowActionRow${rowIndex}`}
                                                    >
                                                        <Menu
                                                            items={
                                                                props.rowActionBtns &&
                                                                props.rowActionBtns(cell.row.original)
                                                            }
                                                            rootEl={
                                                                <ActionButton
                                                                    aria-controls='simple-menu'
                                                                    aria-haspopup='true'
                                                                >
                                                                    <SettingsIcon />
                                                                </ActionButton>
                                                            }
                                                        />
                                                    </ActionTableCell>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </CustomTableRow>
                        </React.Fragment>
                    );
                })}
            </>
        );
    }, [props.rows, props.prepareRow]);

    return (
        <TableBodyEl
            rowCount={tableState.queryPageSize}
            data-testid='TableBody'
            className='main-table-body'
            customHeight={props.bodyHeight}
            {...props.getTableBodyProps()}
        >
            <TableBody />
        </TableBodyEl>
    );
};

export default UiTable3BodyContent;
