import React, { useEffect } from 'react';
import { ExcessiveAccContent } from './ExcessiveAcc.view';
import { ExcessiveAccProps } from './ExcessiveAcc.type';
import { MapAction } from '../../states/global/Map';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const ExcessiveAcc: React.FC<ExcessiveAccProps> = (): JSX.Element => {
    const resetMapState = useResetRecoilState(MapAction);
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tracking-tab' });

        return () => {
            resetMapState();
            resetPageHelpButton();
        };
    }, []);

    return <ExcessiveAccContent data-testid={'ExcessiveAcc-testid'} />;
};

export default Wrapper(ExcessiveAcc);
