import React from 'react';
import { NewHubsExtendedRowContentType } from './NewHubsExtendedRow.type';
import { Grid } from '@mui/material';
import { Revision } from './Revision';
import {
    ButtonWithoutLeftMargin,
    IdentifyDiv,
    LeftBox,
    NewHubsExtendedRowStyle,
    SensorTypeDiv,
    SetSensorTypesRow,
    Text
} from './NewHubsExtendedRow.style';
import UiButton from '../../Ui/Components/UiButton';
import UiCheckbox from '../../Ui/Components/UiCheckbox';
import { useTranslation } from 'react-i18next';
import { SensorTypeNames } from './NewHubsExtendedRow.reducer';
import NewHubsTestResultTable from '../NewHubsTestResultTable';
import { VARIANT_BUTTON } from '../../Ui/variables';

export const NewHubsExtendedRowContent: React.FC<NewHubsExtendedRowContentType> = ({
    hubData,
    mutate,
    dispatch,
    handleSetSensorType,
    revisionCodebook
}): JSX.Element => {
    const { t: translate } = useTranslation();

    return (
        <NewHubsExtendedRowStyle>
            <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    <LeftBox>
                        <IdentifyDiv>
                            <UiButton
                                testid='identify-button'
                                skin='success'
                                color='primary'
                                variant={VARIANT_BUTTON}
                                onClick={() => {
                                    mutate(null);
                                }}
                            >
                                {translate('t.identify')}
                            </UiButton>
                        </IdentifyDiv>
                        <Revision hubData={hubData} revisionCodebook={revisionCodebook} />
                    </LeftBox>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={9}>
                    <NewHubsTestResultTable hubData={hubData} />
                </Grid>
            </Grid>
        </NewHubsExtendedRowStyle>
    );
};
