import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { SeverityRankGraphsViewContent } from './SeverityRankGraphs.style';
import { SeverityRankGraphsViewProps } from './SeverityRankGraphs.type';
import { SeverityWheels } from '../../../pages/SeverityRank/atom';
import UiCircularProgress from '../../Ui/Components/UiCircularProgress';
import SeverityRankGraph from '../SeverityRankGraph';
import { SelectedVehicle } from '../../../states/global/Statistics';

export const SeverityRankGraphsContent: React.FC<SeverityRankGraphsViewProps> = (): JSX.Element => {
    const severityWheels = useRecoilValue(SeverityWheels);
    const selectedVehicle = useRecoilValue(SelectedVehicle);

    const generateGraphs = useCallback(() => {
        if (!severityWheels.isLoading) {
            let graphs: JSX.Element[] = [];
            for (let i = 0; i < severityWheels.wheels.length; i++) {
                graphs.push(
                    <SeverityRankGraph
                        tyreId={severityWheels.wheels[i].wheel.tyreId || null}
                        tyreCustomPosition={severityWheels.wheels[i].wheel.customPosition}
                    />
                );
            }
            return graphs;
        }
        return <></>;
    }, [severityWheels, selectedVehicle.id]);

    return (
        <SeverityRankGraphsViewContent data-testid='SeverityRankGraphsContent'>
            {severityWheels.isLoading && <UiCircularProgress />}
            {!severityWheels.isLoading && generateGraphs()}
        </SeverityRankGraphsViewContent>
    );
};
