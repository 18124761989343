import { Box, TextField, styled } from '@mui/material';

export const UiTable3FilterCellViewContent = styled(Box)({});

export const FilterInput = styled(TextField)({
    margin: '0 !important',
    padding: '0 !important'
});

export const LegendTable = styled('table')`
    td:first-child {
        width: 15px;
    }
    td:last-child {
        padding-left: 5px;
    }
`;
