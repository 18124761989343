import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import SensorFaultyReportVerifiedStatusApi from 'api/SensorFaultyReportVerifiedStatus';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FaultySensorReporterState } from 'states/component/FaultySensorReporter';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import { UserInfo } from 'states/global/User';
import { useTranslation } from 'react-i18next';
import useConverter from '../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { applyStyleByMode } from 'helpers';
import { Theme } from 'states/global/Theme';
import { WHITE } from 'components/Ui/colors';
import UiIcon from 'components/Ui/Components/UiIcon/UiIcon';
import { TextField, OutlinedInput, InputLabel, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FaultySensorReportsContent } from './FaultySensorReports.view';
import { SensorFaultyReportVerifiedStatusCodebook } from 'models/SensorFaultyReportVerifiedStatus.type';
import { Column } from 'models/Table.type';
import { TableItem } from 'models/SensorFaultyReport.type';
import { Wrapper } from 'helpers/wrapper';

const FaultySensorReports: React.FC = (): JSX.Element => {
    const [verifiedStatusData, setVerifiedStatusData] = useState<SensorFaultyReportVerifiedStatusCodebook['codeBook']>(
        []
    );
    const SensorFaultyReportVerifiedStatus = new SensorFaultyReportVerifiedStatusApi();
    const setFaultySensorReporterState = useSetRecoilState(FaultySensorReporterState);
    const refreshTable = useRecoilValue(RefreshTable('FaultySensorReports-RefreshTableAtom'));
    const { fromUTCToTimezone } = useConverter();
    const userInfo = useRecoilValue(UserInfo);
    const { t: translate } = useTranslation();
    const { dateTimeFormat } = useConverter();
    const ThemeMode = useRecoilValue(Theme);

    const { refetch: getVerifiedStatusData } = useQuery(
        ['getVerifiedStatusData-FaultySensorReports'],
        () => SensorFaultyReportVerifiedStatus.get({}),
        {
            enabled: false,
            onSuccess: (dataOnSuccess: SensorFaultyReportVerifiedStatusCodebook) =>
                setVerifiedStatusData(dataOnSuccess.codeBook)
        }
    );

    const columns: Column<TableItem>[] = [
        {
            Header: '',
            accessor: 'id',
            width: 50,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return (
                    <IconButton
                        size='small'
                        data-testid={`Action-FaultySensorReport-Button-${props.row.original.id}`}
                        onClick={() => {
                            setFaultySensorReporterState({
                                sensorId: props.row.original.sensor?.id,
                                vehicleId: props.row.original.sensor?.wheel?.vehicle?.id || 0,
                                sensorName: props.row.original.sensor?.serialNumberHex,
                                internalOnVehicle: true,
                                vehicleName: props.row.original.sensor?.wheel?.vehicle?.name,
                                isExternal: props.row.original.sensor?.sensorType?.id === 2,
                                reportDetailId: props.value,
                                reportDateFrom: props.row.original.dateFrom,
                                reportDateTo: props.row.original.dateTo,
                                showOnlyGraph: true,
                                notValidate: !props.row.original.verifiedAt,
                                displayAllHubs: true,
                                onVerified: refreshTable
                            });
                        }}
                    >
                        <UiIcon
                            icon={['fas', 'pen-to-square']}
                            size='1x'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: undefined,
                                dark: WHITE
                            })}
                        />
                    </IconButton>
                );
            }
        },
        {
            Header: translate('t.sensor'),
            accessor: (row: TableItem) => row.sensor.serialNumberHex,
            id: 'sensor.serialNumberHex',
            width: 100,
            Cell: (props) => (
                <>
                    {props.row.original.sensor?.sensorType?.id === 2 && (
                        <UiIcon
                            icon={['fas', 'up-right-from-square']}
                            size='sm'
                            fixedWidth
                            color={applyStyleByMode({
                                styleOld: '#000',
                                theme: ThemeMode?.mode,
                                light: '#000',
                                dark: WHITE
                            })}
                        />
                    )}{' '}
                    {props.value}
                </>
            )
        },
        {
            Header: translate('t.problem'),
            accessor: 'problem',
            Cell: (props) => <>{translate(`t.${props.value}`)}</>,
            disableFilters: true,
            width: 100
        },
        {
            Header: translate('t.date_from'),
            accessor: 'dateFrom',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.date_from')}
                            value={state.filters.filter((filter) => filter.id === 'dateFrom')[0]?.value.value || null}
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.date_from'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('dateFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='date-from'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.date_to'),
            accessor: 'dateTo',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            value={state.filters.filter((filter) => filter.id === 'dateTo')[0]?.value.value || null}
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.date_to')}
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.date_to'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('dateTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='date-to'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.disabled_reporting'),
            accessor: 'action',
            width: 80,
            Cell: (props) => {
                return <>{props.value ? translate('t.yes') : translate('t.no')}</>;
            },
            Filter: ({ setFilter, column }) => {
                return (
                    <FormControl
                        variant='outlined'
                        size='small'
                        style={{ width: '100%', marginTop: '5px', marginBottom: '10px' }}
                    >
                        <InputLabel htmlFor='disabled-action' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            variant='outlined'
                            label={<>{column.Header}</>}
                            value={column.filterValue?.value ?? ''}
                            onChange={(e) => {
                                const value = !e.target.value
                                    ? undefined
                                    : { name: column.Header, value: e.target.value };
                                setFilter(column.id, value);
                            }}
                            inputProps={{
                                name: 'disabled-action',
                                id: 'disabled-action'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            <MenuItem value={''} data-test='test'>
                                {translate('t.all')}
                            </MenuItem>
                            <MenuItem value={`1;${translate('t.yes')}`} data-test='yes test'>
                                {translate('t.yes')}
                            </MenuItem>
                            <MenuItem value={`0;${translate('t.no')}`}>{translate('t.no')}</MenuItem>
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.reported_by'),
            accessor: (row) => row.user.lastname,
            id: 'userLastname',
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value}</>;
            }
        },

        {
            Header: translate('t.reported_at'),
            accessor: 'createdAt',
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.reported_from')}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtFrom')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.reported_from'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='report-at-from'
                                    size={'small'}
                                    sx={{ marginTop: '10px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />

                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.reported_to')}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.reported_from'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='report-at-to'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.first_activation'),
            accessor: 'firstActivation',
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.first_activation')} ${translate('t.from')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'firstActivationFrom')[0]?.value.value ||
                                null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.first_activation')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('firstActivationFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='report-at-from'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />

                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.first_activation')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'firstActivationTo')[0]?.value.value ||
                                null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.first_activation')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('firstActivationTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='report-at-to'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.last_transmission'),
            accessor: (row) => row.sensor.sensorInfo.measuredAt,
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.last_transmission')} ${translate('t.from')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'lastTransmissionFrom')[0]?.value
                                    .value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.last_transmission')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('lastTransmissionFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='last_transmission-at-from'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />

                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.last_transmission')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'lastTransmissionTo')[0]?.value.value ||
                                null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.last_transmission')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('lastTransmissionTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='last_transmission-at-to'
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.vehicle_name'),
            accessor: (row) => row.sensor.wheel?.vehicle.name,
            width: 100,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },

        {
            Header: translate('t.wheel_position'),
            accessor: (row) => row.sensor.wheel?.customPosition,
            width: 100,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },
        {
            Header: translate('t.mounted_status'),
            accessor: (row) => row.sensor.lastMovement?.removalReason,
            disableFilters: true,
            disableSortBy: true,
            width: 100,
            Cell: (props) => {
                return <>{props.value ?? '-'}</>;
            }
        },
        {
            Header: translate('t.report_validity'),
            accessor: (row) => row.verifiedStatus.name,
            id: 'verifiedStatus.name',
            width: 80,
            Cell: (props) => {
                return <>{props.value ? translate(`t.${props.value.toLowerCase()}`) : ''}</>;
            },
            Filter: ({ column, setFilter }) => {
                return (
                    <FormControl variant='outlined' size='small' style={{ width: '100%', marginTop: '5px' }}>
                        <InputLabel htmlFor='verified-status-native-simple' shrink>
                            <>{column.Header}</>
                        </InputLabel>
                        <Select
                            variant='outlined'
                            label={<>{column.Header}</>}
                            value={column.filterValue?.value || ''}
                            onChange={(e) => {
                                let value: object | undefined = undefined;
                                const currentValue = e.target.value;
                                if (currentValue) {
                                    value = { name: column.Header, value: e.target.value };
                                }
                                setFilter(column.id, value);
                            }}
                            inputProps={{
                                name: 'verified-status',
                                id: 'verified-status-native-simple'
                            }}
                            input={<OutlinedInput notched label={<>{column.Header}</>} />}
                        >
                            <MenuItem value={''}>{translate('t.all')}</MenuItem>
                            {verifiedStatusData.map((verifiedStatus, index) => (
                                <MenuItem
                                    value={`${verifiedStatus.id};${translate(
                                        `t.${verifiedStatus.name.toLowerCase()}`
                                    )}`}
                                    key={index}
                                >
                                    {translate(`t.${verifiedStatus.name.toLowerCase()}`)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
        },
        {
            Header: translate('t.validate_at'),
            accessor: 'verifiedAt',
            width: 120,
            Cell: (props) => <>{fromUTCToTimezone(props.value, false)}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.validate_from')}
                            value={
                                state.filters.filter((filter) => filter.id === 'verifiedAtFrom')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.validate_from'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('verifiedAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    id='validated-at-from'
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    sx={{ marginTop: '10px' }}
                                    name='validatedFrom'
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={translate('t.validate_to')}
                            value={
                                state.filters.filter((filter) => filter.id === 'verifiedAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: translate('t.validate_to'),
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('verifiedAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    id='validated-at-to'
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    margin='normal'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    name='validatedTo'
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'verifiedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'verifiedAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'firstActivationFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            accessor: 'firstActivationTo'
        },
        {
            Header: '',
            disableFilters: true,
            width: 0,
            disableSortBy: true,
            accessor: 'lastActivationFrom'
        },
        {
            Header: '',
            width: 0,
            disableFilters: true,
            disableSortBy: true,
            accessor: 'lastActivationTo'
        }
    ];

    useEffect(() => {
        getVerifiedStatusData();
    }, []);

    return <FaultySensorReportsContent columns={columns} data-testid='FaultySensorReports-testid' />;
};

export default Wrapper(FaultySensorReports);
