import LoadingButton from '@mui/lab/LoadingButton';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { useTheme } from '@mui/material';

import {
    ERROR,
    ERROR_DARK,
    LINK,
    LINK_DARK,
    PRIMARY,
    PRIMARY_DARK,
    SUCCESS,
    SUCCESSDARK,
    THIRD,
    THIRD_DARK
} from '../../colors';

const getColor = (skin?: string): string => {
    switch (skin?.toLowerCase()) {
        case 'primary':
            return PRIMARY;
        case 'success':
            return SUCCESS;
        case 'error':
            return ERROR;
        case 'link':
            return LINK;
        case 'third':
            return THIRD;
        default:
            return skin || PRIMARY;
    }
};

const getHoverColor = (skin?: string): string => {
    switch (skin?.toLowerCase()) {
        case 'primary':
            return PRIMARY_DARK;
        case 'success':
            return SUCCESSDARK;
        case 'error':
            return ERROR_DARK;
        case 'link':
            return LINK_DARK;
        case 'third':
            return THIRD_DARK;
        default:
            return skin || PRIMARY_DARK;
    }
};

export const StyledButton = styled(LoadingButton)<{ skin?: string; textcolor?: string }>`
    &.MuiButton-textSecondary,
    &.MuiButton-outlinedSecondary {
        ${() => {
            return applyStyleByMode({
                theme: useTheme().palette.mode,
                light: `color: ${PRIMARY};`
            });
        }}
    }

    &.MuiButton-root {
        margin: 0px 5px;
        padding: 6px;
        ${(props) => props.textcolor && `color: ${props.textcolor} !important;`}
        :not(.Mui-disabled) {
            box-shadow: none;
        }
    }
`;
