import React, { useCallback } from 'react';
import { UiTable3Content } from './UiTable3.view';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TableAtom, TableRefreshAtom } from '../../../states/component/Table';
import { QueryFn } from './TableAtom';
import { DefaultRequestPropsType, Paginator } from 'helpers/api/type';
import { Wrapper } from 'helpers/wrapper';
import { UserInfo } from 'states/global/User';

const UiTable3 = <
    T extends (props: DefaultRequestPropsType['criteria']) => Promise<Paginator<T, unknown>>,
    K extends ({ ...args }) => ReturnType<K>
>(
    props
): JSX.Element => {
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const refreshTable = useRecoilValue(TableRefreshAtom);
    const setQueryFn = useSetRecoilState(QueryFn);
    const userInfo = useRecoilValue(UserInfo);

    const { data, isLoading, isFetching, refetch } = useQuery(
        [
            `${props.queryKey}-TableQuery`,
            tableState.queryPageIndex,
            tableState.queryPageSize,
            tableState.queryPageSortBy,
            tableState.queryPageFilter,
            refreshTable,
            userInfo.user?.customer.id
        ],
        () => props.fetchFn({ ...tableState, additionalProps: props.additionalProps }),
        {
            refetchInterval: props.refresh ?? false,
            enabled: true, // check enable
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setTableState((current) => ({ ...current, totalCount: data.paginator.totalCount }));
            }
        }
    );

    const defineGlobalQueryFn = useCallback(() => {
        setQueryFn((current) => ({ ...current, refetch }));
    }, [refetch]);

    defineGlobalQueryFn();

    return (
        <UiTable3Content
            {...props}
            data={data?.items || []}
            pageCount={data?.paginator?.pageCount}
            paginator={data?.paginator}
            isLoadingFetching={isLoading || isFetching}
            isLoading={isLoading}
            data-testid='UiTable3-testid'
        />
    );
};

export default Wrapper(UiTable3);
