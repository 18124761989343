import React from 'react';
import { PopoverListContentProps } from './PopoverListContent.type';
import { PopoverContentContent } from './PopoverListContent.view';
import { Sensor } from '../../models/Sensors.type';
import { Wrapper } from 'helpers/wrapper';
// import module

const PopoverContent: React.FC<PopoverListContentProps> = (props): JSX.Element => {
    return <PopoverContentContent data-testid='PopoverContent-testid' {...props} />;
};

export default Wrapper(PopoverContent);
