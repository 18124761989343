import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { AddInputCover, ViewContent } from './AddCustomerSettings.style';
import { AddCustomerSettingsProps } from './AddCustomerSettings.type';
import { useTranslation } from 'react-i18next';
import {
    CustomerSettingEditModeAtom,
    SelectedCustomerSetting,
    SelectedCustomerSettingValue,
    SelectedCustomerSettingsType
} from 'states/component/CustomerSettings';
import { CRUD } from 'variables';
import CustomerSettingInputs from '../CustomeSettingInputs';
import { Grid, TextField } from '@mui/material';
import { AddNewSettingBtn, SettingsAutocomplete, WarningMsg } from '../CustomerSettings/CustomerSettings.style';
import { ERROR, SUCCESS } from 'components/Ui/colors';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import { CustomerSettingType } from 'models/CustomerSettings.type';
import { Wrapper } from 'helpers/wrapper';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';

const AddCustomerSettings: React.FC<AddCustomerSettingsProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [selectedSetting, setSelectedSetting] = useRecoilState(SelectedCustomerSetting);
    const [selectedSettingValue, setSelectedSettingValue] = useRecoilState(SelectedCustomerSettingValue);
    const [editMode, setEditMode] = useRecoilState(CustomerSettingEditModeAtom);

    useEffect(() => {
        if (selectedSetting?.actionType === CRUD.ADD) {
            let value = selectedSetting?.customerSetting?.defaultValue;
            if (selectedSetting?.customerSetting?.type === CustomerSettingType.MAP_AREA) {
                value = (value || [0, 0, 0, 0]) as [];
            }
            setSelectedSettingValue(value);
            setEditMode([]);
        }
    }, [selectedSetting?.customerSetting]);

    return !editMode.length ? (
        <>
            {props.patchIsLoading ? (
                <UiLoadingPage />
            ) : (
                <ViewContent>
                    <Grid
                        container
                        xs={12}
                        md={12}
                        lg={12}
                        data-testid='AddCustomerSettings-testid'
                        height={52}
                        display={'contents'}
                    >
                        <Grid item md={6} lg={6} sm={6} xs={5}>
                            <SettingsAutocomplete
                                fullWidth
                                id='customer'
                                size='small'
                                style={{ width: 'auto' }}
                                value={selectedSetting}
                                freeSolo={false}
                                options={
                                    props.customerSettings?.settings
                                        .filter((setting) => !setting.customValue)
                                        .map((setting) => ({
                                            customerSetting: setting,
                                            actionType: CRUD.ADD
                                        })) || []
                                }
                                getOptionLabel={(option) => {
                                    const opt = option as SelectedCustomerSettingsType;
                                    return option
                                        ? `${translate(`t.${opt?.customerSetting?.key}`)} ${
                                              opt?.customerSetting?.deprecated ? `(${translate('t.deprecated')})` : ''
                                          }`
                                        : '';
                                }}
                                onChange={(event, newValue) => {
                                    setSelectedSetting(newValue as SelectedCustomerSettingsType);
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            label={translate('t.unused_settings')}
                                            variant='outlined'
                                            size='small'
                                            key={'unsused_customer_settings'}
                                            name={'unsused_customer_settings'}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            data-testid='AddCustomerUnusedSettingsInput'
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item md={4} lg={4} sm={4} xs={4}>
                            {selectedSetting?.actionType === CRUD.ADD && (
                                <AddInputCover>
                                    <CustomerSettingInputs
                                        actionType={CRUD.ADD}
                                        value={selectedSettingValue}
                                        setValue={setSelectedSettingValue}
                                        settingOptions={selectedSetting?.customerSetting}
                                        type={selectedSetting?.customerSetting?.type}
                                    />
                                </AddInputCover>
                            )}
                        </Grid>
                        <Grid item md={2} lg={2} sm={2} xs={3} sx={{ pt: { xs: 1, sm: 1 } }}>
                            <AddNewSettingBtn
                                disabled={!selectedSetting?.customerSetting}
                                skin={SUCCESS}
                                color='primary'
                                variant='contained'
                                testid='addCustomerSettingButton'
                                onClick={() =>
                                    props.customerSettingsPopup(
                                        CRUD.ADD,
                                        selectedSettingValue,
                                        selectedSetting?.customerSetting
                                    )
                                }
                            >
                                {translate('t.add')}
                            </AddNewSettingBtn>
                        </Grid>
                    </Grid>
                </ViewContent>
            )}
        </>
    ) : (
        <WarningMsg severity='warning' visible={true}>
            <>{`${translate('p.you_have_unsaved_setting')} ${translate(
                `t.${selectedSetting?.customerSetting?.key}`
            )}`}</>{' '}
            <UiButton
                skin={ERROR}
                size='small'
                color='error'
                variant='contained'
                testid={`${selectedSetting?.customerSetting?.key}UnsavedCancelButton`}
                onClick={() => {
                    setEditMode([]);
                    setSelectedSetting(undefined);
                    setSelectedSettingValue(undefined);
                }}
            >
                {translate('t.cancel')}
            </UiButton>
        </WarningMsg>
    );
};

export default Wrapper(AddCustomerSettings);
