import React from 'react';
import { AdminActionContent } from './AdminAction.view';
import { AdminActionProps } from './AdminAction.type';
import { CustomDatePicker, DetailsContainer, Text, TextTitle } from './AdminAction.style';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { LoggerRequestModel } from '../../../models/LoggerRequest.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { UserInfo } from '../../../states/global/User';
import useConverter from '../../CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from '@mui/material';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

const AdminAction: React.FC<AdminActionProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const userInfo = useRecoilValue(UserInfo);
    const { fromUTCToTimezone, dateTimeFormat } = useConverter();

    const expandableContentUserActions = (props): JSX.Element => {
        return (
            <DetailsContainer>
                <TextTitle>{translate('t.parameter')}:</TextTitle>
                <Text> {props.parameter} </Text>
            </DetailsContainer>
        );
    };

    const columns: Column<LoggerRequestModel>[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableFilters: true,
            width: 50,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => (
                <div style={{ marginLeft: '10px' }}>{props.row.original.id ?? '-'}</div>
            )
        },

        {
            Header: 'URL',
            accessor: 'url',
            width: 100,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.url ?? '-'}</>
        },

        {
            Header: translate('t.parameter'),
            accessor: 'parameter',
            disableFilters: true,
            width: 130,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.parameter ?? '-'}</>
        },

        {
            Header: translate('t.method'),
            accessor: 'method',
            width: 50,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.method ?? '-'}</>
        },

        {
            Header: 'IP',
            accessor: 'ip',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.ip ?? '-'}</>
        },

        {
            Header: (
                <>
                    <FontAwesomeIcon transform='right-3' icon={faCalendar} /> {' . '} {translate('t.created_at')}{' '}
                </>
            ),
            accessor: 'createdAt',
            width: 90,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => (
                <>{fromUTCToTimezone(props.row.original.createdAt, false) ?? '-'}</>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <CustomDatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.created_at')} ${translate('t.from')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtFrom')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin='normal'
                                    id='createdaction-from'
                                    size={'small'}
                                    variant='outlined'
                                    style={{ margin: '0 5px !important' }}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <CustomDatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.created_at')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin='normal'
                                    id='createdaction-from'
                                    size={'small'}
                                    variant='outlined'
                                    style={{ margin: '0 5px !important' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.customer_name'),
            accessor: (row: LoggerRequestModel) => row.customer?.name,
            id: 'customer.name',
            width: 70,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.customer?.name ?? '-'}</>
        },
        {
            Header: translate('t.user_name'),
            accessor: (row: LoggerRequestModel) => row.user?.username,
            id: 'user.username',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: LoggerRequestModel } }) => <>{props.row.original.user?.username ?? '-'}</>
        },

        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        }
    ];

    return (
        <AdminActionContent
            columns={columns}
            data-testid={'AdminAction-testid'}
            expandableContentUserActions={expandableContentUserActions}
        />
    );
};

export default Wrapper(AdminAction);
