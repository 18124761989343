import PortalApiClient from 'helpers/api/PortalApiClient';
import {
    DeviceCommandList,
    DeviceCommandPending,
    DeviceCommandListWithPaginator,
    DeviceCommandModel,
    ExecutedDeviceCommandsTable
} from '../models/DeviceCommand.type';
import { DeviceCommandsTableResponse } from '../models/Device.type';

import { getParams } from 'helpers';

export default class DeviceCommand extends PortalApiClient<
    DeviceCommandModel | DeviceCommandListWithPaginator | DeviceCommandsTableResponse
> {
    route = 'device-command';

    getLastDeviceCommand = (deviceId): Promise<ExecutedDeviceCommandsTable> => {
        return this.getTable({
            extendUrl: `${deviceId}`,
            criteria: {
                limit: 1,
                commandId: 30
            }
        });
    };

    getAvailableDeviceCommand = async (): Promise<DeviceCommandList> => {
        return await this.get({ extendUrl: 'executable' });
    };

    getRecentPlusHistoryDeviceCommand = async (props, additionalProps): Promise<DeviceCommandsTableResponse> => {
        let results = await this.getTable({
            criteria: getParams(props),

            extendUrl: `${additionalProps.id}`
        }).then((dataOnSuccess) => {
            if (dataOnSuccess.items && dataOnSuccess.items.length < props.queryPageSize && props.queryPageIndex == 0) {
                dataOnSuccess.paginator.totalCount = dataOnSuccess.items.length;
            } else {
                dataOnSuccess.paginator.pageCount = dataOnSuccess.items.length;
                dataOnSuccess.paginator.totalCount = -1;
            }
            return dataOnSuccess;
        });

        return results;
    };

    getDeviceCommandAvailability = async (deviceId): Promise<DeviceCommandListWithPaginator> => {
        return await this.get({
            extendUrl: `${deviceId}`
        });
    };

    getRecentSplitHistoryDeviceCommand = async (): Promise<DeviceCommandList> => {
        return await this.get({ extendUrl: 'available' });
    };

    postExcuteCommand = async (props): Promise<Object> => {
        return await this.post({
            data: props.value
                ? { commandName: props.name, value: props.value, deviceIds: props.deviceIds }
                : { commandName: props.name, deviceIds: props.deviceIds }
        });
    };

    deletePendingRequest = async (props): Promise<Object> => {
        return await this.delete({
            extendUrl: `pending/${props.id}`,
            id: props.deviceId
        });
    };

    getCommandPending = async (id: string | number, deviceId: string | number): Promise<DeviceCommandPending> => {
        return await this.get({ extendUrl: `pending/${15}/${deviceId}` });
    };

    setSensorTypes = async (props): Promise<Object> => {
        return await this.post({
            data: props
        });
    };
}
