import React, { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { CustomAlertsCheckboxViewContent } from './CustomAlertsCheckbox.style';
import { CustomAlertsCheckboxProps } from './CustomAlertsCheckbox.type';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Switch } from '@mui/material';
import {
    CustomAlertsLevelCheckbox,
    CustomHighColdPressure,
    HotPressureConsts,
    PressureLevelsSelector
} from '../AlertSettings/atom';
import { AlertLevelEnum } from 'models/Alerts.type';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { GetConvertedAmbient } from 'states/global/CustomerSettings';
import { DateTime } from 'luxon';

const CustomAlertsCheckboxContent: React.FC<CustomAlertsCheckboxProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [customAlerts, setCustomAlerts] = useRecoilState(CustomAlertsLevelCheckbox);
    const hasCustomHighColdPressure = useRecoilValue(CustomHighColdPressure(undefined));
    const { fromServerToUserUnit, convertType } = useConverter();
    const setHotPressureConts = useSetRecoilState(HotPressureConsts);

    const setInitValuesHot1 = useSetRecoilState(PressureLevelsSelector(11));
    const setInitValuesHot2 = useSetRecoilState(PressureLevelsSelector(12));
    const setInitValuesHot3 = useSetRecoilState(PressureLevelsSelector(13));

    const getConvertedAmbient = useRecoilValue(GetConvertedAmbient);
    const now = DateTime.now();
    const currentMonth: number = parseInt(now.toFormat('M'));

    const ambientTemperature = useMemo(() => getConvertedAmbient(fromServerToUserUnit), [getConvertedAmbient]);

    const hotPressureConsts = useMemo(() => {
        return {
            ambientPressure: +fromServerToUserUnit({
                type: convertType.pressure,
                value: 14.7,
                fixed: 1,
                displayUnits: false
            }),
            hotPressure: +fromServerToUserUnit({
                type: convertType.pressure,
                value: 80,
                fixed: 1,
                displayUnits: false
            }),
            ambientTemperature: +fromServerToUserUnit({
                type: convertType.temperature,
                value: (ambientTemperature ? ambientTemperature.all[currentMonth - 1] : 0) as number,
                fixed: 1,
                displayUnits: false
            })
        };
    }, [fromServerToUserUnit, ambientTemperature]);

    useEffect(() => {
        setHotPressureConts({
            ambientPressure: +fromServerToUserUnit({
                type: convertType.pressure,
                value: 14.7,
                fixed: 1,
                displayUnits: false
            }),
            hotPressure: 80,
            ambientTemperature: (ambientTemperature ? ambientTemperature.all[currentMonth - 1] : 0) as number
        });
    }, []);

    useEffect(() => {
        setCustomAlerts(hasCustomHighColdPressure);
    }, [hasCustomHighColdPressure]);

    useEffect(() => {
        if (!customAlerts) {
            setInitValuesHot1((current) => ({
                ...current,
                ambientPressure: hotPressureConsts.ambientPressure,
                hotPressure: hotPressureConsts.hotPressure,
                ambientTemperature: hotPressureConsts.ambientTemperature,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
            setInitValuesHot2((current) => ({
                ...current,
                ambientPressure: hotPressureConsts.ambientPressure,
                hotPressure: hotPressureConsts.hotPressure,
                ambientTemperature: hotPressureConsts.ambientTemperature,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
            setInitValuesHot3((current) => ({
                ...current,
                ambientPressure: hotPressureConsts.ambientPressure,
                hotPressure: hotPressureConsts.hotPressure,
                ambientTemperature: hotPressureConsts.ambientTemperature,
                action: AlertLevelEnum.RECALCULATE_HOT_PRESSURE
            }));
        }
    }, [customAlerts]);

    return (
        <CustomAlertsCheckboxViewContent data-testid='CustomAlertsCheckboxContent'>
            <FormControlLabel
                control={
                    <Switch
                        data-testid={'custom-alerts-level-checkbox'}
                        onChange={(e) => {
                            setCustomAlerts(e.target.checked);
                        }}
                        checked={customAlerts}
                        name='customAlertsLevel'
                    />
                }
                label={translate('t.custom_alerts_level')}
            />
        </CustomAlertsCheckboxViewContent>
    );
};

export default CustomAlertsCheckboxContent;
