import React from 'react';
import {
    LeftActionBar,
    RightActionBar,
    TableCover,
    TableLoader,
    TableTopbar,
    UiTable3ViewContent
} from './UiTable3.style';
import Widget from '../../Ui/Components/UiWidget';
import UiTable3ExportDropdownContent from '../UiTable3ExportDropdown/UiTable3ExportDropdown';
import UiTable3Instance from '../UiTable3Instance';
import UiTable3HideColumns from '../UiTable3HideColumns';
import UiTable3RefreshBtn from '../UiTable3RefreshBtn';
import UiTable3RightActionBar from '../UiTable3RightActionBar';
import UiTable3Footer from '../UiTable3Footer/UiTable3Footer';
import { Table } from '@mui/material';
import SkeletonLoader from '../Components/UiTable2/SkeletonLoader/SkeletonLoader';

export const UiTable3Content = <K extends ({ ...args }) => ReturnType<K>>(props): JSX.Element => {
    return (
        <UiTable3ViewContent data-testid='UiTable3Content'>
            <Widget title={props.title || 'table title'} avatar={props.avatar}>
                {props.isLoading && <SkeletonLoader />}
                {!props.isLoading && (
                    <>
                        <TableTopbar>
                            <LeftActionBar>
                                <UiTable3ExportDropdownContent
                                    allowCopy={props.allowCopy}
                                    allowDownloadCSV={props.allowDownloadCSV}
                                    allowPrint={props.allowPrint}
                                    exportFn={props.exportFn}
                                    queryKey={props.queryKey}
                                    exportName={props.exportName}
                                />
                                <UiTable3RightActionBar
                                    rightActionBtns={props.leftActionBtns}
                                    queryKey={props.queryKey}
                                />
                            </LeftActionBar>
                            <RightActionBar>
                                <UiTable3RightActionBar
                                    rightActionBtns={props.rightActionBtns}
                                    queryKey={props.queryKey}
                                />
                                <UiTable3RefreshBtn />
                                <UiTable3HideColumns queryKey={props.queryKey} />
                            </RightActionBar>
                        </TableTopbar>
                        <TableCover>
                            {props.isLoadingFetching && <TableLoader data-test-id={`${props.queryKey}TableLoader`} />}
                            <UiTable3Instance
                                columns={props.columns}
                                data={props.data}
                                useColumnAction={props.useColumnAction}
                                useColumnFiltering={props.useColumnFiltering}
                                useColumnCheckbox={props.useColumnCheckbox}
                                queryKey={props.queryKey}
                                pageCount={props.pageCount}
                                rowActionBtns={props.rowActionBtns}
                                paginator={props.paginator}
                                bodyHeight={props.bodyHeight}
                            />
                            <Table>
                                <UiTable3Footer queryKey={props.queryKey} />
                            </Table>
                        </TableCover>
                    </>
                )}
            </Widget>
        </UiTable3ViewContent>
    );
};
