import React, { useEffect } from 'react';
import * as type from './AreasMap.type';
import * as view from './AreasMap.view';
import { useResetRecoilState } from 'recoil';
import { MapAction } from '../../../states/global/Map';
import { useMediaQuery, useTheme } from '@mui/material';
import { Wrapper } from 'helpers/wrapper';

const AreasMapContent: React.FC<type.AreasMapProps> = (props): JSX.Element => {
    const resetMapState = useResetRecoilState(MapAction);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xs'));
    let contentHeight = 550;

    if (!matches) {
        contentHeight = 490;
    }

    useEffect(
        () => () => {
            resetMapState();
        },
        []
    );

    return <view.AreasMapContent data-testid='AreasMapContent' contentHeight={contentHeight} {...props} />;
};

export default Wrapper(AreasMapContent);
