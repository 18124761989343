import React from 'react';
import { UiTable3RefreshBtnViewContent } from './UiTable3RefreshBtn.style';
import { UiTable3RefreshBtnProps } from './UiTable3RefreshBtn.type';
import { Refresh } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { QueryFn } from '../UiTable3/TableAtom';

const UiTable3RefreshBtnContent: React.FC<UiTable3RefreshBtnProps> = (): JSX.Element => {
    const queryFn = useRecoilValue(QueryFn);
    return (
        <UiTable3RefreshBtnViewContent data-testid='RefreshBtn' size='small'>
            <Refresh
                onClick={() => {
                    queryFn.refetch && queryFn.refetch instanceof Function && queryFn.refetch();
                }}
            />
        </UiTable3RefreshBtnViewContent>
    );
};

export default UiTable3RefreshBtnContent;
