import React from 'react';
import { useRecoilValue } from 'recoil';
import { BesRankOverviewContainer, Loading, TableCustom } from './BesRankOverview.style';
import { useTranslation } from 'react-i18next';
import { BesRankOverviewViewProps, PercentageForGraphType } from './BesRankOverview.type';
import Widget from '../../../components/Ui/Components/UiWidget';
import { faTire } from '@fortawesome/pro-solid-svg-icons';
import { TableBody, TableRow, TableCell, TableContainer, CircularProgress } from '@mui/material';
import { RANK1, RANK2, RANK3, RANKNA, WHITE } from '../../Ui/colors';
import UiCircularGraph from '../../Ui/Components/UiCircularGraph';
import { Theme, ThemeMode } from 'states/global/Theme';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import ReactGA from 'helpers/ga';
import { CustomerSettings } from 'states/global/CustomerSettings';

const gTrackOnMouseEnter = () => {
    ReactGA.event({
        category: 'BES',
        action: 'Mouse Enter',
        label: 'Mouse Enter - BES Severity Rank Overview'
    });
};

const getTableData = (data: PercentageForGraphType[], besName: string | undefined): PercentageForGraphType[] => {
    if (besName === 'CSEP') {
        return data.slice(0, 2);
    }
    return data.slice(1, 4);
};

const TableHeader: React.FC<{ besName: string | undefined }> = (props): JSX.Element => {
    if (props.besName === 'CSEP') {
        return (
            <TableRow className='bes-rank-colored'>
                <TableCell style={{ background: RANKNA }}>0</TableCell>
                <TableCell style={{ background: RANK3 }}>1</TableCell>
            </TableRow>
        );
    }
    return (
        <TableRow className='bes-rank-colored'>
            <TableCell style={{ background: RANK1 }}>1</TableCell>
            <TableCell style={{ background: RANK2 }}>2</TableCell>
            <TableCell style={{ background: RANK3 }}>3</TableCell>
        </TableRow>
    );
};

export const BesRankOverviewContent: React.FC<BesRankOverviewViewProps> = ({
    isLoading,
    createPercentageForView
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const ThemeState = useRecoilValue(Theme);
    const customerSettings = useRecoilValue(CustomerSettings);

    return (
        <BesRankOverviewContainer onMouseEnter={gTrackOnMouseEnter} data-testid='BesRankOverviewContent'>
            <Widget
                avatar={
                    <UiIcon
                        icon={faTire}
                        size='lg'
                        fixedWidth
                        color={applyStyleByMode({
                            styleOld: '#fff',
                            theme: ThemeState?.mode,
                            light: undefined,
                            dark: WHITE
                        })}
                    />
                }
                testid='Widget-BesRankOver'
                collapse={false}
                ignoreStoredCollapse={true}
                title={translate(`t.${customerSettings.mdd_bes_display_name.toLowerCase()}_rank_overview`)}
                collapseButton={false}
                loading={false}
                applyPadding={false}
                forceCollapse={false}
                forceUncollapse={false}
                headerAction={<div style={{ height: '28px' }}></div>}
            >
                {isLoading ? (
                    <Loading>
                        <CircularProgress />
                    </Loading>
                ) : (
                    <TableContainer>
                        <TableCustom>
                            <TableBody>
                                <TableHeader besName={customerSettings.mdd_bes_display_name.toUpperCase()} />
                                <TableRow className='bes-rank'>
                                    {
                                        //Temporal fix to hide level N/A
                                        getTableData(
                                            createPercentageForView(),
                                            customerSettings.mdd_bes_display_name.toUpperCase()
                                        ).map((rank) => (
                                            <TableCell key={rank.level}>
                                                <span>{rank.count}</span>
                                                <UiCircularGraph
                                                    value={rank.percentage}
                                                    color={ThemeState.mode === ThemeMode.light ? 'inherit' : 'error'}
                                                />
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableBody>
                        </TableCustom>
                    </TableContainer>
                )}
            </Widget>
        </BesRankOverviewContainer>
    );
};
