import React from 'react';
import {
    AlertSettingsChangeOverviewTableContainer,
    LevelIndicator,
    TableBox,
    TableBoxCover
} from './AlertSettingsChangeOverviewTable.style';
import {
    AlertSettingsChangeOverviewTableProps,
    ChangeVisualizationProps,
    ChangeVisualizationTempAndPressProps
} from './AlertSettingsChangeOverviewTable.type';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useRecoilValue } from 'recoil';
import { DefaultPressureLevel, PressureLevelsSelector } from '../AlertSettings/atom';
import { DefaultTemperatureSliderValue, TemperatureSliderValue } from '../AlertTemperatureSlider/atom';
import { useTranslation } from 'react-i18next';
import useConverter from 'components/CustomHooks/Converter/Converter';
import {
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_OPTIMAL_PRESSURE,
    ALERT_OPTIMAL_PRESSURE_DARK,
    ERROR,
    HexToRGB,
    SUCCESS
} from 'components/Ui/colors';
import { ThemeMode } from 'states/global/Theme';

const PressureVisualization: React.FC<ChangeVisualizationTempAndPressProps> = ({
    level,
    defaultValue
}): JSX.Element => {
    const currentValue = useRecoilValue(PressureLevelsSelector(level));
    return <ChangeVisualization defaultValue={defaultValue} currentValue={+currentValue.value} />;
};

const TemperatureVisualization: React.FC<ChangeVisualizationTempAndPressProps> = ({
    level,
    defaultValue
}): JSX.Element => {
    const currentValue = useRecoilValue(TemperatureSliderValue);
    return <ChangeVisualization defaultValue={defaultValue} currentValue={currentValue[level - 1]} />;
};

const ChangeVisualization: React.FC<ChangeVisualizationProps> = ({ defaultValue, currentValue }): JSX.Element => {
    const isDifferent = currentValue !== defaultValue;
    const theme = useTheme();
    const changeColorValue = {
        default: isDifferent ? (theme.palette.mode === ThemeMode.dark ? 'rgb(255, 0, 0)' : ERROR) : 'inherit',
        current: isDifferent ? (theme.palette.mode === ThemeMode.dark ? 'rgb(0, 255, 0)' : SUCCESS) : 'inherit'
    };

    return isDifferent ? (
        <TableBoxCover>
            <TableBox color={changeColorValue.default}>{defaultValue}</TableBox>
            <TableBox>
                <ArrowRightAltIcon />
            </TableBox>
            <TableBox color={changeColorValue.current}>{currentValue}</TableBox>
        </TableBoxCover>
    ) : (
        <TableBoxCover>
            <TableBox>{currentValue}</TableBox>
        </TableBoxCover>
    );
};

const levelColors = {
    0: {
        light: HexToRGB(ALERT_OPTIMAL_PRESSURE, '1'),
        dark: HexToRGB(ALERT_OPTIMAL_PRESSURE_DARK, '1')
    },
    1: {
        light: HexToRGB(ALERT_LEVEL_1, '1'),
        dark: HexToRGB(ALERT_LEVEL_1_DARK, '1')
    },
    2: {
        light: HexToRGB(ALERT_LEVEL_2, '1'),
        dark: HexToRGB(ALERT_LEVEL_2_DARK, '1')
    },
    3: {
        light: HexToRGB(ALERT_LEVEL_3, '1'),
        dark: HexToRGB(ALERT_LEVEL_3_DARK, '1')
    }
};

const AlertSettingsChangeOverviewTableContent: React.FC<AlertSettingsChangeOverviewTableProps> = (): JSX.Element => {
    const defaultValue = useRecoilValue(DefaultPressureLevel);
    const defaultValueTemperature = useRecoilValue(DefaultTemperatureSliderValue);
    const { t: translate } = useTranslation();
    const { displayUserUnits } = useConverter();
    const theme = useTheme();

    return (
        <AlertSettingsChangeOverviewTableContainer data-testid='AlertSettingsChangeOverviewTableContent'>
            <TableContainer component={Box}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}>{`${translate('t.cold_pressure')} (${
                                displayUserUnits.pressure
                            })`}</TableCell>
                            <TableCell colSpan={2}>
                                {`${translate('t.temperature')} (${displayUserUnits.temperature})`}{' '}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 3`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.low3} level={-3} />
                            </TableCell>
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 3`}
                            </LevelIndicator>
                            <TableCell>
                                <TemperatureVisualization defaultValue={defaultValueTemperature[2]} level={3} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 2`}
                            </LevelIndicator>
                            <TableCell>
                                <TableBoxCover>
                                    <PressureVisualization defaultValue={defaultValue.low2} level={-2} />
                                </TableBoxCover>
                            </TableCell>
                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 2`}
                            </LevelIndicator>
                            <TableCell>
                                <TemperatureVisualization defaultValue={defaultValueTemperature[1]} level={2} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 1`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.low1} level={-1} />
                            </TableCell>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 1`}
                            </LevelIndicator>
                            <TableCell>
                                <TemperatureVisualization defaultValue={defaultValueTemperature[0]} level={1} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[0][theme.palette.mode]} component='th' scope='row'>
                                {translate('t.optimal')}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.optimal} level={0} />
                            </TableCell>
                            <TableCell
                                style={{
                                    background: theme.palette.mode === ThemeMode.dark ? `rgb(29,38,48)` : '#f2f2f2'
                                }}
                                colSpan={2}
                            >
                                {`${translate('t.high_hot_pressure')} (${displayUserUnits.pressure})`}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 1`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.high1} level={1} />
                            </TableCell>
                            <LevelIndicator background={levelColors[1][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 1`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.highHot1} level={11} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 2`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.high2} level={2} />
                            </TableCell>
                            <LevelIndicator background={levelColors[2][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 2`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.highHot2} level={12} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 3`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.high3} level={3} />
                            </TableCell>
                            <LevelIndicator background={levelColors[3][theme.palette.mode]} component='th' scope='row'>
                                {`${translate('t.level')} 3`}
                            </LevelIndicator>
                            <TableCell>
                                <PressureVisualization defaultValue={defaultValue.highHot3} level={13} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </AlertSettingsChangeOverviewTableContainer>
    );
};

export default AlertSettingsChangeOverviewTableContent;
