import React, { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AlertsFilterVehiclesInputViewContent, CustomAutocomplete } from './AlertsFilterVehiclesInput.style';
import { AlertsFilterVehiclesInputProps } from './AlertsFilterVehiclesInput.type';
import { useTranslation } from 'react-i18next';
import { TextField, useTheme } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { VehicleModelType } from 'models/VehicleModel.type';
import { AlertVehicleFilters, AlertVehicleFiltersSelector, SelectedVehicle } from '../AlertsFilterVehiclesBy/atom';
import LazyloadingAutocomplete from 'components/Mixs/LazyloadingAutocomplete';
import { SelectedAvailableFilterIds } from '../AlertsAvailableFilters/atom';

const AlertsFilterVehiclesInputContent: React.FC<AlertsFilterVehiclesInputProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const setVehicleFilter = useSetRecoilState(AlertVehicleFilters);
    const [selectedVehicle, setSelectedVehicle] = useRecoilState(SelectedVehicle);
    const [filterVehicle, setFilterVehicle] = useRecoilState(AlertVehicleFiltersSelector(props.filter.tableId));
    const setSelectedFilters = useSetRecoilState(SelectedAvailableFilterIds);
    const theme = useTheme();
    const { data } = useQuery<VehicleModelType>(
        [props.queryId],
        () => props.query(!props.isLazyloading ? props.queryOptions || {} : {}),
        {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            enabled: !props.isLazyloading
        }
    );

    useEffect(() => {
        return () => {
            setFilterVehicle(() => ({
                filter: '',
                input: ''
            }));
        };
    }, []);

    return (
        <AlertsFilterVehiclesInputViewContent data-testid='AlertsFilterVehiclesInputContent'>
            {props.isLazyloading ? (
                <LazyloadingAutocomplete
                    id={props.queryId}
                    label={translate(`t.${props.filter.name}`)}
                    query={props.query}
                    queryId={props.queryId}
                    optionKey={'name'}
                    defaultFilter={props.defaultFilter}
                    optionValue='id'
                    size='small'
                    style={{ margin: '8px 0 0 0' }}
                    onValueChange={(vehicleId, label, origItem) => {
                        if (origItem) {
                            setVehicleFilter(() => ({
                                [props.filter.tableId]: {
                                    name: origItem.name,
                                    id: origItem.id,
                                    configurationId: origItem.vehicleConfigurationV2.id
                                }
                            }));
                            setFilterVehicle((current) => ({
                                ...current,
                                filter: {
                                    name: origItem.name,
                                    id: origItem.id,
                                    configurationId: origItem.vehicleConfigurationV2.id
                                }
                            }));
                            setSelectedVehicle(origItem);
                            setSelectedFilters((current) =>
                                current.filter(
                                    (id) => props.filter.availableWith.includes(id) || id === props.filter.id
                                )
                            );
                        }
                    }}
                    preselectedValue={{
                        value: selectedVehicle?.id || 0,
                        label: selectedVehicle?.name || '',
                        origItem: selectedVehicle
                    }}
                />
            ) : (
                <CustomAutocomplete
                    disableClearable
                    id={props.queryId}
                    data-testid={props.queryId}
                    size='small'
                    value={filterVehicle.filter}
                    options={data?.codeBook || []}
                    getOptionLabel={(option: any) => props.optionKey(option)}
                    onChange={(event, newValue: any) => {
                        setVehicleFilter((current) => ({ ...current, [props.filter.tableId]: newValue }));
                        setFilterVehicle((current) => ({ ...current, filter: newValue }));
                        setSelectedFilters((current) =>
                            current.filter((id) => props.filter.availableWith.includes(id) || id === props.filter.id)
                        );
                    }}
                    theme={theme}
                    style={{ width: 200 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate(props.labelTranslationKey)}
                            variant='outlined'
                            size='small'
                            key={params.id}
                            name={params.id}
                            InputLabelProps={{
                                shrink: true
                            }}
                            //error={!!formikProps.errors.model && !!formikProps.touched.model}
                        />
                    )}
                />
            )}
        </AlertsFilterVehiclesInputViewContent>
    );
};

export default AlertsFilterVehiclesInputContent;
