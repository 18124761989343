import React, { useEffect } from 'react';
import { SloapLegend, SlopeInclinationLegendContainer } from './SlopeInclinationLegend.style';
import { SlopeInclinationLegendProps } from './SlopeInclinationLegend.type';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { MapAction, MapActionData } from 'states/global/Map';
import { SpeedHeatmapLegend, SpeedHeatmapLegendLabel } from 'pages/SpeedHeatmap/SpeedHeatmap.style';
import { FormControlLabel, Switch } from '@mui/material';
import { MapActionsEnum } from 'components/Map/Map.type';

const SlopeInclinationLegend: React.FC<SlopeInclinationLegendProps> = (): JSX.Element => {
    const [mapActionData, setMapActionData] = useRecoilState(MapActionData);
    const setMapAction = useSetRecoilState(MapAction);
    const heatmapLimit = { min: 0, max: 12 };

    useEffect(() => {
        setMapActionData((currentMapActionData) => {
            return {
                ...currentMapActionData,
                useSlopeGradient: true,
                heatmapLimit
            };
        });
        return () => {
            setMapActionData((currentMapActionData) => {
                return {
                    ...currentMapActionData,
                    useSlopeGradient: undefined,
                    heatmapLimit: undefined
                };
            });
        };
    }, []);

    return (
        <SlopeInclinationLegendContainer data-testid='SlopeInclinationLegendContent'>
            {mapActionData.useSlopeGradient && (
                <>
                    <SpeedHeatmapLegend />
                    <SpeedHeatmapLegendLabel>
                        <span>{heatmapLimit.min}</span>
                        <span>{heatmapLimit.max}</span>
                    </SpeedHeatmapLegendLabel>
                </>
            )}
            {!mapActionData.useSlopeGradient && (
                <div>
                    <SloapLegend />
                    <SpeedHeatmapLegendLabel>
                        <span>{0}</span>
                        <span>{6}</span>
                        <span>{12}</span>
                        <span style={{ visibility: 'hidden' }}>{0}</span>
                    </SpeedHeatmapLegendLabel>
                </div>
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <FormControlLabel
                    control={
                        <Switch
                            checked={mapActionData.useSlopeGradient}
                            onChange={() => {
                                setMapActionData((currentMapActionData) => {
                                    return {
                                        ...currentMapActionData,
                                        useSlopeGradient: !currentMapActionData.useSlopeGradient
                                    };
                                });
                                setMapAction({
                                    action: MapActionsEnum.SLOPE_INCLINATION_JOURNEY_UPDATE
                                });
                            }}
                        />
                    }
                    label='Linear'
                />
            </div>
        </SlopeInclinationLegendContainer>
    );
};

export default SlopeInclinationLegend;
