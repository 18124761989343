import React, { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useRecoilValue } from 'recoil';
import * as style from './AlertsSettingsForm.style';
import * as type from './AlertsSettingsForm.type';
import { Form, Formik, FormikProps } from 'formik';
import { CircularProgress, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import Button from '../../Ui/Components/UiButton';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { GridFieldFormik } from '../ProfileTabManager/ProfileTabManager.style';
import { freeEmailService } from 'helpers';

export const AlertsSettingsFormik: React.FC<type.FormikValidationViewProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const usingFreeEmailService = useMemo(() => freeEmailService(props.userEmail), [props.userEmail]);

    const schemaValidateForm = Yup.object().shape({
        alertReminderLevel1: Yup.string().required('p.this_field_is_required'),
        alertReminderLevel2: Yup.string().required('p.this_field_is_required'),
        alertReminderLevel3: Yup.string().required('p.this_field_is_required'),
        allowDesktopNotifications: Yup.string().required('p.this_field_is_required'),
        notificationPeriod: Yup.string().required('p.this_field_is_required')
    });

    return (
        <Formik
            initialValues={props.inicialvaluesFormik}
            onSubmit={(values: type.InicialvaluesForm, actions) => {
                props.handleSubmit(values, actions.setSubmitting);
            }}
            validationSchema={schemaValidateForm}
        >
            {(propsFormik: FormikProps<type.InicialvaluesForm>) => {
                const { errors, setFieldValue, values, isSubmitting } = propsFormik;

                return (
                    <Form>
                        <Grid container justify-content='flex-start' direction='row'>
                            <GridFieldFormik item lg={4} md={12} sm={6} xs={12}>
                                <Autocomplete
                                    disabled={usingFreeEmailService}
                                    options={props.unresolvedAlertReminderLevelList}
                                    getOptionLabel={(alertReminderLevel1) => alertReminderLevel1.name}
                                    onChange={(e, alertReminderLevel1) => {
                                        setFieldValue(
                                            'alertReminderLevel1',
                                            alertReminderLevel1 ? alertReminderLevel1.value : null
                                        );
                                    }}
                                    defaultValue={props.unresolvedAlertReminderLevelList.find(
                                        (alertReminderLevel1) =>
                                            alertReminderLevel1.value === values.alertReminderLevel1 ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.unresolved_alert_reminder_level1')}
                                            variant='outlined'
                                            name='alertReminderLevel1'
                                            value={values.alertReminderLevel1}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.alertReminderLevel1 && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.alertReminderLevel1}
                                            data-testid='Profile-UnresolvedAlertReminderLevel1-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={12} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.unresolvedAlertReminderLevelList}
                                    disabled={usingFreeEmailService}
                                    getOptionLabel={(alertReminderLevel2) => alertReminderLevel2.name}
                                    onChange={(e, alertReminderLevel2) => {
                                        setFieldValue(
                                            'alertReminderLevel2',
                                            alertReminderLevel2 ? alertReminderLevel2.value : null
                                        );
                                    }}
                                    defaultValue={props.unresolvedAlertReminderLevelList.find(
                                        (alertReminderLevel2) =>
                                            alertReminderLevel2.value === values.alertReminderLevel2 ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.unresolved_alert_reminder_level2')}
                                            variant='outlined'
                                            name='alertReminderLevel2'
                                            value={values.alertReminderLevel2}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.alertReminderLevel2 && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.alertReminderLevel2}
                                            data-testid='Profile-UnresolvedAlertReminderLevel2-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={12} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.unresolvedAlertReminderLevelList}
                                    disabled={usingFreeEmailService}
                                    getOptionLabel={(alertReminderLevel3) => alertReminderLevel3.name}
                                    onChange={(e, alertReminderLevel3) => {
                                        setFieldValue(
                                            'alertReminderLevel3',
                                            alertReminderLevel3 ? alertReminderLevel3.value : null
                                        );
                                    }}
                                    defaultValue={props.unresolvedAlertReminderLevelList.find(
                                        (alertReminderLevel3) =>
                                            alertReminderLevel3.value === values.alertReminderLevel3 ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.unresolved_alert_reminder_level3')}
                                            variant='outlined'
                                            name='alertReminderLevel3'
                                            value={values.alertReminderLevel3}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.alertReminderLevel3 && translate('p.this_field_is_required')
                                            }
                                            error={errors.alertReminderLevel3 ? true : false}
                                            data-testid='Profile-UnresolvedAlertReminderLevel3-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={12} sm={6} xs={12}>
                                <Autocomplete
                                    options={props.notificationPeriodList}
                                    getOptionLabel={(notificationPeriod) => notificationPeriod.name}
                                    onChange={(e, notificationPeriod) => {
                                        setFieldValue(
                                            'notificationPeriod',
                                            notificationPeriod ? notificationPeriod.value : null
                                        );
                                    }}
                                    defaultValue={props.notificationPeriodList.find(
                                        (notificationPeriod) =>
                                            notificationPeriod.value === values.notificationPeriod ?? ''
                                    )}
                                    size='small'
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate('t.notification_period')}
                                            variant='outlined'
                                            name='notificationPeriod'
                                            value={values.notificationPeriod}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            helperText={
                                                errors.notificationPeriod && translate('p.this_field_is_required')
                                            }
                                            error={!!errors.notificationPeriod}
                                            data-testid='Profile-NotificationPeriod-Autocomplete'
                                        />
                                    )}
                                />
                            </GridFieldFormik>

                            <GridFieldFormik item lg={4} md={12} sm={6} xs={12}>
                                <FormControlLabel
                                    value='allowDesktopNotifications'
                                    control={
                                        <Switch
                                            color='primary'
                                            name='allowDesktopNotifications'
                                            checked={values.allowDesktopNotifications}
                                            onChange={(e) => {
                                                setFieldValue(
                                                    'allowDesktopNotifications',
                                                    (e.target as HTMLInputElement).checked
                                                );
                                            }}
                                            data-testid='Profile-NotificationPeriod-Switch'
                                        />
                                    }
                                    label={translate('t.allow_desktop_notifications')}
                                    labelPlacement='end'
                                />
                            </GridFieldFormik>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <style.ContainerButton>
                                    <Button
                                        testid='Save-Form-Button-AlertsSettingsFormik'
                                        variant='contained'
                                        type='submit'
                                        skin='success'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <CircularProgress size={25} /> : translate('t.save')}
                                    </Button>
                                </style.ContainerButton>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};
