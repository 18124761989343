import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { UiTable3InstanceViewContent } from './UiTable3Instance.style';
import { UiTable3InstanceProps, columnVisibilityType } from './UiTable3Instance.type';
import {
    ColumnInstance,
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useSortBy,
    useTable
} from 'react-table';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { ColumnNameIdType, ColumnNamesIds, TableInstanceAtom, ColumnVisibility, TableFn } from '../UiTable3/TableAtom';
import UiTable3Header from '../UiTable3Header';
import UiTable3Body from '../UiTable3Body';
import { Table } from '@mui/material';
import { TableActions, TableAtom, TableDispatcher } from '../../../states/component/Table';
import { useUserLocalStorageSettingsTable } from '../../CustomHooks/UserLocalStorageSettings';

const UiTable3InstanceContent: React.FC<UiTable3InstanceProps> = ({ columns, data, ...props }): JSX.Element => {
    const resetTableInstance = useResetRecoilState(TableInstanceAtom);
    const setColumnNamesIds = useSetRecoilState(ColumnNamesIds);
    const setColumnVisibility = useSetRecoilState(ColumnVisibility);
    const setTableFns = useSetRecoilState(TableFn);
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const [definedColumns, setDefinedColumns] = useState<boolean>(false);
    const [definedFns, setDefinedFns] = useState<boolean>(false);
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const { getUserSettingTable } = useUserLocalStorageSettingsTable();
    const localStorageTableKey = useMemo(() => `table.${props.queryKey}Table`, [props.queryKey]);

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: tableState.queryPageIndex,
                pageSize: tableState.queryPageSize,
                sortBy: tableState.queryPageSortBy,
                filters: tableState.queryPageFilter
            },
            manualPagination: true,
            pageCount: props.pageCount,
            autoResetPage: false,
            manualSortBy: true,
            manualFilters: true
        },
        useColumnOrder,
        useFilters,
        useGroupBy,
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns
    );

    const getColumnVisibility = (columns: ColumnInstance[]): columnVisibilityType => {
        let columnVisibility: columnVisibilityType = {};
        for (let i = 0, length = columns.length; i < length; i++) {
            columnVisibility[columns[i].id] = {
                isVisible: columns[i].isVisible,
                name: columns[i].Header
            };
        }
        return columnVisibility;
    };

    const defineTableFns = useCallback(() => {
        if (!definedFns) {
            setTableFns({
                toggleHideColumn: tableInstance.toggleHideColumn,
                gotoPage: tableInstance.gotoPage
            });
            setDefinedFns(true);
        }
    }, [tableInstance.toggleHideColumn, tableInstance.gotoPage, definedFns]);

    const defineTableColumns = useCallback(() => {
        if (!definedColumns) {
            const columnNames: ColumnNameIdType[] = tableInstance.allColumns.map((column) => ({
                id: column.id,
                name: column.Header
            }));
            setColumnNamesIds(columnNames);
            setDefinedColumns(true);
        }
        setColumnVisibility(getColumnVisibility(tableInstance.allColumns));
    }, [tableInstance.allColumns, definedColumns]);

    useEffect(() => {
        if (data && props.paginator?.totalCount) {
            tableDispatch({
                type: TableActions.TOTAL_COUNT_CHANGED,
                payload: props.paginator.totalCount
            });
        }
    }, [data, props.paginator?.totalCount]);

    useEffect(() => {
        if (tableInstance.state.pageIndex !== tableState.queryPageIndex) {
            tableDispatch({
                type: TableActions.PAGE_CHANGED,
                payload: tableInstance.state.pageIndex
            });
            return;
        } else if (tableInstance.state.pageSize !== tableState.queryPageSize) {
            tableDispatch({
                type: TableActions.PAGE_SIZE_CHANGED,
                payload: tableInstance.state.pageSize
            });
        } else if (tableInstance.state.sortBy !== tableState.queryPageSortBy) {
            tableDispatch({
                type: TableActions.PAGE_SORT_CHANGED,
                payload: tableInstance.state.sortBy
            });
        } else {
            tableDispatch({
                type: TableActions.PAGE_FILTER_CHANGED,
                payload: tableInstance.state.filters
            });
        }
    }, [
        tableInstance.state.pageIndex,
        tableInstance.state.pageSize,
        tableInstance.state.sortBy,
        tableInstance.state.filters
    ]);

    /*useEffect(() => {
        setUserSettings(localStorageTableKey, {
            ...getUserSettingTable(localStorageTableKey),
            hiddenColumns: tableInstance.state.hiddenColumns
        })
    }, [(tableInstance.state.hiddenColumns || []).toString()]);*/

    useEffect(() => {
        if (getUserSettingTable(localStorageTableKey)?.orderBy) {
            tableInstance.setSortBy(getUserSettingTable(localStorageTableKey)?.orderBy);
        }
        if (getUserSettingTable(localStorageTableKey)?.hiddenColumns) {
            tableInstance.setHiddenColumns(getUserSettingTable(localStorageTableKey).hiddenColumns);
        }
        if (getUserSettingTable(localStorageTableKey)?.pageSize) {
            tableInstance.setPageSize(getUserSettingTable(localStorageTableKey).pageSize);
        }
    }, [
        (getUserSettingTable(localStorageTableKey)?.orderBy || []).toString(),
        (getUserSettingTable(localStorageTableKey)?.hiddenColumns || []).toString(),
        getUserSettingTable(localStorageTableKey)?.pageSize
    ]);

    useEffect(() => {
        return () => {
            resetTableInstance();
        };
    }, []);

    defineTableColumns();
    defineTableFns();

    return (
        <UiTable3InstanceViewContent data-testid='UiTable3InstanceContent'>
            <Table size='small' {...tableInstance.getTableProps()}>
                <UiTable3Header
                    useColumnFiltering={props.useColumnFiltering}
                    useColumnAction={props.useColumnAction}
                    queryKey={props.queryKey}
                    useColumnCheckbox={props.useColumnCheckbox}
                    headerGroups={tableInstance.headerGroups}
                />
                <UiTable3Body
                    getTableBodyProps={tableInstance.getTableBodyProps}
                    getTableProps={tableInstance.getTableProps}
                    prepareRow={tableInstance.prepareRow}
                    rows={tableInstance.rows}
                    rowActionBtns={props.rowActionBtns}
                    useColumnAction={props.useColumnAction}
                    useColumnCheckbox={props.useColumnCheckbox}
                    queryKey={props.queryKey}
                    bodyHeight={props.bodyHeight}
                />
            </Table>
        </UiTable3InstanceViewContent>
    );
};

export default UiTable3InstanceContent;
