import { styled, Box, Table } from '@mui/material';
import { applyStyleByMode } from 'helpers';

export const UiTable3InstanceViewContent = styled(Box)({
    overflow: 'auto'
});

export const TableEl = styled(Table)`
    ${applyStyleByMode({
        styleOld: `
            &.MuiTable-root {
                width: 99.8%;
            }
            & .MuiTableHead-root {
                border-bottom: 1px solid #474544;
                background: #f9f9f9;
            }
            td {
                ${applyStyleByMode({
                    styleOld: `
                        font-size: 1em;
                        line-height: 21px !important;
                        `
                })}
            }
        `
    })}
`;
