import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import { PRIMARY } from '../../Ui/colors';
import { applyStyleByMode } from 'helpers';

export const VehicleDetailsSchemaViewContent = styled.div`
    width: 100%;
`;

export const ButtonDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;

    ${applyStyleByMode({
        styleOld: `
            background-color: #fbfbfb;
            border: 1px solid #ddd;
        `
    })}
`;

export const LoadingDiv = styled.div`
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
`;

export const Loading = styled(CircularProgress)({
    color: `${PRIMARY} !important`,
    width: '50px !important',
    height: '50px !important'
});

export const SchemaDiv = styled.div<{ noBorder?: boolean }>`
    ${applyStyleByMode({
        styleOld: `
            border: ${(props) => (props.noBorder ? 'none' : '1px solid #ddd')} ;
        `,
        styleJade: `
            @media (max-width: 599px){
                height: 120%;
            }
        `
    })}
`;
export const ContainerButtonBES = styled.div`
    ${applyStyleByMode({
        styleJade: 'margin-top: 5px;'
    })}
`;
