import React from 'react';
import {
    ContainerSchemmaVehicleStatus,
    SchemaMapTabs,
    IconLabel,
    SchemaMapTab
} from './VehicleGeneralDetailsTab.style';
import { VehicleGeneralDetailsTabProps } from './VehicleGeneralDetailsTab.type';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { applyStyleByMode } from 'helpers';
import UiIcon from 'components/Ui/Components/UiIcon';
import { faLocationDot, faCarSide } from '@fortawesome/pro-solid-svg-icons';
import { WHITE } from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import VehicleDetailsSchema from '../VehicleDetailsSchema/VehicleDetailsSchema';
import TabContent from 'components/Ui/Components/UiTab/UiTab';
import VehicleDetailsMap from '../VehicleDetailsMap/VehicleDetailsMap';
import { Wrapper } from 'helpers/wrapper';

export const VehicleGeneralDetailsTab: React.FC<VehicleGeneralDetailsTabProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const [activeTab, setActiveTab] = React.useState(0);
    const ThemeMode = useRecoilValue(Theme);
    const handleTabChange = (event, newTabIndex: number): void => setActiveTab(newTabIndex);

    return (
        <ContainerSchemmaVehicleStatus>
            <>
                <SchemaMapTabs
                    value={activeTab}
                    onChange={handleTabChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    <SchemaMapTab
                        label={
                            <IconLabel>
                                <UiIcon
                                    icon={faCarSide}
                                    fontSize={17}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#3f51b5',
                                        theme: ThemeMode?.mode,
                                        light: undefined,
                                        dark: WHITE
                                    })}
                                    transform='up-1 left-2'
                                />
                                <div>{translate('t.vehicle_schema')}</div>
                            </IconLabel>
                        }
                        data-testid='VehicleStatus-vehicle-schema-Button'
                    />
                    <SchemaMapTab
                        label={
                            <IconLabel>
                                <UiIcon
                                    icon={faLocationDot}
                                    fontSize={15}
                                    fixedWidth
                                    color={applyStyleByMode({
                                        styleOld: '#777',
                                        theme: ThemeMode?.mode,
                                        light: undefined,
                                        dark: WHITE
                                    })}
                                    transform='up-1 left-3'
                                />
                                <div>{translate('t.current_location')}</div>
                            </IconLabel>
                        }
                        data-testid='VehicleStatus-current-location-Button'
                    />
                </SchemaMapTabs>
                <TabContent value={activeTab} index={0} testId='VehicleDetailsSchema'>
                    <VehicleDetailsSchema {...props} />
                </TabContent>
                <TabContent value={activeTab} index={1} testId='VehicleDetailsLocation'>
                    <VehicleDetailsMap vehicleId={props.vehicleId} />
                </TabContent>
            </>
        </ContainerSchemmaVehicleStatus>
    );
};

export default Wrapper(VehicleGeneralDetailsTab);
