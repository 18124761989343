import React, { useEffect } from 'react';
import { SpeedIncidentsContent } from './SpeedIncidents.view';
import { SpeedIncidentsProps } from './SpeedIncidents.type';
import { MapAction } from '../../states/global/Map';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { PageHelpButtonAtom } from 'components/TopBar/PageHelpButton/PageHelpButton.atom';
import { Wrapper } from 'helpers/wrapper';

const SpeedIncidents: React.FC<SpeedIncidentsProps> = (): JSX.Element => {
    const setPageHelpButton = useSetRecoilState(PageHelpButtonAtom);
    const resetMapState = useResetRecoilState(MapAction);
    const resetPageHelpButton = useResetRecoilState(PageHelpButtonAtom);

    useEffect(() => {
        setPageHelpButton({ article: 'tracking-tab' });

        return () => {
            resetMapState();
            resetPageHelpButton();
        };
    }, []);

    return <SpeedIncidentsContent data-testid='SpeedIncidents-testid' />;
};

export default Wrapper(SpeedIncidents);
