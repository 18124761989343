import React, { useMemo } from 'react';
import { TemperatureProps } from './TyreDetails.type';
import { SelectedSensorAtom } from './SelectedSensor.atom';
import { useRecoilValue } from 'recoil';
import { Value } from './TyreDetails.style';
import useTempPressValue from 'components/CustomHooks/TemperaturePressureValue/TemperaturePressureValue';
import { faChartMixedUpCircleCurrency } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Temperature: React.FC<TemperatureProps> = ({
    wheelPosition,
    alertLevel,
    temperaturePredictionAlert
}): JSX.Element => {
    const selectedSensor = useRecoilValue(SelectedSensorAtom(wheelPosition));
    let temperatureValue = selectedSensor?.sensorLog?.temperature;
    const { displayValue } = useTempPressValue();
    const finalAlertLevel = useMemo(() => {
        if (temperaturePredictionAlert) {
            if (alertLevel > temperaturePredictionAlert) {
                return alertLevel;
            }
            return temperaturePredictionAlert === 1 ? 2 : 3;
        }
        return alertLevel;
    }, [temperaturePredictionAlert, alertLevel]);

    return (
        <Value alertLevel={finalAlertLevel} $temperaturePrediction={temperaturePredictionAlert === 3}>
            {temperaturePredictionAlert > 0 && <FontAwesomeIcon icon={faChartMixedUpCircleCurrency} />}{' '}
            {displayValue(temperatureValue, {
                sensor: selectedSensor?.sensor,
                valueType: 'temperature'
            })}
        </Value>
    );
};

export default Temperature;
