import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { ExportButton, UiTable3ExportDropdownViewContent } from './UiTable3ExportDropdown.style';
import { UiTable3ExportDropdownProps } from './UiTable3ExportDropdown.type';
import { useTranslation } from 'react-i18next';
import UiMenu from '../Components/UiMenu/UiMenu';
import { ContentCopy, Download, ExpandMore, Print } from '@mui/icons-material';
import { Item } from '../Components/UiMenu/UiMenu.type';
import { useQuery } from '@tanstack/react-query';
import { TableAtom } from '../../../states/component/Table';

const UiTable3ExportDropdownContent: React.FC<UiTable3ExportDropdownProps> = (props): JSX.Element => {
    const { t: translate } = useTranslation();
    const tableState = useRecoilValue(TableAtom(`${props.queryKey}-Table`));

    const { isFetching, refetch } = useQuery(
        [`${props.queryKey}-TableQuery`],
        () => (props as { exportFn: (props) => Promise<string> }).exportFn({ ...tableState }),
        {
            refetchInterval: false,
            retry: false,
            keepPreviousData: true,
            enabled: false,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                createCSV(data);
            }
        }
    );

    const createCSV = (data) => {
        const blob = new Blob([data ?? ' '], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `${props.exportName || ''}Report.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const menuItems = useMemo(() => {
        let items: Item[] = [];
        if (props.allowDownloadCSV) {
            items.push({
                title: 'CSV',
                value: 'CSV',
                iconUI: <Download />,
                actionGeneral: () => refetch()
            });
        }
        if (props.allowCopy) {
            items.push({
                title: translate('t.copy'),
                value: 'copy',
                iconUI: <ContentCopy />,
                actionGeneral: () => {}
            });
        }
        if (props.allowPrint) {
            items.push({
                title: translate('t.print'),
                value: 'print',
                iconUI: <Print />,
                actionGeneral: () => {}
            });
        }
        return items;
    }, [props.allowCopy, props.allowDownloadCSV, props.allowPrint]);

    return (
        <UiTable3ExportDropdownViewContent data-testid='UiTable3ExportDropdownContent'>
            {(props.allowCopy || props.allowDownloadCSV || props.allowPrint) && (
                <UiMenu
                    rootEl={
                        <ExportButton endIcon={<ExpandMore />} size={'small'} variant='outlined' loading={isFetching}>
                            {translate('t.export')}
                        </ExportButton>
                    }
                    items={menuItems}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                />
            )}
        </UiTable3ExportDropdownViewContent>
    );
};

export default UiTable3ExportDropdownContent;
