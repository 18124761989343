import React, { useEffect, useState } from 'react';
import {
    CustomButton,
    CustomList,
    CustomListButton,
    CustomListText,
    CustomTextField,
    DateTimeText,
    FieldContainer,
    GridCalendar,
    GridContainer,
    GridTimePicker,
    GridTitle,
    HrsMinContainer,
    CustomCalendarPicker,
    HelperText,
    Text,
    Title
} from './DateTimeDialogSelector.style';
import { useRecoilValue } from 'recoil';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import useConverter from '../../../../../CustomHooks/Converter/Converter';
import { DateTimeModalDialogProps } from './DateTimeDialogSelector.type';
import { Is12HrsFormat } from 'helpers/Converters';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

const DateTimeDialogSelector: React.FC<DateTimeModalDialogProps> = ({
    onlyDate,
    dateValueStaticDatePicker,
    setDateValueStaticDatePicker
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const { dateTimeFormat } = useConverter();
    const [is12HrsFormatSelected, setIs12HrsFormatSelected] = useState<number | null>(
        dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1
    );
    const is12HrsFormat = useRecoilValue(Is12HrsFormat);
    const [hours, setHours] = useState<number | null>(null);
    const [minutes, setMinutes] = useState<number | null>(null);

    useEffect(() => {
        setHours(fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour));
        setMinutes(dateValueStaticDatePicker.toObject().minute);
        setIs12HrsFormatSelected(dateValueStaticDatePicker.toObject().hour >= 12 ? 2 : 1);
    }, [dateValueStaticDatePicker]);

    useEffect(() => {
        return () => {
            setHours(null);
            setMinutes(null);
            setIs12HrsFormatSelected(null);
        };
    }, []);

    const handleListItemClick = (event, index) => {
        setIs12HrsFormatSelected(index);
        setDateValueStaticDatePicker(
            index == 2 ? dateValueStaticDatePicker.plus({ hours: 12 }) : dateValueStaticDatePicker.minus({ hours: 12 })
        );
    };

    const setHoursMinutes = (props): DateTime => {
        return DateTime.fromObject({
            year: dateValueStaticDatePicker.toObject().year,
            month: dateValueStaticDatePicker.toObject().month,
            day: dateValueStaticDatePicker.toObject().day,
            hour: props.hour ?? dateValueStaticDatePicker.toObject().hour,
            minute: props.minute ?? dateValueStaticDatePicker.toObject().minute
        });
    };

    const fromMSTto12HoursFormat = (value: number): number => {
        if (value != 0) return is12HrsFormat ? (value > 12 ? value - 12 : value) : value;
        else return is12HrsFormat ? 12 : value;
    };

    const inRangeHours = (value: number): boolean => {
        return is12HrsFormat ? (value <= 12 && value >= 1 ? true : false) : value <= 23 && value >= 0 ? true : false;
    };

    const inRangeMinutes = (value: number): boolean => {
        return value <= 59 && value >= 0 ? true : false;
    };

    const handleHoursTimeButtons = (dateValue: DateTime, action: string, add: boolean): DateTime => {
        return action == 'HOURS'
            ? add == false
                ? dateValue.minus({ hours: 1 })
                : dateValue.plus({ hours: 1 })
            : add == false
            ? dateValue.minus({ minutes: 1 })
            : dateValue.plus({ minutes: 1 });
    };

    return (
        <GridContainer container spacing={1}>
            <GridTitle item xs={12} md={12} lg={12}>
                <Title>{translate(`t.${onlyDate ? 'select_date' : 'select_date_and_time'}`)}</Title>
            </GridTitle>
            <GridCalendar item xs={12} md={onlyDate ? 12 : 7} className='datePicker'>
                <CustomCalendarPicker
                    date={dateValueStaticDatePicker}
                    onChange={(newDate: DateTime): void => {
                        setDateValueStaticDatePicker(newDate);
                    }}
                />
            </GridCalendar>
            {!onlyDate && (
                <GridTimePicker item xs={12} md={5} className='timePicker'>
                    <DateTimeText variant='h6'>
                        {DateTime.fromISO(dateValueStaticDatePicker).toFormat(dateTimeFormat('date', false))}
                    </DateTimeText>
                    <Text $ampm={is12HrsFormat}>{translate('t.time')}:</Text>
                    <div></div>
                    <HrsMinContainer $ampm={is12HrsFormat}>
                        <FieldContainer className='innerDiv'>
                            <CustomButton
                                onClick={() => {
                                    setDateValueStaticDatePicker(
                                        handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', true)
                                    );
                                }}
                            >
                                <ArrowDropUp />
                            </CustomButton>

                            <CustomTextField
                                placeholder={is12HrsFormat ? 'HH' : 'hh'}
                                error={
                                    !inRangeHours(
                                        hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)
                                    )
                                }
                                value={hours != null && hours < 10 ? `0${hours}` : hours}
                                onChange={(newDate) => {
                                    if (!Number.isNaN(Number(newDate.target.value))) {
                                        setHours(Number(newDate.target.value));

                                        if (inRangeHours(Number(newDate.target.value))) {
                                            setDateValueStaticDatePicker(
                                                setHoursMinutes({ hour: newDate.target.value })
                                            );
                                        }
                                    }
                                }}
                                defaultValue={
                                    fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour) < 10
                                        ? `0${fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)}`
                                        : fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)
                                }
                            ></CustomTextField>

                            <CustomButton
                                onClick={() => {
                                    setDateValueStaticDatePicker(
                                        handleHoursTimeButtons(dateValueStaticDatePicker, 'HOURS', false)
                                    );
                                }}
                            >
                                <ArrowDropDown />
                            </CustomButton>
                        </FieldContainer>
                        <FieldContainer className='innerDiv'>:</FieldContainer>

                        <FieldContainer className='innerDiv'>
                            <CustomButton
                                onClick={() => {
                                    setDateValueStaticDatePicker(
                                        handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', true)
                                    );
                                }}
                            >
                                <ArrowDropUp />
                            </CustomButton>
                            <CustomTextField
                                placeholder='mm'
                                value={minutes && minutes < 10 ? `0${minutes}` : minutes}
                                error={!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute)}
                                onChange={(newDate) => {
                                    if (!Number.isNaN(Number(newDate.target.value))) {
                                        setMinutes(Number(newDate.target.value));

                                        if (newDate.target.value && inRangeMinutes(Number(newDate.target.value))) {
                                            setDateValueStaticDatePicker(
                                                setHoursMinutes({ minute: newDate.target.value })
                                            );
                                        }
                                    }
                                }}
                                defaultValue={
                                    dateValueStaticDatePicker.toObject().minute < 10
                                        ? `0${dateValueStaticDatePicker.toObject().minute}`
                                        : dateValueStaticDatePicker.toObject().minute
                                }
                            ></CustomTextField>
                            <CustomButton
                                onClick={() => {
                                    setDateValueStaticDatePicker(
                                        handleHoursTimeButtons(dateValueStaticDatePicker, 'MINUTES', false)
                                    );
                                }}
                            >
                                <ArrowDropDown />
                            </CustomButton>
                        </FieldContainer>

                        {is12HrsFormat && (
                            <CustomList>
                                <CustomListButton
                                    selected={is12HrsFormatSelected === 1}
                                    onClick={(event) => handleListItemClick(event, 1)}
                                >
                                    <CustomListText primary={'AM'} />
                                </CustomListButton>
                                <CustomListButton
                                    selected={is12HrsFormatSelected === 2}
                                    onClick={(event) => handleListItemClick(event, 2)}
                                >
                                    <CustomListText primary={'PM'} />
                                </CustomListButton>
                            </CustomList>
                        )}
                    </HrsMinContainer>

                    <div></div>
                    {!inRangeMinutes(minutes ?? dateValueStaticDatePicker.toObject().minute) && (
                        <HelperText $ampm={is12HrsFormat}> {translate('t.the_date_format_is_invalid')}</HelperText>
                    )}
                    {!inRangeHours(hours ?? fromMSTto12HoursFormat(dateValueStaticDatePicker.toObject().hour)) && (
                        <HelperText $ampm={is12HrsFormat}>{translate('t.the_date_format_is_invalid')}</HelperText>
                    )}
                </GridTimePicker>
            )}
        </GridContainer>
    );
};

export default DateTimeDialogSelector;
