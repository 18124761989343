import React, { useEffect, useState } from 'react';
import { StatisticsContent } from './Statistics.view';
import { RouteParams, StatisticsProps } from './Statistics.type';
import { useResetRecoilState, useSetRecoilState, useRecoilState, useRecoilValue } from 'recoil';
import { CurrentTab, SelectedVehicle } from '../../states/global/Statistics';
import Vehicle from '../../api/Vehicle';
import { useQuery } from '@tanstack/react-query';
import UiLoadingPage from '../../components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { useTranslation } from 'react-i18next';
import { RedirectParams } from 'pages/Redirect/Redirect.atom';
import { Wrapper } from 'helpers/wrapper';
import { IsChangingCustomer } from 'components/Customer/CustomerSelectorAtom';

const Statistics: React.FC<StatisticsProps> = ({ vehicleId, vehicleName, vehicleIcon }): JSX.Element => {
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [selectedVehicle, setSelectedVehicle] = useRecoilState(SelectedVehicle);
    const resetSelectedVehicle = useResetRecoilState(SelectedVehicle);
    const setStateCurrentTab = useSetRecoilState(CurrentTab);
    const [paramsLocal, setUseParamsLocal] = useState<RouteParams | null>(null);
    const [vehicleIdparam, setVehicleIdparam] = useState<number | null>(null);
    const vehicleApi = new Vehicle();
    const { t: translate } = useTranslation();
    const redirectParams = useRecoilValue(RedirectParams);
    const resetRedirectParams = useResetRecoilState(RedirectParams);
    const isChangingCustomer = useRecoilValue(IsChangingCustomer);

    useEffect(() => {
        if (selectedVehicle.name) {
            document.title = `${translate('t.statistics')} - ${selectedVehicle.name}`;
        } else {
            document.title = `${translate('t.statistics')}`;
        }
    }, [selectedVehicle.name]);

    const getUseParams = async (): Promise<void> => {
        try {
            const module = await import('react-router-dom');
            const useParams = (module as { useParams }).useParams;
            setUseParamsLocal(useParams);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('getUseParams error:' + error);
        }
    };

    const changeTab = (event: React.ChangeEvent<{}>, newTab: number): void => {
        setCurrentTab(newTab);
    };

    const { data: vehicleByIdData, isLoading } = useQuery(
        ['vehicleByIdStatistics'],
        () => vehicleApi.getById({ id: vehicleIdparam || 0 }),
        {
            enabled: Boolean(vehicleIdparam),
            retry: false,
            refetchInterval: false,
            refetchOnWindowFocus: false
        }
    );

    if (paramsLocal && !vehicleIdparam) {
        const { id } = paramsLocal;
        setVehicleIdparam(Number(id));
    }

    useEffect(() => {
        if (!paramsLocal) {
            getUseParams();
        }

        setSelectedVehicle({
            id: vehicleId || vehicleByIdData?.id || 0,
            icon: vehicleIcon || '',
            name: vehicleName || vehicleByIdData?.name || ''
        });
    }, [vehicleByIdData]);

    useEffect(() => {
        setStateCurrentTab(currentTab);
    }, [currentTab]);

    useEffect(() => {
        redirectParams.params?.tab && redirectParams.params?.tab === 'map' && setCurrentTab(1);
    }, [redirectParams.params?.tab]);

    useEffect(() => {
        return () => {
            resetSelectedVehicle();
            setCurrentTab(0);
            resetRedirectParams();
        };
    }, []);

    if (isLoading) {
        return <UiLoadingPage />;
    }

    return (
        <StatisticsContent
            data-testid={'Statistics-testid'}
            currentTab={currentTab}
            events={{ changeTab }}
            setCurrentTab={setCurrentTab}
        />
    );
};

Statistics.defaultProps = {
    vehicleId: 0,
    vehicleName: '',
    vehicleIcon: ''
};

export default Wrapper(Statistics);
