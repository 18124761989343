import React, { useEffect, useMemo, useState } from 'react';
import { CustomerSettingsContent } from './CustomerSettings.view';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import CustomerSettingsApiClass from 'api/CustomerSettings';
import { CustomerSettingType, CustomerSettingsModel, OptionLabels } from 'models/CustomerSettings.type';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from 'states/global/Modal';
import UiButton from 'components/Ui/Components/UiButton/UiButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { CRUD } from 'variables';
import {
    CustomerSettingEditModeAtom,
    SelectedCustomerSetting,
    SelectedCustomerSettingValue
} from 'states/component/CustomerSettings';
import { Success } from 'components/Popup/Popup';
import { UserInfo } from 'states/global/User';
import { checkRole } from 'helpers/authentication';
import { ROLES } from 'variables';
import { UserUnits } from 'states/global/User';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { DefaultRequestPropsType } from 'helpers/api/type';
import { Wrapper } from 'helpers/wrapper';
import { CustomerSettings as CustomerSettingsAtom } from 'states/global/CustomerSettings';
import { ERROR, SUCCESS } from 'components/Ui/colors';

const CustomerSettingsApi = new CustomerSettingsApiClass();

const CustomerSettings: React.FC = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const {
        dateTimeFormat,
        fromTimezoneToUTC,
        fromUTCtoUserTimezone,
        convertType,
        fromServerToUserUnit,
        fromUserToServerUnit
    } = useConverter();

    const setEditMode = useSetRecoilState(CustomerSettingEditModeAtom);
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const userInfo = useRecoilValue(UserInfo);
    const isSuperAdmin = useMemo(
        () => checkRole(userInfo.user, [ROLES.ROLE_SUPER_ADMIN]),

        [userInfo.user, ROLES]
    );
    const [selectedCustomerSetting, setSelectedCustomerSetting] = useRecoilState(SelectedCustomerSetting);
    const setSelectedSettingValue = useSetRecoilState(SelectedCustomerSettingValue);
    const setCustomerSettings = useSetRecoilState(CustomerSettingsAtom);
    const userUnits = useRecoilValue(UserUnits);
    const [TypedCustomerSettingsParse, setTypedCustomerSettingsParse] = useState<CustomerSettingsModel>();

    const {
        data: TypedCustomerSettings,
        refetch: RefetchTypedCustomerSettings,
        isLoading: CustomerSettingsLoading
    } = useQuery(['customerSettingsTyped'], () => CustomerSettingsApi.get<CustomerSettingsModel>({ apiProject: '' }), {
        refetchInterval: false,
        onSuccess(data) {
            data.settings = data.settings
                .filter((setting) => isSuperAdmin || setting.customerEdit)
                .map((setting) => {
                    const settingCopy = { ...setting };
                    if (settingCopy.type === CustomerSettingType.DATETIME && settingCopy.value) {
                        settingCopy.value = DateTime.fromFormat(
                            fromUTCtoUserTimezone({
                                date: settingCopy.value,
                                format: 'dateTime',
                                displaySeconds: settingCopy.format?.includes('s')
                            }),
                            dateTimeFormat('dateTime', !!settingCopy.format?.includes('s'))
                        );
                    }

                    if (settingCopy.type === CustomerSettingType.AMBIENT_TEMPERATURE) {
                        settingCopy.value = (settingCopy.value as number[]).map(
                            (val) => +fromServerToUserUnit({ type: convertType.temperature, value: val })
                        );
                    }
                    return settingCopy;
                });
            setTypedCustomerSettingsParse(data);
        }
    });

    const { refetch: RefetchGlobalCustomerSettings } = useQuery(
        ['refreshGlobalCustomerSettings'],
        () => CustomerSettingsApi.getAll(),
        {
            enabled: false,
            onSuccess: (dataOnSuccess) => setCustomerSettings({ ...dataOnSuccess.settings, ready_to_use_data: true })
        }
    );

    const { isLoading: patchIsLoading, mutate: patchSetting } = useMutation<
        DefaultRequestPropsType,
        Error,
        { [key: string]: string | number | number[] | boolean | null | Date | DateTime }
    >({
        mutationKey: ['patchCustomerSetting'],
        mutationFn: (setting) => {
            return CustomerSettingsApi.patch({ data: setting, apiProject: '' });
        },
        onSuccess: () => {
            setEditMode([]);
            Success({
                text: `${translate('t.customer_settings')} ${translate(
                    `p.${selectedCustomerSetting?.actionType === CRUD.REMOVE ? 'has_been_removed' : 'has_been_updated'}`
                )}`
            });
            modalDispach({
                type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                ModalPropsPayload: {
                    isOpen: false
                }
            });
            setSelectedCustomerSetting(undefined);
            setSelectedSettingValue(undefined);
            RefetchTypedCustomerSettings();
            RefetchGlobalCustomerSettings();
        }
    });

    const getModalHeight = (customerSettingType?: CustomerSettingType): number => {
        if (customerSettingType === CustomerSettingType.AMBIENT_TEMPERATURE) {
            return 320;
        }
        if (customerSettingType === CustomerSettingType.MAP_AREA) {
            return 120;
        }
        return 70;
    };

    const getHumanValue = (
        value: unknown,
        customerSetting: CustomerSettingsModel['settings'][0] | undefined
    ): JSX.Element | string | JSX.Element[] => {
        switch (customerSetting?.type) {
            case CustomerSettingType.BOOLEAN:
                return translate(`t.${value ? 'enabled' : 'disabled'}`);
            case CustomerSettingType.LIST:
            case CustomerSettingType.MULTIPLE_LIST:
                if (value === -1) {
                    return translate('t.none');
                }
                if (customerSetting?.options) {
                    return customerSetting.options[value as number | string];
                }
                return value as string | JSX.Element;
            case CustomerSettingType.MAP_AREA:
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return (value as []).map((val, index) => (
                    <TextField
                        label={
                            CustomerSettingType.MAP_AREA === customerSetting.type
                                ? `${translate('t.coordinates')} ${index + 1}`
                                : `${translate(`t.month${index + 1}`)}`
                        }
                        type='number'
                        size='small'
                        key={index}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                            endAdornment: customerSetting?.type == CustomerSettingType.AMBIENT_TEMPERATURE && (
                                <InputAdornment position='end'>°{userUnits.temperature.toUpperCase()}</InputAdornment>
                            )
                        }}
                        value={val ?? null}
                        style={{
                            marginRight: '10px',
                            marginTop: '10px'
                        }}
                    />
                )) as JSX.Element[];
            case CustomerSettingType.DATETIME:
                return typeof value === 'string'
                    ? value
                    : (value as DateTime).toFormat(dateTimeFormat('dateTime', false));
            default:
                return OptionLabels[value as number | string] || value;
        }
    };

    const getBEValue = (
        value: string | number | number[] | boolean | null | Date | DateTime,
        customerSetting: CustomerSettingsModel['settings'][0] | undefined
    ): string | number | number[] | boolean | null | Date | DateTime => {
        switch (customerSetting?.type) {
            case CustomerSettingType.LIST:
            case CustomerSettingType.MULTIPLE_LIST:
                if (value === -1) {
                    return null;
                }
                return value;
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return (value as number[]).map((val) =>
                    fromUserToServerUnit({ type: convertType.temperature, value: +val })
                );
            case CustomerSettingType.DATETIME: {
                return value ? fromTimezoneToUTC(value, customerSetting.format?.includes('s'), true) : null;
            }

            default:
                return value;
        }
    };

    const customerSettingsPopup = (
        type: CRUD,
        value: string | number | boolean | null | undefined | DateTime | [],
        selectedSetting: CustomerSettingsModel['settings'][0] | undefined
    ): void => {
        const humanValue = getHumanValue(value, selectedSetting);
        const settings = TypedCustomerSettings?.settings
            .filter((setting) => setting.customValue)
            .reduce((acc, current) => {
                acc[current.key] = getBEValue(current.value, current);
                return acc;
            }, {});
        const isAreaOrTemperature =
            selectedSetting?.type === CustomerSettingType.AMBIENT_TEMPERATURE ||
            selectedSetting?.type === CustomerSettingType.MAP_AREA;
        const isContentToSave = !!(type === CRUD.ADD || type === CRUD.EDIT);

        const contentModalAdd = {
            id: 'customerSettingsPopup',
            leftTitle: translate(
                `t.${
                    type === CRUD.ADD
                        ? 'add_customer_setting'
                        : type === CRUD.REMOVE
                        ? 'remove_customer_setting'
                        : 'edit_customer_setting'
                }`
            ),
            content: (
                <>
                    <Typography variant='body1' gutterBottom>
                        {translate(
                            `p.${
                                isContentToSave
                                    ? 'save_customer_setting_with_value'
                                    : 'remove_customer_setting_with_value'
                            }`,
                            {
                                setting: translate(`t.${selectedSetting?.key}`),
                                value: ''
                            }
                        )}
                        {isAreaOrTemperature ? <div>{humanValue}</div> : humanValue}
                    </Typography>
                </>
            ),
            buttons: (
                <>
                    <UiButton
                        skin={isContentToSave ? SUCCESS : ERROR}
                        color={isContentToSave ? 'primary' : 'error'}
                        variant='contained'
                        loading={patchIsLoading}
                        onClick={() => {
                            if (selectedSetting?.key) {
                                const { [selectedSetting.key]: _, ...remainingSettingValues } = settings as Record<
                                    string,
                                    unknown
                                >;

                                patchSetting(
                                    isContentToSave
                                        ? {
                                              ...settings,
                                              [selectedSetting.key]: getBEValue(value, selectedSetting)
                                          }
                                        : remainingSettingValues
                                );
                            }
                        }}
                    >
                        {translate(`t.${isContentToSave ? 'save' : 'remove'}`)}
                    </UiButton>
                </>
            ),
            width: 500,
            height: getModalHeight(selectedSetting?.type)
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                onClose: () => {
                    modalDispach({
                        type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                        ModalPropsPayload: {
                            ...contentModalAdd,
                            isOpen: false
                        }
                    });
                    if (type === CRUD.REMOVE) {
                        setEditMode([]);
                        setSelectedCustomerSetting(undefined);
                        setSelectedSettingValue(undefined);
                    }
                },
                isOpen: true
            }
        });
    };

    useEffect(() => {
        return () => {
            setEditMode([]);
        };
    }, []);

    return (
        <CustomerSettingsContent
            data-testid='CustomerSettings-testid'
            customerSettingsPopup={customerSettingsPopup}
            patchSetting={patchSetting}
            typedCustomerSettings={TypedCustomerSettingsParse}
            patchIsLoading={patchIsLoading}
            isLoading={CustomerSettingsLoading}
        />
    );
};

export default Wrapper(CustomerSettings);
