import { HeaderGroup, HeaderProps, Renderer } from 'react-table';
import { SetterOrUpdater, atom } from 'recoil';
import { columnVisibilityType } from '../UiTable3Instance/UiTable3Instance.type';

type Table = {
    headerGroups: HeaderGroup<{}>[];
};

export type TableFilters = {
    [key: string]: {
        value: string | number;
        humanValue: string;
    };
};

export const TableInstanceAtom = atom<Table | undefined>({
    key: 'TableInstance',
    default: undefined
});

export const TableFilters = atom<TableFilters>({
    key: 'TableFilters',
    default: {}
});

export type ColumnNameIdType = {
    id: string;
    name?: Renderer<HeaderProps<object>>;
};

export const ColumnNamesIds = atom<ColumnNameIdType[]>({
    key: 'ColumnNamesIds',
    default: []
});

export const ColumnVisibility = atom<columnVisibilityType>({
    key: 'ColumnVisibility',
    default: {}
});

type TableFnType = Record<string, unknown>;

export const TableFn = atom<TableFnType>({
    key: 'TableFn',
    default: {}
});

export const QueryFn = atom<TableFnType>({
    key: 'QueryFn',
    default: {}
});

export const SelectAll = atom<boolean>({
    key: 'SelectAll',
    default: false
});

export enum SelectedRowEnum {
    SET_SELECT_ALL = 'SET_SELECT_ALL',
    SET_SELECT = 'SET_SELECT'
}

type toggleSelectRow = {
    type: SelectedRowEnum.SET_SELECT_ALL;
    payload: boolean;
};

type selectRow = {
    type: SelectedRowEnum.SET_SELECT;
    payload: {
        rowData: Record<string, unknown>;
        added: boolean;
    };
};

type SelectedRowAction = toggleSelectRow | selectRow;

type SelectedRowProps = {
    selectedAll: boolean;
    selectedRows: Record<string, unknown>[];
};

export const SelectRow = atom<SelectedRowProps>({
    key: 'SelectRow',
    default: {
        selectedAll: false,
        selectedRows: []
    }
});

export const SelectedRowDispatcher =
    (previousState: SelectedRowProps, setState: SetterOrUpdater<SelectedRowProps>) => (action: SelectedRowAction) => {
        switch (action.type) {
            case SelectedRowEnum.SET_SELECT_ALL:
                return setState({
                    ...previousState,
                    selectedRows: [],
                    selectedAll: action.payload
                });
            case SelectedRowEnum.SET_SELECT: {
                let copySelectedRows = [...previousState.selectedRows];
                if (action.payload.added) {
                    copySelectedRows.push(action.payload.rowData);
                } else {
                    copySelectedRows = copySelectedRows.filter((row) => row.id !== action.payload.rowData.id);
                }
                return setState({
                    ...previousState,
                    selectedRows: copySelectedRows
                });
            }
            default:
                return setState(previousState);
        }
    };
