import { Table } from '@mui/material';
import styled from 'styled-components';

export const BesRankOverviewContainer = styled.div``;

export const TableCustom = styled(Table)`
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
    font-size: 12px !important;
    & thead th{
        padding: 0 !important;
        padding-bottom: 5px !important;
        font-weight: bold;
        line-height: 25px;
        background-color: #f2f2f2;
    }
    & tr:not(.bes-rank):not(.bes-rank-colored) td:first-child {
        font-weight: bold;
        text-align: left !important;
        padding-left: 5px !important;
    }
    & .bes-rank td {
        text-align: center !important;
        font-weight: bold !important;
        span {
            display: block;
        }
    }
    & .bes-rank-colored td {
        text-align: center !important;
        font-weight: bold !important;
        color: #fff !important;
    }
    & tr td{
        padding: 8px 10px !important;
        font-size: 12px !important;
        vertical-align: middle !important;
        text-align: center !important;
        border-left: 1px solid rgba(224, 224, 224, 1); !important;
    }
`;

export const Loading = styled.div`
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
