import React, { useEffect } from 'react';
import ModalButton from 'components/Ui/Components/UiButton/UiButton';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SettingsIcon from '@mui/icons-material/Settings';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DeviceBatchModel } from 'models/DeviceBatch.type';
import Menu from 'components/Ui/Components/UiMenu/UiMenu';
import { useMutation } from '@tanstack/react-query';
import { Text, TextFieldDatePicker } from './BatchesTable.style';
import DeviceUpdateBatch from 'api/DeviceUpdateBatch';
import { Success } from 'components/Popup/Popup';
import { RefreshTable } from 'components/Ui/Components/UiTable2/UiTable.atom';
import { UserInfo } from 'components/../states/global/User';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useConverter from 'components/CustomHooks/Converter/Converter';
import { DateTime } from 'luxon';
import { BatchesTableProps } from './BatchesTable.type';
import { BatchesTableContent } from './BatchesTable.view';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import JobsTable from '../JobsTable/JobsTable';
import useModal from 'components/CustomHooks/ModalHook/ModalHook';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

const deviceUpdateBatchApi = new DeviceUpdateBatch();

const BatchesTable: React.FC<BatchesTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const { setModalState, updateModalState, modalState } = useModal(0);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('batchesHub-RefreshTableAtom'));

    const userInfo = useRecoilValue(UserInfo);

    const { fromUTCtoUserTimezone } = useConverter();
    const { dateTimeFormat } = useConverter();

    const updateModal = (isOpen?: boolean): void => {
        updateModalState({
            ...modalState,
            buttons: <ModalButton skin='success'>{<CircularProgress size='1.75em' />}</ModalButton>,
            isOpen: typeof isOpen === 'undefined' ? modalState.isOpen : isOpen
        });
    };

    const { mutate: cancelBatchMutate, isLoading: unassignAdministrationHubLoading } = useMutation(
        deviceUpdateBatchApi.cancelBatch,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.batch_successfully_removed')
                });
                setRefreshTableAtom(true);
            },
            onSettled: () => {
                setModalState({});
            }
        }
    );

    const handleModalRemove = (id: number): void => {
        const contentModalDelete = {
            id: 'RemoveBatchHubActions',
            leftTitle: `${translate('t.cancel_batch')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_cancel_batch')}
                </Text>
            ),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    onClick={() => {
                        cancelBatchMutate(id);
                    }}
                >
                    {unassignAdministrationHubLoading ? <CircularProgress size='1.75em' /> : translate('t.cancel')}
                </ModalButton>
            ),
            width: 400,
            height: 150,
            customCancelTitle: translate('t.close'),
            isOpen: true
        };

        setModalState({ ...contentModalDelete });
    };

    const jobsContent = (id) => {
        const contentModalJob = {
            content: <JobsTable batchId={id} />,
            leftTitle: translate('t.jobs'),
            rightTitle: `${translate('t.batch_id')} ${id}`,
            width: 1300,
            height: 600,
            buttons: <></>,
            customCancelTitle: translate('t.close'),
            isOpen: true
        };

        setModalState({ ...contentModalJob });
    };

    const columns: Column<DeviceBatchModel>[] = [
        {
            Header: translate('t.actions'),
            width: 88,
            height: 20,
            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        items={[
                            {
                                title: translate('t.view_jobs'),
                                value: props.value,
                                action: () => {
                                    jobsContent(props.row.original.id);
                                }
                            },

                            {
                                title: translate('t.cancel_batch'),
                                value: props.value,
                                action: () => {
                                    handleModalRemove(props.row.original.id);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        },
        {
            Header: 'ID',
            accessor: 'id',
            disableFilters: true,
            disableSortBy: true,
            width: 60,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.id ?? '-'}</>
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            disableFilters: false,
            disableSortBy: true,
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => (
                <>
                    {fromUTCtoUserTimezone({
                        date: props.row.original.createdAt,
                        format: 'dateTime',
                        displaySeconds: true
                    }) ?? '-'}
                </>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.created_at')} ${translate('t.from')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtFrom')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextFieldDatePicker
                                    {...params}
                                    margin='normal'
                                    id='createdat-from'
                                    size={'small'}
                                    variant='outlined'
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.created_at')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: DateTime.fromISO(newValue)
                                      };
                                setFilter('createdAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextFieldDatePicker
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='createdat-to'
                                    size={'small'}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },
        {
            Header: translate('t.finished_at'),
            accessor: 'finishedAt',
            disableFilters: false,
            disableSortBy: true,
            width: 120,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) =>
                props.row.original.finishedAt != null ? (
                    <>
                        {fromUTCtoUserTimezone({
                            date: props.row.original.finishedAt,
                            format: 'dateTime',
                            displaySeconds: true
                        })}
                    </>
                ) : (
                    <>-</>
                ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.finished_at')} ${translate('t.from')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'finishedAtFrom')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.finished_at')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('finishedAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextFieldDatePicker
                                    {...params}
                                    margin='normal'
                                    id='finishedAt-from'
                                    size={'small'}
                                    variant='outlined'
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.finished_at')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'finishedAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue: DateTime): void => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.finished_at')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: DateTime.fromISO(newValue)
                                      };
                                setFilter('finishedAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextFieldDatePicker
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='finishedAt-to'
                                    size={'small'}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.finished_jobs'),
            accessor: 'finishedJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.finishedJobs ?? '-'}</>
        },

        {
            Header: translate('t.unfinished_jobs'),
            accessor: 'unfinishedJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.unfinishedJobs ?? '-'}</>
        },
        {
            Header: translate('t.error_jobs'),
            accessor: 'errorJobs',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) => <>{props.row.original.errorJobs ?? '-'}</>
        },
        {
            Header: translate('t.state'),
            accessor: 'state',
            disableFilters: true,
            disableSortBy: true,
            width: 180,
            height: 20,
            Cell: (props: { row: { original: DeviceBatchModel } }) =>
                props.row.original.finishedAt == null ? (
                    <CircularProgress data-testid={'BatchesTable-CircularProgress-testid'} size='1em' />
                ) : props.row.original.errorJobs > 0 ? (
                    <FontAwesomeIcon icon={faExclamationCircle} color='red' />
                ) : (
                    <FontAwesomeIcon icon={faCheckCircle} color='green' />
                )
        }
    ];

    useEffect(() => {
        updateModal();
    }, [unassignAdministrationHubLoading]);

    return <BatchesTableContent data-testid='BatchesTable-testid' columns={columns} />;
};

export default Wrapper(BatchesTable);
