import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import RoomIcon from '@mui/icons-material/Room';
import { WidgetJourneyMapProps } from './WidgetJourneyMap.type';
import { MapAction } from 'states/global/Map';
import { MapActionsEnum, MapModes } from 'components/Map/Map.type';
import { DateTimePicker, ShowAlerts, StatusDataPressureGraph, Timeline } from 'states/component/FaultySensorReporter';
import useConverter from 'components/CustomHooks/Converter/Converter';
import Widget from 'components/Ui/Components/UiWidget';
import MapLegend from 'components/Ui/Components/UiMapComponent';
import { useTranslation } from 'react-i18next';
import Map from 'components/Map';
import { applyStyleByMode } from 'helpers';
import NoDataContainerContent from 'components/Mixs/NoDataContainer/NoDataContainer';
import {
    BACKGROUND_DARK_MODE,
    BORDER_MAP_CURRENT_VEHICLE,
    BORDER_MAP_OTHER_VEHICLE,
    ERROR,
    MAP_CURRENT_VEHICLE,
    MAP_OTHER_VEHICLE
} from 'components/Ui/colors';
import { Theme } from 'states/global/Theme';
import { CircleColor, ContentMapInfo } from './WidgetJourneyMap.style';
import { Checkbox, FormControlLabel } from '@mui/material';

const WidgetJourneyMap: React.FC<WidgetJourneyMapProps> = ({
    faultySensorReporterState,
    invalidRangeHour,
    sx
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const setMapState = useSetRecoilState(MapAction);
    const resetMapState = useResetRecoilState(MapAction);
    const dateTimePicker = useRecoilValue(DateTimePicker);
    const statusDataPressureGraph = useRecoilValue(StatusDataPressureGraph);
    const timeline = useRecoilValue(Timeline);
    const { fromTimezoneToUTC } = useConverter();
    const ThemeModeValue = useRecoilValue(Theme);
    const showAlerts = useRecoilValue(ShowAlerts);
    const [showAllVehicle, setShowCurrentVehicle] = useState<boolean>(false);
    const headerColor = useMemo(() => {
        return applyStyleByMode({
            theme: ThemeModeValue?.mode,
            light: '#e9e9e9;',
            dark: BACKGROUND_DARK_MODE
        });
    }, [ThemeModeValue]);

    const updatedShowAllVehicle = (allVehicle: boolean) => {
        if (allVehicle !== showAllVehicle) {
            showPointsMap(allVehicle);
            setShowCurrentVehicle(allVehicle);
        }
    };

    const showPointsMap = (showAllVehicle: boolean) => {
        setMapState({
            action: MapActionsEnum.DRAW_JOURNEY_FAULTY_REPORT,
            params: {
                granularity: timeline.granularity,
                measuredFrom: fromTimezoneToUTC(dateTimePicker.current.dateFrom.valueOf()),
                measuredTo: fromTimezoneToUTC(dateTimePicker.current.dateTo.valueOf()),
                sensorId: faultySensorReporterState.sensorId,
                vehicleId: faultySensorReporterState.vehicleId,
                showAllVehicle: showAllVehicle
            }
        });
    };

    useEffect(() => {
        if (!invalidRangeHour && statusDataPressureGraph.dataAvailable) {
            showPointsMap(false);
        }
    }, [dateTimePicker, statusDataPressureGraph.dataAvailable]);

    useEffect(
        () => () => {
            resetMapState();
            setMapState({ action: MapActionsEnum.HIDE_MAP_MESSAGE });
        },

        []
    );

    const showContent = () => {
        if (invalidRangeHour) {
            return (
                <NoDataContainerContent
                    icon='hourglass-clock'
                    iconColor={ERROR}
                    message={translate('t.3_hours_period_allowed')}
                />
            );
        }

        if (!statusDataPressureGraph.dataAvailable) {
            return (
                <NoDataContainerContent
                    icon='hourglass-clock'
                    iconColor={ERROR}
                    message={translate('t.there_no_data')}
                />
            );
        }

        return (
            <>
                <MapLegend right={showAlerts ? '35em' : '0rem'}>
                    <ContentMapInfo onClick={() => updatedShowAllVehicle(false)}>
                        <CircleColor color={MAP_CURRENT_VEHICLE} border={BORDER_MAP_CURRENT_VEHICLE} />
                        {`${translate('t.current_vehicle')} (${faultySensorReporterState.vehicleName})`}
                    </ContentMapInfo>

                    <ContentMapInfo onClick={() => updatedShowAllVehicle(true)}>
                        <CircleColor color={MAP_OTHER_VEHICLE} border={BORDER_MAP_OTHER_VEHICLE} />
                        {`${translate('t.other_vehicles')}`}
                    </ContentMapInfo>
                </MapLegend>
                <Map mapMode={MapModes.JOURNEY_FAULTY_REPORT} />
            </>
        );
    };

    return (
        <Widget
            title={translate('t.map')}
            avatar={<RoomIcon />}
            testid='Widget-JourneyMap'
            collapseButton={false}
            fullScreenButton={false}
            headerColor={headerColor}
            $contentHeight={45}
            unit='vh'
            padding={0}
            applyPadding={false}
            marginBottom={false}
            sx={sx}
            headerAction={
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAllVehicle}
                            disabled={invalidRangeHour}
                            onChange={() => updatedShowAllVehicle(!showAllVehicle)}
                            name='display-mounted'
                        />
                    }
                    label={translate('t.display_readings_from_all_hubs')}
                />
            }
        >
            {showContent()}
        </Widget>
    );
};

export default WidgetJourneyMap;
