import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import { ERROR, PRIMARY, SUCCESS } from '../../colors';

const getColor = (skin?: string): string => {
    switch (skin?.toLowerCase()) {
        case 'primary':
            return PRIMARY;
        case 'success':
            return SUCCESS;
        case 'error':
            return ERROR;
        default:
            return skin || PRIMARY;
    }
};

export const StyledCheckbox = styled(Checkbox)<{ skin?: string }>``;

export const StyledFormControlLabel = styled(FormControlLabel)<{
    skin?: string;
    skinlabel?: boolean | number;
}>`
    &.MuiFormControlLabel-root {
        span {
            font-size: 0.93rem !important ;
        }
    }
`;
