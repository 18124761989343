import React, { useCallback } from 'react';
import { CustomeSettingInputsProps } from './CustomeSettingInputs.type';
import { CustomerSettingType, OptionLabels } from '../../../models/CustomerSettings.type';
import InputAdornment from '@mui/material/InputAdornment';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { MenuItem, TextField } from '@mui/material';
import UiDatePicker from '../../Ui/Components/UiDatePicker/UiDatePicker';
import UiCheckbox from '../../Ui/Components/UiCheckbox/UiCheckbox';
import { CRUD } from 'variables';
import { ContainerAmbientTemp, CustomeSettingInputsContainer, DatePickerContainer } from './CustomeSettingInputs.style';
import { UserUnits } from '../../../states/global/User';

import { DateTime } from 'luxon';
import useConverter from '../../CustomHooks/Converter/Converter';
import { Wrapper } from 'helpers/wrapper';

export const humanValue = (value: OptionLabels): string => {
    return OptionLabels[value] || value;
};

const CustomeSettingInputs: React.FC<CustomeSettingInputsProps> = ({
    type,
    value,
    setValue,
    actionType,
    settingOptions
}): JSX.Element => {
    const { t: translate } = useTranslation();
    const userUnits = useRecoilValue(UserUnits);
    const { dateTimeFormat } = useConverter();
    const settingInput = useCallback(() => {
        switch (type) {
            case CustomerSettingType.INTEGER:
            case CustomerSettingType.NUMBER:
                return (
                    <TextField
                        label={translate('t.value')}
                        type='number'
                        size='small'
                        data-testid={`${type}Input`}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            inputProps:
                                type === CustomerSettingType.NUMBER
                                    ? { min: settingOptions?.min, max: settingOptions?.max }
                                    : {}
                        }}
                        value={value}
                        onChange={(e) => {
                            setValue(+e.target.value);
                        }}
                    />
                );
            case CustomerSettingType.DATETIME: {
                !value && setValue(DateTime.now());
                let val = value;
                if (typeof val === 'string') {
                    val = DateTime.fromFormat(val, dateTimeFormat('dateTime', !!settingOptions?.format?.includes('s')));
                }
                return (
                    <DatePickerContainer>
                        <UiDatePicker
                            testId={`${type}Input`}
                            value={val}
                            onDateChange={(newValue) => {
                                setValue(newValue || null);
                            }}
                        />
                    </DatePickerContainer>
                );
            }
            case CustomerSettingType.BOOLEAN:
                return actionType === CRUD.EDIT ? (
                    <UiCheckbox
                        testid={`${type}Input`}
                        checked={value as boolean | undefined}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setValue(e.target.checked);
                        }}
                    />
                ) : (
                    <TextField
                        id='boolean_list'
                        select
                        value={(value || false).toString()}
                        label={translate('t.value')}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setValue(event.target.value === 'true');
                        }}
                        data-testid={`${type}Input`}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                            style: {
                                width: '100%'
                            }
                        }}
                        style={{
                            width: '100%'
                        }}
                    >
                        <MenuItem value={'true'} data-testid={`${type}InputEanbled`}>
                            {translate('t.enabled')}
                        </MenuItem>
                        <MenuItem value={'false'} data-testid={`${type}InputDisabled`}>
                            {translate('t.disabled')}
                        </MenuItem>
                    </TextField>
                );
            case CustomerSettingType.LIST:
            case CustomerSettingType.MULTIPLE_LIST:
                if (!value || value === null) setValue(-1);
                return (
                    <TextField
                        id='select_list'
                        select
                        value={value}
                        label={translate('t.value')}
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            setValue(+event.target.value === -1 ? null : event.target.value);
                        }}
                        variant='outlined'
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        data-testid={`${type}Input`}
                        style={{
                            width: '100%'
                        }}
                        inputProps={{
                            style: {
                                width: '100%'
                            }
                        }}
                    >
                        {settingOptions?.nullable && <MenuItem value={-1}>{translate('t.none')}</MenuItem>}
                        {settingOptions?.options &&
                            Object.keys(settingOptions.options).map((key) => {
                                if (settingOptions?.options) {
                                    return (
                                        <MenuItem value={key} key={key}>
                                            {humanValue(settingOptions.options[key])}
                                        </MenuItem>
                                    );
                                }
                            })}
                    </TextField>
                );
            case CustomerSettingType.MAP_AREA:
            case CustomerSettingType.AMBIENT_TEMPERATURE:
                return (
                    <ContainerAmbientTemp ismap={type === CustomerSettingType.MAP_AREA}>
                        {((value || [0, 0, 0, 0]) as []).map((val, index) => (
                            <TextField
                                label={
                                    CustomerSettingType.MAP_AREA === type
                                        ? `${translate('t.coordinates')} ${index + 1}`
                                        : `${translate(`t.month${index + 1}`)}`
                                }
                                type='number'
                                size='small'
                                key={index}
                                InputLabelProps={{ shrink: true }}
                                data-testid={`${type}Input`}
                                value={val}
                                onChange={(e) => {
                                    setValue((current) => {
                                        let temp = [...current];
                                        temp[index] = e.target.value;
                                        return temp;
                                    });
                                }}
                                onBlur={(e) => {
                                    setValue((current) => {
                                        let temp = [...current];
                                        temp[index] = +e.target.value;
                                        return temp;
                                    });
                                }}
                                InputProps={{
                                    endAdornment: type == CustomerSettingType.AMBIENT_TEMPERATURE && (
                                        <InputAdornment position='end'>
                                            °{userUnits.temperature.toUpperCase()}
                                        </InputAdornment>
                                    )
                                }}
                                style={{
                                    marginRight: '10px',
                                    marginTop: '10px',
                                    width: '100px !important'
                                }}
                            />
                        ))}
                    </ContainerAmbientTemp>
                );
            default:
                return (
                    <TextField
                        label={translate('t.value')}
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        value={value || ''}
                        data-testid={`${type}Input`}
                        onChange={(e) => {
                            setValue(e.target.value);
                        }}
                        error={!settingOptions?.nullable && value === null}
                    />
                );
        }
    }, [type, value, settingOptions]);

    return (
        <CustomeSettingInputsContainer data-testid='CustomeSettingInputs-testid'>
            {type ? settingInput() : <></>}
        </CustomeSettingInputsContainer>
    );
};

export default Wrapper(CustomeSettingInputs);
