import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import styled from 'styled-components';
import { useTheme } from '@mui/material';
import { RANK0, RANK1, RANK2, RANK3 } from '../../../../Ui/colors';
import { applyStyleByMode } from 'helpers';
import { ThemeMode } from '../../../../../states/global/Theme';
import {
    ALERT_LEVEL_0,
    ALERT_LEVEL_0_DARK,
    ALERT_LEVEL_0_DARK_HOVER,
    ALERT_LEVEL_0_HOVER,
    ALERT_LEVEL_1,
    ALERT_LEVEL_1_DARK,
    ALERT_LEVEL_1_DARK_HOVER,
    ALERT_LEVEL_1_HOVER,
    ALERT_LEVEL_2,
    ALERT_LEVEL_2_DARK,
    ALERT_LEVEL_2_DARK_HOVER,
    ALERT_LEVEL_2_HOVER,
    ALERT_LEVEL_3,
    ALERT_LEVEL_3_DARK,
    ALERT_LEVEL_3_DARK_HOVER,
    ALERT_LEVEL_3_HOVER,
    PRIMARY,
    BACKGROUND_BLUE,
    WHITE
} from '../../../../Ui/colors';
import { CommonTypeTheme } from 'components/Ui/Theme';
import useCommon from 'helpers/themeCommon/useCommon';

export const VehicleStatusListViewContent = styled.div`
    width: auto;

    ${applyStyleByMode({
        styleOld: `
        min-width: 420px;
            `,
        styleJade: `
        min-width: 440px;
            `
    })}
`;

export const NoVehicleIcon = styled.span`
    font-size: 28px;
    opacity: 0.3;
    ${applyStyleByMode({
        styleJade: 'padding-right: 11px;'
    })}
`;

export const ListWrapper = styled.section`
    width: 100%;
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `
            background-color: transparent;
            font-size: ${common.textSize};
        `;
    }}
    z-index: 2;
`;

export const VehicleList = styled(List)`
    && {
        padding: 0 !important;
    }
`;

export const VehicleListItem = styled(ListItemButton)<{
    $disableVehicleSelection: boolean;
    selected: boolean;
    alertlevel: number;
}>`
    &.MuiListItemButton-root {
        padding: ${(props) => (props.selected ? '0 0 0 3.4px' : '0 0 0 5px')};
        cursor: ${(props) => (props.selected ? 'initial' : props.$disableVehicleSelection ? 'wait' : 'pointer')};

        color: ${() => {
            return `
                color: ${useTheme().palette.mode === ThemeMode.light ? '#777' : WHITE}
            `;
        }};

        height: 32px;
        ${() => `font-size: ${useCommon().textSize};`}
        border-width: ${(props) => (props.selected ? '3px' : 'thin')};
        border-style: solid;
        border-color: ${(props) => {
            const alertColors = {
                0: '#bfbfbf',
                1: '#9f9415',
                2: '#CD6E0A',
                3: '#e00000'
            };
            const colorBorderSelected = applyStyleByMode({
                styleOld: '#3a3633',
                theme: useTheme().palette.mode,
                light: '#3a3633',
                dark: BACKGROUND_BLUE
            });

            if (useTheme().palette.mode === ThemeMode.dark) {
                return props.selected ? colorBorderSelected : useTheme().palette.background.default;
            }

            return props.selected ? colorBorderSelected : alertColors[props.alertlevel];
        }};
        background-color: ${(props) => {
            const alertColors = {
                light: {
                    0: ALERT_LEVEL_0,
                    1: ALERT_LEVEL_1,
                    2: ALERT_LEVEL_2,
                    3: ALERT_LEVEL_3
                },
                dark: {
                    0: ALERT_LEVEL_0_DARK,
                    1: ALERT_LEVEL_1_DARK,
                    2: ALERT_LEVEL_2_DARK,
                    3: ALERT_LEVEL_3_DARK
                }
            };
            return alertColors[useTheme().palette.mode][props.alertlevel];
        }};
        &:hover {
            background: ${(props) => {
                const alertColors = {
                    light: {
                        0: ALERT_LEVEL_0_HOVER,
                        1: ALERT_LEVEL_1_HOVER,
                        2: ALERT_LEVEL_2_HOVER,
                        3: ALERT_LEVEL_3_HOVER
                    },
                    dark: {
                        0: ALERT_LEVEL_0_DARK_HOVER,
                        1: ALERT_LEVEL_1_DARK_HOVER,
                        2: ALERT_LEVEL_2_DARK_HOVER,
                        3: ALERT_LEVEL_3_DARK_HOVER
                    }
                };
                return alertColors[useTheme().palette.mode][props.alertlevel];
            }};
            transition: background-color 500ms linear;
        }
        &&.Mui-selected {
            background: ${(props) => {
                const alertColors = {
                    light: {
                        0: ALERT_LEVEL_0_HOVER,
                        1: ALERT_LEVEL_1_HOVER,
                        2: ALERT_LEVEL_2_HOVER,
                        3: ALERT_LEVEL_3_HOVER
                    },
                    dark: {
                        0: ALERT_LEVEL_0_DARK_HOVER,
                        1: ALERT_LEVEL_1_DARK_HOVER,
                        2: ALERT_LEVEL_2_DARK_HOVER,
                        3: ALERT_LEVEL_3_DARK_HOVER
                    }
                };

                return alertColors[useTheme().palette.mode][props.alertlevel];
            }};
            transition: background-color 500ms linear;
        }
    }
`;

export const IconWrapper = styled.section<{ $outOfService: boolean }>`
    opacity: ${(props) => (props.$outOfService ? '0.4' : '1')};
`;

export const Name = styled.span<{ alertLevel: number }>`
    ${() => {
        const common = useTheme().palette.common as CommonTypeTheme;
        return `font-size: ${common.textSize};`;
    }}
    padding: 0px 5px;
    font-weight: bold;
    display: inline-block;
    width: 80px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;

    color: ${(props) => {
        const alertColors = {
            0: PRIMARY,
            1: ALERT_LEVEL_1_DARK,
            2: ALERT_LEVEL_2_DARK,
            3: ALERT_LEVEL_3_DARK
        };
        return useTheme().palette.mode === ThemeMode.light ? alertColors[props.alertLevel] : WHITE;
    }};
`;

export const VehicleUpdateTime = styled.span<{ selected: boolean }>`
    ${() => {
        const {
            palette: { common }
        } = useTheme() as { palette: { common: CommonTypeTheme } };
        return `font-size: ${common.textSize};`;
    }}

    padding:  ${(props) => (props.selected ? '0px 3.2px' : '0px 5px')};
    top: 50%;
    right: 4px;
    position: absolute;
    transform: translateY(-50%);
    text-align: left;
    min-width: 114.4px;
`;

export const BESLabel = styled.div<{ rank: number }>`
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 21px;
    width: 50px;
    position: absolute;
    right: 160px;
  background-color: ${(props) => {
      const BESColors = {
          0: RANK0,
          1: RANK1,
          2: RANK2,
          3: RANK3
      };
      return BESColors[props.rank];
  }};
  ${(props) => props.rank === 0 && 'display: none;'}
};`;

export const BESText = styled.div`
    padding-top: 3px;
    font-weight: 600;
    color: #fff;
`;
export const Icon = styled.i<{ $hidden: boolean }>`
    ${(props) => props.$hidden && 'display: none'};
`;

export const HubIconContainer = styled.div`
    margin: 0px 7.84px;
`;
