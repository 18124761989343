import { atom, selector } from 'recoil';
import { CustomerSettingsAll } from 'models/CustomerSettings.type';
import { ConverterFromServerProps, ConverterTypeEnum } from 'components/CustomHooks/Converter/Converter.type';
import { DateTime } from 'luxon';

export const CustomerSettings = atom<CustomerSettingsAll['settings'] & { ready_to_use_data?: boolean }>({
    key: 'CustomerSettingsAtom',
    default: {
        ac_set_hotspot: '',
        ac_ping_reboot_type: null,
        ac_set_server_address: null,
        ac_set_hub_property: null,
        ac_set_hub_modules: null,
        alerts_from_foreign_hub: false,
        altitude_min: 0,
        altitude_max: 0,
        ambient_temperature: [],
        ambient_temperature_command: '',
        area_gps_sat_threshold: 0,
        custom_wheel_position: false,
        cron_accelerometer_event_log_command: false,
        email_service_type: '0',
        maintenance_zone_radius: 0,
        map_area: null,
        max_speed_cap: 0,
        mine_operation_start: '',
        notification_tyre_expiration: 0,
        notification_tyre_threshold: 0,
        notification_tyre_pressure_threshold: 0,
        notification_tyre_temperature_threshold: 0,
        require_password_reset: false,
        secondary_sensors_alerts: false,
        toolbox: false,
        speed_event_notification_area_time: 0,
        toolbox_test_queue: false,
        wet_tyres: false,
        wet_tyres_from: null,
        wifi_secret_key: '',
        remove_message_request_id: [],
        mdd_bes: false,
        mdd_bes_driving_assistance: false,
        mdd_bes_display_name: '',
        temperature_prediction: false,
        temperature_prediction_hours: 4,
        tablet_activation: false,
        vehicle_alerts_enabled: false,
        ready_to_use_data: false
    }
});

export const GetConvertedAmbient = selector({
    key: 'GetConvertedAmbientTemperature',
    get: ({ get }) => {
        const customerSettings = get(CustomerSettings);
        const currentMonth = parseInt(DateTime.now().toFormat('M'));

        return (
            fromServerToUserUnit: ({
                type,
                value,
                fixed,
                displayUnits,
                displayIfEmpty
            }: ConverterFromServerProps) => string
        ): { all: number[]; current: number } => {
            const allAmbientTemperatures = customerSettings.ambient_temperature.map(
                (ambientVal) =>
                    +fromServerToUserUnit({
                        type: ConverterTypeEnum.TEMPERATURE,
                        value: ambientVal,
                        displayIfEmpty: '0'
                    })
            );

            return { all: allAmbientTemperatures, current: allAmbientTemperatures[currentMonth - 1] };
        };
    }
});
