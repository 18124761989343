import { ScaleLine } from 'ol/control';
import Feature, { FeatureLike } from 'ol/Feature';
import { Geometry } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import Vector from 'ol/source/Vector';
import Map from 'ol/Map';
import { DateTime } from 'luxon';
import {
    //HEATMAP_RESPONSE_TRACKLOG_API
    SlopeInclinationHeatmapResponse,
    ElevationProps,
    ElevationResponse,
    AccelerationResponse,
    GpsResponse,
    HeatmapProps,
    SpeedHeatmapResponse,
    BasicHeatmapProps
} from '../../models/TrackLog.type';
import {
    //HEATMAP_RESPONSE_SENSORLOG_API
    TemperatureResponse
} from '../../models/SensorLog.type';
import {
    //HEATMAP_RESPONSE_ACCELEROMETER_API
    VibrationZHeatmapResponse,
    VibrationHeatmapResponse
} from '../../models/AccelerometerLog.type';
import { MapActionDataType } from '../../states/global/Map';

export enum MapTypes {
    bing = 'bing',
    osm = 'osm',
    mapbox = 'mapbox'
}

export enum MapModes {
    SPEED_HEATMAP = 'SPEED_HEATMAP',
    TRACKING_MAP = 'TRACKING_MAP',
    ELEVATION_HEATMAP = 'ELEVATION_HEATMAP',
    GPS_HEATMAP = 'GPS_HEATMAP',
    TEMPERATURE_HEATMAP = 'TEMPERATURE_HEATMAP',
    SPEED_INCIDENTS = 'SPEED_INCIDENTS',
    ACCELERATION_HEATMAP = 'ACCELERATION_HEATMAP',
    TOTAL_VIBRATION_HEATMAP = 'TOTAL_VIBRATION_HEATMAP',
    HORIZONTAL_VIBRATION_HEATMAP = 'HORIZONTAL_VIBRATION_HEATMAP',
    CUSTOM_DATA = 'CUSTOM_DATA',
    EXCESSIVE_ACC = 'EXCESSIVE_ACC',
    JOURNEY_FAULTY_REPORT = 'JOURNEY_FAULTY_REPORT'
}

export type MapProps = {
    enableDrawing?: boolean;
    enableEditingArea?: boolean;
    onAreaEditEnd?: (coordinates: [number, number][]) => void;
    mapMode?: MapModes;
    disableVehiclePopup?: boolean;
};

export type MapViewProps = {
    mapElement: React.MutableRefObject<HTMLDivElement | undefined>;
    showPopup: boolean;
    warningMsg: string;
    layoutType?: CustomLayers;
    disableVehiclePopup?: boolean;
};

export type DefaultSettings = {
    maxZoom: number;
    minZoom: number;
    zoom: number;
    areaCenterCoordinates: [number, number];
    epsg: [string, string];
    scaleLine: ScaleLine;
};

export enum CustomLayers {
    JOURNEY = 'JOURNEY',
    AREA = 'AREA',
    VEHICLE = 'VEHICLE',
    DRAW_AREA = 'DRAW_AREA',
    HEATMAP = 'HEATMAP',
    SPEED_INCIDENTS = 'SPEED_INCIDENTS',
    EXCESSIVE_ACC = 'EXCESSIVE_ACC',
    JOURNEY_FAULTY_REPORT = 'JOURNEY_FAULTY_REPORT',
    SEVERE_DRIVING = 'SEVERE_DRIVING',
    SLOPE_INCLINATION_JOURNEY = 'SLOPE_INCLINATION_JOURNEY'
}

export type LayerOrLayers = CustomLayers | CustomLayers[];

export type LayerChainingDependency = Parameters<findLayerByNameType>[0] extends []
    ? Parameters<findLayerByNameType>[0][number]
    : Parameters<findLayerByNameType>[0];

export type VehicleFeature = {
    coordinates: [number, number] | [];
};

export type VehicleFeatureCoordinates = number[];

export type MapActions = {
    findLayerByName: findLayerByNameType;
    focusToLayer: focusToLayerType;
    defaultSettings: DefaultSettings;
    moveFeatureTo: moveFeatureToType;
    findFeatureInLayer: findFeatureInLayerType;
    getMapExtent: () => [number, number, number, number];
    updateLoadingMapStatus: (status: boolean) => void;
    toggleMapInteractions: () => void;
    mapInstance?: Map;
};

export enum MapActionsEnum {
    LOAD_JOURNEY_LINE_ON_STATISTICS = 'LOAD_JOURNEY_LINE_ON_STATISTICS',
    LOAD_JOURNEY_LINE = 'LOAD_JOURNEY_LINE',
    LOAD_MULTIPLE_JOURNEY_LINE = 'LOAD_MULTIPLE_JOURNEY_LINE',
    DRAW_VEHICLE_AT_TIME = 'DRAW_VEHICLE_AT_TIME',
    DRAW_VEHICLES_POSITION = 'DRAW_VEHICLES_POSITION',
    NO_ACTION = 'NO_ACTION',
    CLEAN_DRAW_AREA = 'CLEAN_DRAW_AREA',
    DRAW_AREAS = 'DRAW_AREAS',
    CLEAN_AREAS = 'CLEAN_AREAS',
    RESIZE_MAP = 'RESIZE_MAP',
    DRAW_AREA = 'DRAW_AREA',
    DRAW_HEATMAP = 'DRAW_HEATMAP',
    DRAW_CUSTOM_DATA = 'DRAW_CUSTOM_DATA',
    DRAW_SPEED_INCIDENTS = 'DRAW_SPEED_INCIDENTS',
    DRAW_BES_SEVERE_DRIVING = 'DRAW_BES_SEVERE_DRIVING',
    GET_BES_SEVERE_DRIVING_DATA = 'GET_BES_SEVERE_DRIVING_DATA',
    DRAW_EXCESSIVE_ACC = 'DRAW_EXCESSIVE_ACC',
    SHOW_MAP_MESSAGE = 'SHOW_MAP_MESSAGE',
    HIDE_MAP_MESSAGE = 'HIDE_MAP_MESSAGE',
    DRAW_JOURNEY_FAULTY_REPORT = 'DRAW_JOURNEY_FAULTY_REPORT',
    DISASSEMBLE_MAP = 'DISASSEMBLE_MAP',
    DRAW_SLOPE_JOURNEY = 'DRAW_SLOPE_JOURNEY',
    SLOPE_INCLINATION_JOURNEY_UPDATE = 'SLOPE_INCLINATION_JOURNEY_UPDATE'
}

export const getHeatmapResponseKey = (value: HeatmapKeys): string => {
    const indexOfS = Object.values(HeatmapKeys).indexOf(value as unknown as HeatmapKeys);
    return HeatmapResponseKey[Object.keys(HeatmapResponseKey)[indexOfS]];
};

export enum HeatmapKeys {
    //HEATMAP_KEY = HEATMAP_VALUE
    VIBRATIONZ = 'vibrationLevelZ',
    VIBRATION = 'vibrationLevel',
    SPEED = 'speed',
    ELEVATION = 'elevation',
    GPS = 'satellite',
    TEMPERATURE = 'avg',
    ACCELERATION = 'acceleration'
}

export enum HeatmapResponseKey {
    //HEATMAP_RESPONSE_KEY = HEATMAP_RESPONSE_VALUE
    VIBRATIONZ = 'vibrationZHeatmap',
    VIBRATION = 'vibrationHeatmap',
    SPEED = 'speedHeatmap',
    ELEVATION = 'heatmapElevation',
    GPS = 'heatmapGps',
    TEMPERATURE = 'temperatureHeatmap',
    ACCELERATION = 'accelerationHeatmap'
} // sync response keys on BE

export type drawHeatmapParams = {
    measuredFrom?: DateTime;
    measuredTo?: DateTime;
};

type Awaited<T> = T extends PromiseLike<infer U> ? Awaited<U> : T;
export type HeatmapData =
    //HEATMAP_DATA
    | Awaited<ReturnType<getVibrationZHeatmapType>>[HeatmapResponseKey.VIBRATIONZ]
    | Awaited<ReturnType<getVibrationHeatmapType>>[HeatmapResponseKey.VIBRATION]
    | Awaited<ReturnType<getGpsHeatmapType>>[HeatmapResponseKey.GPS]
    | Awaited<ReturnType<getTemperatureHeatmapType>>[HeatmapResponseKey.TEMPERATURE]
    | Awaited<ReturnType<getElevationHeatmapType>>[HeatmapResponseKey.ELEVATION]
    | Awaited<ReturnType<getSpeedHeatmapType>>[HeatmapResponseKey.SPEED]
    | Awaited<ReturnType<getAccelerationHeatmapType>>[HeatmapResponseKey.ACCELERATION];

export type HeatmapDataResponse =
    //HEATMAP_DATA_RESPONSE
    | ReturnType<getVibrationZHeatmapType>
    | ReturnType<getVibrationHeatmapType>
    | ReturnType<getGpsHeatmapType>
    | ReturnType<getTemperatureHeatmapType>
    | ReturnType<getElevationHeatmapType>
    | ReturnType<getSpeedHeatmapType>
    | ReturnType<getAccelerationHeatmapType>;

export type findLayerByNameType = (names: LayerOrLayers) => VectorLayer<Vector>[];
export type focusToLayerType = (layer: LayerChainingDependency) => void;
export type moveFeatureToType = (featureId: string, layer: LayerChainingDependency, newGeometry: Geometry) => void;
export type findFeatureInLayerType = (
    featureId: string,
    layer: LayerChainingDependency | VectorLayer<Vector<Geometry>>
) => Feature<Geometry> | null;

type getAccelerationHeatmapType = (props: BasicHeatmapProps) => Promise<AccelerationResponse>;
type getGpsHeatmapType = (props: BasicHeatmapProps) => Promise<GpsResponse>;
type getTemperatureHeatmapType = (props: BasicHeatmapProps) => Promise<TemperatureResponse>;
type getElevationHeatmapType = (props: ElevationProps) => Promise<ElevationResponse>;
type getSpeedHeatmapType = (props: HeatmapProps) => Promise<SpeedHeatmapResponse>;
type getVibrationHeatmapType = (props: HeatmapProps) => Promise<VibrationHeatmapResponse>;
type getVibrationZHeatmapType = (props: HeatmapProps) => Promise<VibrationZHeatmapResponse>;
//GET_HEATMAP_TYPE

type heatmapMapType = {
    map: MapActions;
    mapData: MapActionDataType;
};

export type drawHeatmapType = (props: heatmapMapType) => Promise<void>;

export type getHeatmapReturnType<T> = T extends HeatmapKeys.ELEVATION
    ? Parameters<getElevationHeatmapType>[0]
    : T extends HeatmapKeys.GPS
    ? Parameters<getGpsHeatmapType>[0]
    : T extends HeatmapKeys.SPEED
    ? Parameters<getSpeedHeatmapType>[0]
    : T extends HeatmapKeys.TEMPERATURE
    ? Parameters<getTemperatureHeatmapType>[0]
    : T extends HeatmapKeys.ACCELERATION
    ? Parameters<getAccelerationHeatmapType>[0]
    : T extends HeatmapKeys.VIBRATION
    ? Parameters<getVibrationHeatmapType>[0]
    : T extends HeatmapKeys.VIBRATIONZ
    ? Parameters<getVibrationZHeatmapType>[0]
    : never;
//HEATMAP_RETURN_TYPE

export type getHeatmapPropsType = <T extends HeatmapKeys>(
    heatmapKey: T,
    mapData: MapActionDataType,
    map?: MapActions
) => getHeatmapReturnType<T> | false | HeatMapBasicProps;
export type basicPropsType = Exclude<ReturnType<getHeatmapPropsType>, false>;

export type ResponseGeneralByClick = {
    feature: FeatureLike[] | FeatureLike | null;
    layoutType: CustomLayers;
};

export type PointExcessiveSettings = {
    id: number;
    accelY: number;
    position: {
        latitude: number;
        longitude: number;
    };
    loaded?: number; // need to be checked
};

export type HeatMapBasicProps = {
    measuredFrom: string;
    measuredTo: string;
    zoomLevel: number;
    area: string | undefined;
    limit: number;
} & Record<string, unknown>;
