import React, { useEffect } from 'react';
import { FilterProps, UiTableProps } from './UiTable.type';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { TableActions, TableAtom, TableDispatcher } from '../../../../states/component/Table';
import { useQuery } from '@tanstack/react-query';
import { TableInstance } from './TableInstance';
import { RefreshTable } from './UiTable.atom';
import useUserLocalStorageSettings from '../../../CustomHooks/UserLocalStorageSettings';

import SkeletonLoader from './SkeletonLoader/SkeletonLoader';
import { DateTime } from 'luxon';
import { Wrapper } from 'helpers/wrapper';
import { UserInfo } from 'states/global/User';

const UiTable: React.FC<UiTableProps> = (props): JSX.Element => {
    const [tableState, setTableState] = useRecoilState(TableAtom(`${props.queryKey}-Table`));
    const refreshTableState = useResetRecoilState(TableAtom(`${props.queryKey}-Table`));
    const tableDispatch = TableDispatcher(tableState, setTableState);
    const [refreshTableAtom, setRefreshTableAtom] = useRecoilState(RefreshTable(`${props.queryKey}-RefreshTableAtom`));
    const { setUserSettings, userLSSettings: tableSettings } = useUserLocalStorageSettings([
        `table.${props.queryKey}Table`
    ]);
    const userInfo = useRecoilValue(UserInfo);

    const checkEnabled = (): boolean => {
        return tableState.tableKey === props.queryKey || refreshTableAtom;
    };

    const { data, isLoading, isFetching } = useQuery(
        [
            `${props.queryKey}-TableQuery`,
            tableState.queryPageIndex,
            tableState.queryPageSize,
            tableState.queryPageSortBy,
            tableState.queryPageFilter,
            refreshTableAtom,
            userInfo.user?.customer.id
        ],
        () => props.fetchFn({ ...tableState }, props.additionalProps),
        {
            refetchInterval: props.refresh ?? 50000,
            keepPreviousData: true,
            enabled: checkEnabled(),
            refetchOnWindowFocus: false
        }
    );

    const {
        data: exportData,
        isFetching: isLoadingExportData,
        refetch: exportRefetch
    } = useQuery(
        [
            `${props.queryKey}-exportValues`,
            tableState.queryPageIndex,
            tableState.queryPageSize,
            tableState.queryPageSortBy,
            tableState.queryPageFilter,
            refreshTableAtom
        ],
        () => props?.exportFn?.({ ...tableState }, props.additionalProps),
        {
            retry: false,
            keepPreviousData: true,
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    useEffect(() => {
        if (tableSettings && tableSettings[`${props.queryKey}Table`]) {
            let filtersState: (FilterProps & Record<string, unknown>)[] | [] =
                tableSettings[`${props.queryKey}Table`].filter || [];
            if (filtersState && !!filtersState.length) {
                filtersState = filtersState.map((filter) => {
                    let newFilter: FilterProps & Record<string, unknown> = filter;
                    const date = DateTime.fromISO(filter.value.value, { zone: 'utc' });
                    if (date.isValid && isNaN(filter.value.value)) {
                        newFilter.value.value = date;
                        return newFilter;
                    }
                    return filter;
                });
            }
            setTableState({
                ...tableState,
                queryPageSortBy: tableSettings[`${props.queryKey}Table`]
                    ? tableSettings[`${props.queryKey}Table`].orderBy
                    : [],
                queryPageSize: tableSettings[`${props.queryKey}Table`].pageSize || 10,
                queryPageFilter: filtersState,
                tableKey: props.queryKey
            });
        } else {
            if (tableSettings && !tableSettings[`${props.queryKey}Table`]) {
                setTableState({
                    ...tableState,
                    queryPageIndex: 0,
                    queryPageSize: 10,
                    queryPageSortBy: props.defaultSortBy ? [props.defaultSortBy] : [],
                    queryPageFilter: props.defaultFilter ? [props.defaultFilter] : [],
                    tableKey: props.queryKey
                    //ready: true
                });
                setUserSettings(`table.${props.queryKey}Table`, {
                    filter: props.defaultFilter ? [props.defaultFilter] : [],
                    orderBy: props.defaultSortBy ? [props.defaultSortBy] : [],
                    pageSize: 10
                });
            }
        }
        return () => {
            refreshTableState();
        };
    }, [tableSettings, props.queryKey]);

    useEffect(() => {
        if (refreshTableAtom) {
            setRefreshTableAtom(false);
        }
    }, [refreshTableAtom]);

    useEffect(() => {
        if (data) {
            tableDispatch({
                type: TableActions.TOTAL_COUNT_CHANGED,
                payload: data.paginator.totalCount
            });
        }
    }, [data]);

    if (isLoading) {
        return <SkeletonLoader testid={`${props.queryKey}`} />;
    }

    if (!data) {
        return <SkeletonLoader testid={`${props.queryKey}`} />;
    }

    return (
        <TableInstance
            {...props}
            data={data?.items || []}
            pageCount={data?.paginator?.pageCount}
            isFetching={isFetching}
            exportData={exportData}
            isLoadingExportData={isLoadingExportData}
            exportRefetch={exportRefetch}
            exportName={props.exportName}
        />
    );
};

export default Wrapper(UiTable);
