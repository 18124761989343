import React, { useEffect, useRef } from 'react';
import { GlobalAdminNotifyTableProps } from './GlobalAdminNotifyTable.type';
import { GlobalAdminNotifyTableContent } from './GlobalAdminNotifyTable.view';
import { useMutation } from '@tanstack/react-query';
import { CustomTextField, Text } from './GlobalAdminNotifyTable.style';
import ModalButton from '../../Ui/Components/UiButton/UiButton';
import Menu from '../../Ui/Components/UiMenu';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ModalActionTypesEnum, ModalAtom, ModalDispatcher } from '../../../states/global/Modal';
import { useTranslation } from 'react-i18next';
import { HubNotifyDataModel } from '../../../models/HubNotify.type';
import HubNotify from '../../../api/HubNotify';
import { Success } from '../../Popup/Popup';
import { RefreshTable } from '../../Ui/Components/UiTable2/UiTable.atom';
import AddHubNotify from '../AddHubNotify/AddHubNotify';
import { UserInfo } from '../../../states/global/User';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AddHubNotifyProps } from '../AddHubNotify/AddHubNotify.type';
import useConverter from '../../CustomHooks/Converter/Converter';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { TextField } from '@mui/material';
import UiLoadingPage from 'components/Ui/Components/UiLoadingPage/UiLoadingPage';
import { Column } from 'models/Table.type';
import { Wrapper } from 'helpers/wrapper';

const GlobalAdminNotifyTable: React.FC<GlobalAdminNotifyTableProps> = (): JSX.Element => {
    const { t: translate } = useTranslation();
    const [modalAtom, setStateModalAtom] = useRecoilState(ModalAtom);
    const modalDispach = ModalDispatcher(modalAtom, setStateModalAtom);
    const setRefreshTableAtom = useSetRecoilState(RefreshTable('hubNotify-RefreshTableAtom'));
    const hubNotifyApi = new HubNotify();
    const refAddHub = useRef<HTMLButtonElement>(null);
    const userInfo = useRecoilValue(UserInfo);
    const { dateTimeFormat, fromUTCToTimezone } = useConverter();

    const updateModal = (isOpen?: boolean) => {
        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...modalAtom,
                buttons: <ModalButton skin='success'>{<UiLoadingPage />}</ModalButton>,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const { mutate: removeNotifyHubMutate, isLoading: removeNotifyHubLoading } = useMutation(
        hubNotifyApi.removeHubNotify,
        {
            onSuccess: () => {
                Success({
                    text: translate('t.removed_hub_notify')
                });
            },
            onSettled: () => {
                updateModal(false);
                setRefreshTableAtom(true);
            }
        }
    );

    const handleModalRemove = (id: number, hubId: number) => {
        const contentModalDelete = {
            id: 'DeleteAdminNotifyHubActions',
            leftTitle: `${translate('t.remove_hub_notify')}`,
            content: (
                <Text style={{ margin: '20px 0 0 0 ' }} variant='h6'>
                    {translate('p.are_you_sure_to_delete')} {translate('t.hub_id')}:{hubId}{' '}
                </Text>
            ),
            buttons: (
                <ModalButton
                    skin='success'
                    color='error'
                    variant='contained'
                    testid='Remove-HubNotify-Button'
                    onClick={() => {
                        removeNotifyHubMutate(id);
                    }}
                >
                    {removeNotifyHubLoading ? <UiLoadingPage /> : translate('t.remove')}
                </ModalButton>
            ),
            width: 400,
            height: 100,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalDelete,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalDelete,
                isOpen: true
            }
        });
    };

    const { mutate: addNotifyHubMutate, isLoading: addNotifyHubLoading } = useMutation(hubNotifyApi.addHubNotify, {
        onSuccess: () => {
            Success({
                text: translate('t.added_hub_notify')
            });
        },
        onSettled: () => {
            updateModal(false);
            setRefreshTableAtom(true);
        }
    });

    const handleModalAdd = () => {
        const contentModalAdd = {
            id: 'AddHubNotifyActions',
            leftTitle: `${translate('t.add_hub_notify')}`,
            content: <AddHubNotify refAddHub={refAddHub} handleMutation={addNotifyHubMutate} />,
            buttons: (
                <ModalButton
                    skin='success'
                    color='primary'
                    variant='contained'
                    testid='Save-HubNotify-Button'
                    onClick={() => {
                        refAddHub?.current && refAddHub?.current.click();
                    }}
                >
                    {addNotifyHubLoading ? <UiLoadingPage /> : translate('t.add')}
                </ModalButton>
            ),
            width: 600,
            height: 300,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalAdd,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalAdd,
                isOpen: true
            }
        });
    };

    const {
        mutate: getByIdNotifyHubMutate,
        isLoading: getByIdNotifyHubLoading,
        data: getByIdNotifyHubData
    } = useMutation(hubNotifyApi.getHubNotifyById, {
        onSuccess: () => {}
    });

    const { mutate: editNotifyHubMutate, isLoading: editNotifyHubLoading } = useMutation(hubNotifyApi.editHubNotify, {
        onSuccess: () => {
            Success({
                text: translate('t.edited_hub_notify')
            });
        },
        onSettled: () => {
            updateModal(false);
            setRefreshTableAtom(true);
        }
    });

    const updateModalEdit = (isOpen?: boolean) => {
        const contentModalEdit = {
            id: 'EditHubNotifyActions',
            leftTitle: `${translate('t.edit_hub_notify')}`,
            content: (
                <>
                    {getByIdNotifyHubLoading ? (
                        <UiLoadingPage size='30px' />
                    ) : (
                        <AddHubNotify
                            refAddHub={refAddHub}
                            initValues={{
                                id: getByIdNotifyHubData?.hubNotify.id || 0,
                                hubId: getByIdNotifyHubData?.hubNotify.hubId || 0,
                                vpnTunnel: getByIdNotifyHubData?.hubNotify.vpnTunnel || false,
                                reverseTunnel: getByIdNotifyHubData?.hubNotify.reverseTunnel || false,
                                replierLog: getByIdNotifyHubData?.hubNotify.replierLog || false,
                                message: getByIdNotifyHubData?.hubNotify.message || ''
                            }}
                            handleMutation={editNotifyHubMutate as AddHubNotifyProps['handleMutation']}
                        />
                    )}
                </>
            ),
            buttons: (
                <ModalButton
                    skin='success'
                    color='primary'
                    variant='contained'
                    testid='Save-HubNotify-Button'
                    onClick={() => {
                        refAddHub?.current && refAddHub?.current.click();
                    }}
                >
                    {editNotifyHubLoading ? <UiLoadingPage /> : translate('t.save')}
                </ModalButton>
            ),
            width: 600,
            height: 200,
            widthUnit: 'px',
            onClose: () =>
                modalDispach({
                    type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
                    ModalPropsPayload: {
                        ...contentModalEdit,
                        isOpen: false
                    }
                })
        };

        modalDispach({
            type: ModalActionTypesEnum.UPDATE_MODAL_STATE,
            ModalPropsPayload: {
                ...contentModalEdit,
                isOpen: typeof isOpen === 'undefined' ? modalAtom.isOpen : isOpen
            }
        });
    };

    const handleModalEdit = () => {
        updateModalEdit(true);
    };

    const columns: Column<HubNotifyDataModel>[] = [
        {
            Header: 'ID',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => <>{props.row.original.id ?? '-'}</>
        },
        {
            Header: translate('t.hub_id'),
            accessor: 'hubId',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => <>{props.row.original.hubId ?? '-'}</>
        },

        {
            Header: translate('t.vpn_tunnel'),
            accessor: 'vpnTunnel',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => (
                <>{props.row.original.vpnTunnel == true ? '1' : '0'}</>
            )
        },

        {
            Header: translate('t.reverse_tunnel'),
            accessor: 'reverseTunnel',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => (
                <>{props.row.original.reverseTunnel == true ? '1' : '0'}</>
            )
        },

        {
            Header: translate('t.replier_log'),
            accessor: 'replierLog',
            width: 60,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => (
                <>{props.row.original.replierLog == true ? '1' : '0'}</>
            )
        },

        {
            Header: translate('t.message'),
            accessor: 'message',
            width: 400,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => <>{props.row.original.message ?? '-'}</>,
            Filter: ({ setFilter, state }) => {
                return (
                    <CustomTextField
                        id='message-multiline-static'
                        label={`${translate('t.message')}`}
                        variant='outlined'
                        multiline
                        rows={3}
                        value={state.filters.filter((filter) => filter.id === 'message')[0]?.value.value || null}
                        onChange={(newValue) => {
                            const value = !newValue
                                ? undefined
                                : {
                                      name: `${translate('t.message')}`,
                                      humanValue: newValue.target.value,
                                      value: newValue.target.value
                                  };

                            setFilter('message', value);
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{
                            maxLength: 255,
                            style: {
                                padding: '1.5px'
                            }
                        }}
                    />
                );
            }
        },
        {
            Header: translate('t.created_at'),
            accessor: 'createdAt',
            width: 90,
            height: 20,
            Cell: (props: { row: { original: HubNotifyDataModel } }) => (
                <>{fromUTCToTimezone(props.row.original.createdAt || '', true) ?? '-'}</>
            ),
            Filter: ({ setFilter, state }) => {
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtFrom')[0]?.value.value || null
                            }
                            label={`${translate('t.created_at')} ${translate('t.from')}`}
                            onChange={(newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.from')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtFrom', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='device-from'
                                    size={'small'}
                                    sx={{ marginTop: '10px' }}
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                        <DatePicker
                            inputFormat={dateTimeFormat('date', false)}
                            label={`${translate('t.created_at')} ${translate('t.to')}`}
                            value={
                                state.filters.filter((filter) => filter.id === 'createdAtTo')[0]?.value.value || null
                            }
                            onChange={(newValue) => {
                                const value = !newValue
                                    ? undefined
                                    : {
                                          name: `${translate('t.created_at')} ${translate('t.to')}`,
                                          humanValue: newValue.toFormat(dateTimeFormat('date', false)),
                                          value: newValue
                                      };
                                setFilter('createdAtTo', value);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    variant='outlined'
                                    {...params}
                                    margin='normal'
                                    id='device-to'
                                    helperText={params.error && translate('t.the_date_format_is_invalid')}
                                    size={'small'}
                                    sx={{ marginTop: '5px' }}
                                    onFocus={() => {}}
                                    onBlur={() => {}}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: userInfo.user?.userSetting.dateFormat || 'YYYY-MM-DD',
                                        style: {
                                            padding: '10.5px'
                                        }
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                );
            }
        },

        {
            Header: translate('t.actions'),
            accessor: 'id',
            width: 80,
            height: 20,

            disableFilters: true,
            disableSortBy: true,
            Cell: (props) => (
                <span>
                    <Menu
                        testid={`Menu-HubNotify-${props.row.original.id}`}
                        items={[
                            {
                                title: translate('t.edit'),
                                value: props.value,
                                action: () => {
                                    getByIdNotifyHubMutate(props.value);
                                    handleModalEdit();
                                }
                            },

                            {
                                title: translate('t.remove'),
                                value: props.value,
                                action: () => {
                                    handleModalRemove(props.value, props.row.original.hubId);
                                }
                            }
                        ]}
                        rootEl={
                            <Button
                                aria-controls='simple-menu'
                                aria-haspopup='true'
                                style={{ minWidth: '20px', maxWidth: '20px' }}
                                data-testid={`Action-Button-${props.row.original.id}`}
                            >
                                <SettingsIcon />
                            </Button>
                        }
                    />
                </span>
            )
        },

        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            accessor: 'createdAtTo'
        }
    ];

    useEffect(() => {
        updateModal();
    }, [editNotifyHubLoading, addNotifyHubLoading, removeNotifyHubLoading]);

    useEffect(() => {
        updateModalEdit();
    }, [getByIdNotifyHubLoading]);

    return (
        <GlobalAdminNotifyTableContent
            data-testid='GlobalAdminNotifyTable-testid'
            handleModalAdd={handleModalAdd}
            columns={columns}
        />
    );
};

export default Wrapper(GlobalAdminNotifyTable);
