import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { UiTable3HideColumnsContentView } from './UiTable3HideColumnsContent.style';
import { UiTable3HideColumnsContentProps } from './UiTable3HideColumnsContent.type';
import { ColumnNamesIds } from '../UiTable3/TableAtom';
import UiTable3HideColumnsCheckbox from '../UiTable3HideColumnsCheckbox';

const UiTable3HideColumnsContent: React.FC<UiTable3HideColumnsContentProps> = (props): JSX.Element => {
    const columnNamesIds = useRecoilValue(ColumnNamesIds);

    const Checkboxes = useMemo(() => {
        return (
            <UiTable3HideColumnsContentView data-testid='UiTable3HideColumnsContentContent'>
                {columnNamesIds
                    ?.filter((column) => !!column.name)
                    .map((column, index) => (
                        <UiTable3HideColumnsCheckbox
                            queryKey={props.queryKey}
                            columnId={column.id}
                            label={column.name}
                            key={index}
                        />
                    ))}
            </UiTable3HideColumnsContentView>
        );
    }, [columnNamesIds]);

    return Checkboxes;
};

export default UiTable3HideColumnsContent;
