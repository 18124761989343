import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { BACKGROUND_BLUE } from '../../../../colors';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import styled from 'styled-components';
import { applyStyleByMode } from 'helpers';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro';

export const IntervalSelectorsContainer = styled.div`
    padding-top: 10px;
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
`;

export const IntervalsText = styled.div`
    padding-top: 10px;
    margin-left: 25px;
    text-align: left;
`;

export const CustomCalendarPicker = styled(DateRangeCalendar)`
    & .MuiPickersCalendarHeader-labelContainer {
        font-size: 1.2em;
        font-weight: 600;
    }

    & .MuiPickersArrowSwitcher-button svg {
        font-size: 1.5em;
        font-weight: 600;
    }

    & .MuiPickersCalendarHeader-switchViewButton {
        font-size: 1.5em;
    }

    & .MuiButtonBase-root {
        border-radius: 50px;
        margin: 0 0.5px;
        padding: 0;
        &.Mui-selected {
            background-color: ${BACKGROUND_BLUE} !important;
        }
    }

    & .Mui-selected {
        background-color: ${BACKGROUND_BLUE} !important;
    }

    & .MuiPickersDay-root,
    & .MuiDayPicker-weekDayLabel {
        font-size: 1.2em;
    }
`;

export const GridContainer = styled(Grid)`
    text-align: center;
    align-items: center;

    &.MuiGrid-root {
       
        & .MuiGrid-item {
           
            padding-top: 0px;
            padding-left: 0px;
        }

    @media (max-width: 768px) {
        &.MuiGrid-root {
            display: flex;
            flex-direction: column;
            text-align: initial;
            align-items: initial;
            & .MuiGrid-item {
                padding-left: 0px !important;
            }
        }

        &&& .timePicker {
            text-align: center;
            align-items: center;
            margin: auto;
            padding-bottom: 10px;
        }

        &&& .datePicker {
            margin: 0;
            padding: 0;
        }
    }
`;

export const GridTimePicker = styled(Grid)`
    & .MuiGrid-item {
        padding: 0px;
    }
`;

export const GridCalendar = styled(Grid)`
    @media (min-width: 768px) {
        border-right: 1px solid #ddd;
    }
`;

export const GridTitle = styled(Grid)``;

export const Title = styled(Typography)`
    &.MuiTypography-root {
        padding: 15px 0px 2px 23px !important;
        text-align: left;
        font-size: 20px;

        @media (max-width: 768px) {
            padding-left: 30px !important;
        }
    }
`;

export const DateTimeText = styled(Typography)`
    &.MuiTypography-root {
        font-size: 1.5em;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 35px;
        @media (min-width: 768px) {
        }
    }

    & .MuiButton-root {
        color: #565656;

        font-size: 0.9em;
    }

    & .MuiButton-startIcon {
        width: 1em;
        height: 1em;
        color: #5f945b;
    }
`;

export const Text = styled(Typography)<{ $ampm: boolean }>`
    &.MuiTypography-root {
        padding-top: 10px;
        font-size: 1.2em;
        font-weight: 600;
        @media (min-width: 768px) {
            text-align: ${(props) => (props.$ampm ? 'left' : 'center')} !important;
            margin-left: ${(props) => (props.$ampm ? 19 : 0)}px;
        }
    }
`;

export const CustomStaticDatePicker = styled(StaticDateTimePicker)`
    height: 300px;
`;

export const CustomDateTimePicker = styled(DesktopDateTimePicker)`
    &.MuiFormControl-root {
        margin: 0 3px 3px 0 !important;
    }
    ${applyStyleByMode({
        styleOld: `
            && .MuiInputBase-input {
                padding: 8.5px 0px 8.5px 14px !important;
            }
        `
    })}
`;
