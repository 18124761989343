import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faAnalytics,
    faAngleUp,
    faArrowDown,
    faArrowDownFromLine,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faArrowUpToLine,
    faArrowsV,
    faBan,
    faBarsFilter,
    faBatteryEmpty,
    faBatteryFull,
    faBatteryHalf,
    faBatteryLow,
    faBatteryQuarter,
    faBatteryThreeQuarters,
    faBellOn,
    faBellSlash,
    faBook,
    faBug,
    faBullhorn,
    faCalendarDay,
    faCalendarRange,
    faCarWrench,
    faChartArea,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronsLeft,
    faChevronsRight,
    faCircle,
    faCircleCheck,
    faCircleExclamation,
    faCirclePlus,
    faCircleXmark,
    faCircleInfo,
    faDownToLine,
    faDownload,
    faEllipsis,
    faEnvelope,
    faExclamation,
    faFileAlt,
    faFileCirclePlus,
    faFileSlash,
    faFire,
    faFireFlameCurved,
    faFireFlameSimple,
    faGauge,
    faGaugeCircleBolt,
    faGaugeHigh,
    faGaugeMax,
    faGaugeMin,
    faGaugeSimpleMax,
    faGear,
    faGlobeEurope,
    faGrid,
    faHourglass,
    faHourglassClock,
    faHourglassEnd,
    faHourglassHalf,
    faInfo,
    faLaptop,
    faLinkSlash,
    faList,
    faLocationDot,
    faLocationDotSlash,
    faLock,
    faLockOpen,
    faMap,
    faMapMarker,
    faMaximize,
    faMessageSms,
    faMinimize,
    faMinus,
    faMobile,
    faMountains,
    faParking,
    faPause,
    faPen,
    faPenToSquare,
    faPersonRunning,
    faPlay,
    faPlus,
    faPowerOff,
    faRectangleXmark,
    faRss,
    faSearchPlus,
    faShieldCheck,
    faShuffle,
    faSignal,
    faSignalBars,
    faSms,
    faSnowflake,
    faSquare,
    faSun,
    faTabletAndroidAlt,
    faTemperatureFull,
    faTemperatureHalf,
    faTemperatureThreeQuarters,
    faThermometerThreeQuarters,
    faTirePressureWarning,
    faTireRugged,
    faTriangleExclamation,
    faTruck,
    faTruckFast,
    faTruckFront,
    faTruckMoving,
    faUnlink,
    faUser,
    faWifi,
    faWifiSlash,
    faWrench,
    faXmark,
    faCarBus,
    faTruckContainer,
    faUpRightFromSquare,
    faChartPie,
    faChartMixedUpCircleCurrency,
    faSlash
} from '@fortawesome/pro-solid-svg-icons';

import {
    faBellOn as faBellOnR,
    faBellSlash as faBellSlashR,
    faBullhorn as faBullhornR,
    faCheck as faCheckR,
    faCircleCheck as faCircleCheckR,
    faList as faListR,
    faStopwatch
} from '@fortawesome/pro-regular-svg-icons';

library.add(
    faRectangleXmark,
    faParking,
    faLocationDotSlash,
    faWifi,
    faWrench,
    faBatteryLow,
    faPen,
    faSignalBars,
    faChevronDown,
    faChevronUp,
    faPowerOff,
    faBatteryEmpty,
    faBatteryHalf,
    faBatteryThreeQuarters,
    faBatteryFull,
    faBatteryQuarter,
    faTruck,
    faGlobeEurope,
    faXmark,
    faCircleCheck,
    faBook,
    faTirePressureWarning,
    faMapMarker,
    faEllipsis,
    faExclamation,
    faWifiSlash,
    faPause,
    faPlay,
    faSquare,
    faBan,
    faWifiSlash,
    faMobile,
    faLaptop,
    faEnvelope,
    faMap,
    faMapMarker,
    faMessageSms,
    faSms,
    faTriangleExclamation,
    faBullhorn,
    faBellOn,
    faBellSlash,
    faBellOnR,
    faBellSlashR,
    faBullhornR,
    faThermometerThreeQuarters,
    faSignal,
    faShuffle,
    faSignal,
    faHourglass,
    faHourglassEnd,
    faArrowsV,
    faTriangleExclamation,
    faDownToLine,
    faCheckR,
    faListR,
    faCircleCheckR,
    faDownload,
    faPersonRunning,
    faFileSlash,
    faFileCirclePlus,
    faLinkSlash,
    faArrowDown,
    faArrowUp,
    faSearchPlus,
    faTireRugged,
    faGaugeMax,
    faLock,
    faLockOpen,
    faRss,
    faStopwatch,
    faChartArea,
    faTemperatureThreeQuarters,
    faBarsFilter,
    faList,
    faGrid,
    faAnalytics,
    faSnowflake,
    faSun,
    faFileAlt,
    faGear,
    faUnlink,
    faHourglassClock,
    faCarWrench,
    faFireFlameSimple,
    faFireFlameCurved,
    faTemperatureHalf,
    faGaugeSimpleMax,
    faTruckMoving,
    faTruckFront,
    faFire,
    faCircleXmark,
    faCircleExclamation,
    faUser,
    faPlus,
    faMountains,
    faLocationDot,
    faTemperatureFull,
    faShieldCheck,
    faPenToSquare,
    faBug,
    faAngleUp,
    faInfo,
    faArrowLeft,
    faMaximize,
    faMinimize,
    faArrowDownFromLine,
    faArrowUpToLine,
    faGaugeCircleBolt,
    faGaugeMin,
    faGaugeHigh,
    faGauge,
    faTruckFast,
    faHourglassHalf,
    faCalendarDay,
    faArrowRight,
    faCalendarRange,
    faCircle,
    faChevronLeft,
    faChevronsLeft,
    faChevronRight,
    faChevronsRight,
    faCarBus,
    faTruckContainer,
    faUpRightFromSquare,
    faCirclePlus,
    faCirclePlus,
    faCircleInfo,
    faChartPie,
    faChartMixedUpCircleCurrency,
    faTabletAndroidAlt,
    faArrowDownToLine,
    faMinus,
    faSlash
);
